import axios from 'axios';
import React from 'react';
import { S3File } from '../../entities/S3File';
import {
  Box,
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { BsArrowRepeat, BsCloudDownload, BsTrash } from 'react-icons/bs';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { logger } from '../../../../common/infra/logger';

export const FilesTable: React.FC<{
  files: S3File[];
  compositionId: number;
  onUpdate(error: string | undefined): void;
  onDelete(error: string | undefined): void;
}> = ({ files, compositionId, onUpdate, onDelete }) => {
  const toast = useToast();

  const populateFacetsFromMasterMidi = () => {
    axios
      .post<{ warnings?: string[] }>(
        `/compositions/${compositionId}/populateFacetsFromMasterMidi`,
      )
      .then((response) => {
        const { warnings } = response.data;
        if (warnings?.length > 0) {
          toast({
            position: 'top',
            title: 'Issue Populating Facets from Master MIDI',
            description: (
              <VStack align="flex-start">
                {warnings.map((w) => (
                  <Text key={w}>{w}</Text>
                ))}
              </VStack>
            ),
            status: 'warning',
            duration: null,
            isClosable: true,
          });
        } else {
          toast({
            position: 'top',
            title: 'Populated Facets from Master MIDI',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        }

        // Even if there were some warnings, consider this a success
        onUpdate(undefined);
      })
      .catch((error) => {
        logger.warn(
          `Error populating facets from master midi ${error.message}`,
        );
        if (error.response.data.error) {
          onUpdate(error.response.data.error);
        }
      });
  };

  const deleteFile = async (fileName) => {
    if (window.confirm(`Delete ${fileName}?`)) {
      const data = {
        composition: {
          id: compositionId,
        },
        file: {
          name: fileName,
        },
      };
      axios
        .patch(`/compositions/${compositionId}/deleteFile`, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          onDelete(undefined);
        })
        .catch((error) => {
          logger.warn(`Error deleting file ${error.message}`);
          onDelete(error.message);
        });
    }
  };

  let presets = {};
  if (files) {
    files.map((file) => {
      if (file.name.includes('presets/')) {
        let name = file.name.substring(
          file.name.indexOf('presets/') + 8,
          file.name.lastIndexOf('.'),
        );
        presets[name] = true;
      }
    });
  }
  let highlightColor = {};
  if (files) {
    files.map((file) => {
      if (file.name.includes('presets_raw/')) {
        let name = file.name.substring(
          file.name.indexOf('presets_raw/') + 12,
          file.name.lastIndexOf('.'),
        );
        if (!presets[name]) {
          highlightColor[file.name] = 'Red';
        }
      }
    });
  }

  return (
    <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>File Key</Th>
            <Th>Last Modified</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {files &&
            files.map((file) => (
              <Tr key={file.id}>
                <Td wordBreak="break-word">
                  <VStack align="flex-start">
                    <Text bgColor={highlightColor[file.name] ?? undefined}>
                      {file.name}
                    </Text>
                    {file.name.toLowerCase().endsWith('.mp3') && (
                      <audio
                        controls
                        src={`/compositions/${compositionId}/files/download?fileName=${encodeURIComponent(
                          file.name,
                        )}`}
                      >
                        Your browser does not support the <code>audio</code>{' '}
                        element.
                      </audio>
                    )}
                  </VStack>
                </Td>
                <Td>{new Date(file.lastModified).toLocaleString()}</Td>
                <Td>
                  <Menu autoSelect={false}>
                    <MenuButton
                      as={Button}
                      size="sm"
                      rightIcon={<Icon as={HiOutlineChevronDown} />}
                    >
                      Actions
                    </MenuButton>
                    {/* Override MenuList fontSize due to Table size="sm" making it smaller by default */}
                    <MenuList fontSize="1rem">
                      <MenuItem
                        as="a"
                        icon={<Icon as={BsCloudDownload} />}
                        href={`/compositions/${compositionId}/files/download?fileName=${encodeURIComponent(
                          file.name,
                        )}`}
                        download
                      >
                        Download
                      </MenuItem>
                      {file.name.toLowerCase().endsWith('_master.mid') && (
                        <MenuItem
                          icon={<Icon as={BsArrowRepeat} />}
                          onClick={() => populateFacetsFromMasterMidi()}
                        >
                          Populate Facets
                        </MenuItem>
                      )}
                      <MenuDivider />
                      <MenuItem
                        color="red.500"
                        icon={<Icon as={BsTrash} />}
                        onClick={() => deleteFile(file.name)}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};
