import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { dateReviver } from '../../../../../../common/api/http';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Select,
} from '@chakra-ui/react';
import { NewFile } from '../new_file';
import { MasteringPreset } from '../../../../../common/entities/MasteringPreset';
import { String } from 'aws-sdk/clients/cloudtrail';
import { logger } from '../../../../../common/infra/logger';

const CustomPreset: MasteringPreset = {
  id: 0,
  name: 'Custom Mastering',
  prefixKey: 'custom_mastering',
  createdDate: new Date(),
  archivedDate: undefined,
};

export const MasteringPresetSelector: React.FC<{
  compositionId: string;
  onUpload(error: String): void;
}> = ({ compositionId, onUpload }) => {
  const [presetSelectionId, setPresetSelectionId] = useState<
    number | undefined
  >(undefined);
  const [masteringPresets, setMasteringPresets] = useState<MasteringPreset[]>([
    CustomPreset,
  ]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const fetchPresets = () => {
    const request = axios.get<{ masteringPresets: MasteringPreset[] }>(
      '/mastering_presets/all',
      {
        transformResponse: [(data) => JSON.parse(data, dateReviver)],
      },
    );
    request.then(({ data }) => {
      const presets = data.masteringPresets;
      presets.push(CustomPreset);
      setMasteringPresets(presets);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    axios
      .patch(
        `/compositions/${compositionId}/copyPreset`,
        { masteringPresetId: presetSelectionId },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        logger.debug(response);
        onUpload(undefined);
      })
      .catch((error) => {
        logger.warn(error);
        onUpload(error.response.data.error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  useEffect(() => {
    fetchPresets();
  }, []);

  return (
    <>
      <HStack
        align="flex-end"
        paddingBottom={presetSelectionId === CustomPreset.id ? '0px' : '15px'}
        width="100%"
      >
        <FormControl>
          <FormLabel>RenderRoar Mastering Preset</FormLabel>
          <Select
            placeholder="Select Preset"
            size="sm"
            isRequired
            value={presetSelectionId}
            onChange={(e) => setPresetSelectionId(parseInt(e.target.value))}
          >
            {masteringPresets?.map((preset) => (
              <option key={preset.id} value={preset.id}>
                {preset.name}
              </option>
            ))}
          </Select>
        </FormControl>
        <Button
          size="sm"
          colorScheme="blue"
          onClick={handleSubmit}
          isDisabled={
            submitting ||
            !presetSelectionId ||
            presetSelectionId === CustomPreset.id
          }
        >
          Load
        </Button>
      </HStack>
      {presetSelectionId === CustomPreset.id && (
        <NewFile
          compositionId={compositionId}
          label="Mastering Preset Files (.roar files)"
          prefix="master_chain"
          onUpload={onUpload}
          accept=".roar"
          focus={true}
        />
      )}
    </>
  );
};
