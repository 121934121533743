import axios from 'axios';
import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  CloseButton,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Stack,
} from '@chakra-ui/react';
import { QueuePriority } from '../../../../common/entities/AccountQueuePriority';

export const MessagePriorityAdjuster: React.FC = () => {
  const [regexPattern, setRegexPattern] = useState<string>('');
  const [destinationPriority, setDestinationPriority] = useState<QueuePriority>(
    QueuePriority.Medium,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const [successfulSubmission, setSuccessfulSubmission] = useState<boolean>(
    false,
  );
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setSubmitting(true);
    resetAlert();
    axios
      .post(
        `/settings/adjust_message_priority`,
        {
          regexPattern,
          destinationPriority,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setError(undefined);
        setSuccessfulSubmission(true);
      })
      .catch((error) => {
        setError(
          `Error starting adjustments: ${
            error.response.data?.error ?? error.message
          }`,
        );
        setSuccessfulSubmission(false);
      })
      .finally(() => setSubmitting(false));
  };

  const resetAlert = () => {
    setSuccessfulSubmission(false);
    setError(undefined);
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
      {(error || successfulSubmission) && (
        <Alert status={error ? 'error' : 'success'} mb={4}>
          <AlertIcon />
          {error ||
            `Started adjustments for ${regexPattern} to ${destinationPriority} priority.`}
          <CloseButton
            position="absolute"
            right={2}
            top={2}
            onClick={() => {
              resetAlert();
            }}
          />
        </Alert>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Stack direction="column" align="start" spacing={4}>
          <FormControl>
            <FormLabel>Regex Pattern</FormLabel>
            <Input
              placeholder='"accountId":1, "userId":1, "dynascoreName":"Epic Earth"...'
              onChange={(e) => {
                setRegexPattern(e.target.value);
                resetAlert();
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Destination Priority</FormLabel>
            <Select
              value={destinationPriority}
              onChange={(e) => {
                setDestinationPriority(e.target.value as QueuePriority);
                resetAlert();
              }}
            >
              {[
                QueuePriority.High,
                QueuePriority.Medium,
                QueuePriority.Low,
              ]?.map((p) => (
                <option key={p} value={p}>
                  {p}
                </option>
              ))}
            </Select>
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            isDisabled={!regexPattern || submitting}
          >
            Adjust
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
