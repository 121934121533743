import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReportDay } from '../../../../common/entities/Report';
import {
  Box,
  Icon,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BsTriangleFill } from 'react-icons/bs';

type SortColumn =
  | 'day'
  | 'numAccounts'
  | 'numUsers'
  | 'numApiUsers'
  | 'numHatches'
  | 'numAppHatches'
  | 'totalHatches';

type CompareFunction = (a: ReportDay, b: ReportDay) => number;

export const DaysReport = () => {
  const [days, setDays] = useState<ReportDay[]>([]);

  const sortComparators: {
    [column in SortColumn]: CompareFunction;
  } = {
    day: (a, b) => a.day.localeCompare(b.day),
    numAccounts: (a, b) => a.numAccounts - b.numAccounts,
    numUsers: (a, b) => a.numUsers - b.numUsers,
    numApiUsers: (a, b) => a.numApiUsers - b.numApiUsers,
    numHatches: (a, b) => a.numHatches - b.numHatches,
    numAppHatches: (a, b) => a.numAppHatches - b.numAppHatches,
    totalHatches: (a, b) => a.totalHatches - b.totalHatches,
  };

  const columnDefaultSortAscending: { [column in SortColumn]: boolean } = {
    day: false,
    numAccounts: false,
    numUsers: false,
    numApiUsers: false,
    numHatches: false,
    numAppHatches: false,
    totalHatches: false,
  };

  const [sortColumn, setSortColumn] = useState<SortColumn>('day');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const fetchDays = () => {
    return axios
      .get<{ days: ReportDay[] }>('/reports/days')
      .then(({ data }) => setDays(data.days));
  };

  useEffect(() => {
    fetchDays();
  }, []);

  return (
    <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            {(
              [
                { name: 'Day', column: 'day' },
                { name: '# Account Signups', column: 'numAccounts' },
                { name: '# User Signups', column: 'numUsers' },
                { name: '# API User Signups', column: 'numApiUsers' },
                { name: '# Desktop Hatches', column: 'numHatches' },
                { name: '# iOS App Hatches', column: 'numAppHatches' },
                { name: '# Total Hatches', column: 'totalHatches' },
              ] as {
                name: string;
                column: SortColumn;
              }[]
            ).map(({ name, column }) => (
              <Th
                key={column}
                onClick={() => {
                  if (column == sortColumn) {
                    setSortAscending(!sortAscending);
                  } else {
                    setSortColumn(column);
                    setSortAscending(columnDefaultSortAscending[column]);
                  }
                }}
              >
                {name}
                {column === sortColumn && (
                  <Icon
                    as={BsTriangleFill}
                    mx={2}
                    boxSize={2}
                    transform={`rotate(${sortAscending ? 0 : 180}deg)`}
                  />
                )}
              </Th>
            ))}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {days
            ?.sort(
              (a, b) =>
                sortComparators[sortColumn](a, b) * (sortAscending ? 1 : -1),
            )
            .map((day) => (
              <Tr key={day.day}>
                <Td>{day.day}</Td>
                <Td>{day.numAccounts}</Td>
                <Td>{day.numUsers}</Td>
                <Td>{day.numApiUsers}</Td>
                <Td>{day.numHatches}</Td>
                <Td>{day.numAppHatches}</Td>
                <Td>{day.totalHatches}</Td>
              </Tr>
            ))}
        </Tbody>
        {days?.length > 0 && (
          <Tfoot>
            <Tr>
              <Th>Total {days.length}</Th>
              <Th>{days.reduce((sum, d) => sum + d.numAccounts, 0)}</Th>
              <Th>{days.reduce((sum, d) => sum + d.numUsers, 0)}</Th>
              <Th>{days.reduce((sum, d) => sum + d.numApiUsers, 0)}</Th>
              <Th>{days.reduce((sum, d) => sum + d.numHatches, 0)}</Th>
              <Th>{days.reduce((sum, d) => sum + d.numAppHatches, 0)}</Th>
              <Th>{days.reduce((sum, d) => sum + d.totalHatches, 0)}</Th>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </Box>
  );
};
