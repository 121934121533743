import { makeRangeMarker } from './makeMarker';
import {
  allMarkerTypes,
  IMarker,
  IRangeMarker,
  isRangeMarker,
  MarkerType,
} from './markerType';

// NOTE: This file is meant to be the source of truth of all marker rules, and
// ideally everything uses the code in this file. However, the iOS app doesn't
// execute TypeScript/Javascript code, so instead this file has been
// reimplemented in Swift for that project and should be kept up to date with
// the validation rules in this file.
export interface CompositionRules {
  endMarkerDefaultTimeFromEnd?: number;
  falseEndingMarkerDefaultTimeFromEndMarker?: number;

  // These rules are exclusively used by the Dynascore demo on wonder.inc.
  minTransitionMarkers?: number;
  maxTransitionMarkers?: number;
}

// Validation rules that do not change per composition
interface DefaultValidationRules {
  minDuration: number;
  defaultDuration: number;
  maxDuration: number;

  minTimeBetweenMarkers: number;

  endMarkerMinTimeFromEnd: number;
  endMarkerMaxTimeFromEnd: number;

  falseEndingMarkerMinTimeFromEndMarker: number;
  falseEndingMarkerMaxTimeFromEndMarker: number;

  markerMinTimeFromStart: number;
}

// Validation rules that may change per composition (based on CompositionRules)
interface CustomValidationRules {
  endMarkerDefaultTimeFromEnd: number;
  falseEndingMarkerDefaultTimeFromEndMarker: number;

  // These rules are exclusively used by the Dynascore demo on wonder.inc.
  minTransitionMarkers?: number;
  maxTransitionMarkers?: number;
}

interface ValidationRules
  extends DefaultValidationRules,
    CustomValidationRules {}

export const defaultValidationRules: DefaultValidationRules = (() => {
  const endMarkerMinTimeFromEnd = 2;
  const endMarkerMaxTimeFromEnd = 4;

  const falseEndingMarkerMinTimeFromEndMarker = 1;
  const falseEndingMarkerMaxTimeFromEndMarker = 5;

  const minTimeBetweenMarkers = 5;
  const markerMinTimeFromStart = 5;

  const minDuration = Math.max(
    6,
    // Ensure that the minDuration at supports placing an end marker in some
    // valid position while following all other rules.
    Math.ceil(
      markerMinTimeFromStart +
        falseEndingMarkerMinTimeFromEndMarker +
        endMarkerMinTimeFromEnd,
    ),
  );
  const defaultDuration = Math.max(60, minDuration);
  const maxDuration = Math.max(600, defaultDuration);

  return {
    minDuration,
    defaultDuration,
    maxDuration,

    minTimeBetweenMarkers,

    endMarkerMinTimeFromEnd,
    endMarkerMaxTimeFromEnd,

    falseEndingMarkerMinTimeFromEndMarker,
    falseEndingMarkerMaxTimeFromEndMarker,

    markerMinTimeFromStart,
  };
})();

const makeCustomValidationRules = (
  compositionRules: CompositionRules,
): CustomValidationRules => {
  const {
    endMarkerMinTimeFromEnd,
    endMarkerMaxTimeFromEnd,
    falseEndingMarkerMinTimeFromEndMarker,
    falseEndingMarkerMaxTimeFromEndMarker,
  } = defaultValidationRules;

  const endMarkerDefaultTimeFromEnd = Math.max(
    endMarkerMinTimeFromEnd,
    Math.min(
      endMarkerMaxTimeFromEnd,
      compositionRules.endMarkerDefaultTimeFromEnd || 2,
    ),
  );

  const falseEndingMarkerDefaultTimeFromEndMarker = Math.max(
    falseEndingMarkerMinTimeFromEndMarker,
    Math.min(
      falseEndingMarkerMaxTimeFromEndMarker,
      compositionRules.falseEndingMarkerDefaultTimeFromEndMarker || 3,
    ),
  );

  return {
    ...compositionRules,
    endMarkerDefaultTimeFromEnd,
    falseEndingMarkerDefaultTimeFromEndMarker,
  };
};

export const makeValidationRules = (
  compositionRules: CompositionRules,
): ValidationRules => {
  return {
    ...defaultValidationRules,
    ...makeCustomValidationRules(compositionRules),
  };
};

// NOTE(slanden): If any additional markers are added, be sure to update
// the friendly error messages in dynascore/premiere/.../util/validation.ts.
type ValidationErrorKind =
  | 'minDuration'
  | 'maxDuration'
  | 'minNumOfMarkerType'
  | 'maxNumOfMarkerType'
  | 'rangeMarkerMinDuration'
  | 'rangeMarkerMaxDuration'
  | 'markerEndLocation'
  | 'markerFalseEndingLocation'
  | 'markerTooCloseToStart'
  | 'minTimeBetweenMarkers'
  | 'markersBeforeEndMarkers';

export interface ValidationError {
  kind: ValidationErrorKind;
  message: string;
}

export interface MarkerValidationError extends ValidationError {
  marker: IMarker;
}

interface TimeIntervalValidationError extends MarkerValidationError {
  start: number;
  end: number;
}

const isTimeIntervalValidationError = (
  error: ValidationError,
): error is TimeIntervalValidationError => {
  return 'start' in error && 'end' in error;
};

interface MinDurationValidationError extends ValidationError {
  kind: 'minDuration';
  minDuration: number;
}

interface MaxDurationValidationError extends ValidationError {
  kind: 'maxDuration';
  maxDuration: number;
}

export interface MinNumOfMarkerTypeValidationError extends ValidationError {
  kind: 'minNumOfMarkerType';
  markerType: MarkerType;
  minNumOfMarkerTypes: number;
}

export interface MaxNumOfMarkerTypeValidationError
  extends MarkerValidationError {
  kind: 'maxNumOfMarkerType';
  markerType: MarkerType;
  maxNumOfMarkerTypes: number;
}

export interface RangeMarkerMinDurationValidationError
  extends MarkerValidationError {
  kind: 'rangeMarkerMinDuration';
  minDuration: number;
}

export interface RangeMarkerMaxDurationValidationError
  extends MarkerValidationError {
  kind: 'rangeMarkerMaxDuration';
  maxDuration: number;
}

export interface MarkerEndLocationValidationError
  extends TimeIntervalValidationError {
  kind: 'markerEndLocation';
}

export interface MarkerFalseEndingLocationValidationError
  extends TimeIntervalValidationError {
  kind: 'markerFalseEndingLocation';
}

interface MarkerTooCloseToStartValidationError
  extends TimeIntervalValidationError {
  kind: 'markerTooCloseToStart';
  minTimeFromStart: number;
}

interface MinTimeBetweenMarkersValidationError
  extends TimeIntervalValidationError {
  kind: 'minTimeBetweenMarkers';
  minTimeBetweenMarkers: number;
}

export interface MarkersBeforeEndMarkersValidationError
  extends TimeIntervalValidationError {
  kind: 'markersBeforeEndMarkers';
  markerType: MarkerType;
}

// Use an epsilon value to account for floating point precision issues.
// One concrete example is that Javascript incorrectly evaluates
// `6.838 > 8.838 - 2` to true, when it should be false.
// Javascript has `Number.EPSILON`, but it is actually too small to handle
// this scenario, so use a relatively larger, but still small, epsilon value.
const epsilon = 0.0001;

// Return a list of validation errors for the provided Dynascore input.
// Validation errors are sorted in descending order of priority.
// An empty array means the input is valid.
export const validateDynascoreInput = (
  duration: number,
  markers: IMarker[],
) => {
  const errors: ValidationError[] = [];

  // RULE: Minimum & maximum duration
  const durationError = validateDuration(duration);
  if (durationError !== undefined) {
    errors.push(durationError);
  }

  // RULE: Minimum number of markers of type
  for (const markerType of allMarkerTypes) {
    const minNumOfMarkerTypes = minMarkersOfType(markerType);
    if (minNumOfMarkerTypes > 0) {
      const currentNumOfType = markers.reduce((total, m) => {
        return m.markerType === markerType ? total + 1 : total;
      }, 0);

      if (currentNumOfType < minNumOfMarkerTypes) {
        const error: MinNumOfMarkerTypeValidationError = {
          kind: 'minNumOfMarkerType',
          markerType,
          minNumOfMarkerTypes,
          message: `Must have at least ${minNumOfMarkerTypes} marker(s) of type ${markerType}.`,
        };
        errors.push(error);
      }
    }
  }

  // Validate every marker.
  for (let i = 0; i < markers.length; i++) {
    const marker = markers[i];
    const otherMarkers = [...markers.slice(0, i), ...markers.slice(i + 1)];
    errors.push(...validateMarker(duration, otherMarkers, marker));
  }

  // Sort by priority (descending)
  errors.sort(
    (a, b) => validationErrorPriority(b) - validationErrorPriority(a),
  );

  return errors;
};

// Return the re-validated markers when changing the duration. This keeps the
// end/falseEnding markers at the same timeCode relative to the end of the
// duration, and then removes any conflicting or out of bounds markers. It is
// assumed that the newDuration value has already been validated.
export const validateChangeDuration = (
  compositionRules: CompositionRules,
  oldDuration: number,
  newDuration: number,
  existingMarkers: IMarker[],
): IMarker[] => {
  let markers = existingMarkers.concat();

  // Shift end/falseEnding markers so that they are the same amount of time
  // from the end of newDuration as they were from oldDuration.
  markers = markers.map((marker) => {
    const timeFromOldDuration = oldDuration - marker.timeCode;
    if (marker.markerType === 'end') {
      const newMarker = {
        ...marker,
        timeCode: newDuration - timeFromOldDuration,
      };

      // Since there must always be an end marker, ensure that it's still in a
      // valid location so it doesn't get removed when we re-validate all
      // markers below. If it's not in a valid location, it must be because the
      // duration was shortened and the end marker is now too close to the
      // start, so move the end marker up 1 second at a time to make it valid.
      if (newDuration < oldDuration) {
        // Ensure there isn't an infinite loop in case it's not possible to
        // have a valid position for the end marker.
        for (let j = 0; j < oldDuration - newDuration; j++) {
          // Explicitly give no other markers. The purpose of this is just to
          // ensure that the end marker can in a valid location, and then all
          // other markers (if any) will have to follow.
          if (validateMarkerLocation(newDuration, [], newMarker).length === 0) {
            return newMarker;
          } else {
            newMarker.timeCode++;
          }

          // If shifting the end marker around didn't work, replace it with the
          // default end marker so we can guarantee that it fits.
          return makeDefaultMarkers(compositionRules, newDuration).find(
            (m) => m.markerType === 'end',
          )!;
        }
      }

      return newMarker;
    } else {
      return marker;
    }
  });

  // Re-validate all markers, but assume end marker is valid (for now).
  // Do this so that if e.g. a transition marker conflicts with an end
  // marker, both aren't eliminated in a single filter pass, instead preferring
  // to eliminate the transition marker.
  markers = markers.filter((marker, i) => {
    if (marker.markerType === 'end') {
      return true;
    }
    const otherMarkers = [...markers.slice(0, i), ...markers.slice(i + 1)];
    return validateMarker(newDuration, otherMarkers, marker).length === 0;
  });

  // Re-validate all markers, including end markers.
  return markers.filter((marker, i) => {
    const otherMarkers = [...markers.slice(0, i), ...markers.slice(i + 1)];
    return validateMarker(newDuration, otherMarkers, marker).length === 0;
  });
};

const validateDuration = (duration: number): ValidationError | undefined => {
  const { minDuration, maxDuration } = defaultValidationRules;

  if (duration < minDuration - epsilon) {
    return {
      kind: 'minDuration',
      minDuration,
      message: `Must be at least ${minDuration} seconds long.`,
    } as MinDurationValidationError;
  } else if (duration > maxDuration + epsilon) {
    return {
      kind: 'maxDuration',
      maxDuration,
      message: `Can not be longer than ${maxDuration} seconds.`,
    } as MaxDurationValidationError;
  } else {
    return undefined;
  }
};

const validateMarker = (
  duration: number,
  otherMarkers: IMarker[],
  marker: IMarker,
) => {
  const errors: ValidationError[] = [];
  errors.push(...validateMarkerProperties(marker));
  errors.push(...validateMarkerLocation(duration, otherMarkers, marker));
  return errors;
};

// Validate whether the properties of a marker itself are valid. This does not
// attempt to validate the location of the marker, which is done separately.
const validateMarkerProperties = (marker: IMarker) => {
  const errors: ValidationError[] = [];

  if (isRangeMarker(marker)) {
    // RULE: Minimum duration for range markers
    const minDuration = minRangeMarkerDuration(marker.markerType);
    if (marker.duration < minDuration - epsilon) {
      const error: RangeMarkerMinDurationValidationError = {
        kind: 'rangeMarkerMinDuration',
        marker,
        minDuration,
        message: `Marker of type ${marker.markerType} must be at least ${minDuration} second(s) long.`,
      };
      errors.push(error);
    }

    // RULE: Maximum duration for range markers
    const maxDuration = maxRangeMarkerDuration(marker.markerType);
    if (maxDuration !== undefined && marker.duration > maxDuration + epsilon) {
      const error: RangeMarkerMaxDurationValidationError = {
        kind: 'rangeMarkerMaxDuration',
        marker,
        maxDuration,
        message: `Marker of type ${marker.markerType} can be no more than ${maxDuration} second(s) long.`,
      };
      errors.push(error);
    }
  }

  return errors;
};

// Return a list of validation errors associated with attempting to place a
// marker to a particular location. This does NOT validate the marker
// properties (use with validateMarkerProperties). An empty array means the
// input is valid.
const validateMarkerLocation = (
  duration: number,
  otherMarkers: IMarker[],
  marker: IMarker,
) => {
  // Get the errors associated with invalid locations where a marker of that
  // type may NOT be placed.
  let errors = invalidMarkerLocations(otherMarkers, marker);

  errors = errors.filter((error) => {
    if (isTimeIntervalValidationError(error)) {
      // Check to see if the marker is in a valid or invalid location.
      const markerStart = marker.timeCode;
      const markerEnd =
        marker.timeCode + (isRangeMarker(marker) ? marker.duration : 0);

      // If the marker intersects with the validation error time interval, then
      // this error applies. Check both if the marker start/end is contained
      // within the error interval, and (implicitly for range markers), since
      // the range marker could be a superset of the error time interval, also
      // check if the error time interval is contained within the marker range.
      // The error time intervals are exclusive on both ends, so < is used
      // instead of <=.
      return (
        (markerStart > error.start + epsilon &&
          markerStart < error.end - epsilon) ||
        (markerEnd > error.start + epsilon &&
          markerEnd < error.end - epsilon) ||
        (error.start - epsilon > markerStart &&
          error.start + epsilon < markerEnd) ||
        (error.end - epsilon > markerStart && error.end + epsilon < markerEnd)
      );
    } else {
      // If the error is not a time interval validation error, then the
      // marker may not be placed anywhere.
      return true;
    }
  });

  // RULE: End marker must be in a valid location
  if (marker.markerType === 'end') {
    const {
      endMarkerMinTimeFromEnd,
      endMarkerMaxTimeFromEnd,
      falseEndingMarkerMinTimeFromEndMarker,
      falseEndingMarkerMaxTimeFromEndMarker,
    } = defaultValidationRules;

    const markerStart = marker.timeCode;
    const markerEnd = marker.timeCode + marker.duration;

    if (
      markerEnd > duration - (endMarkerMinTimeFromEnd - epsilon) ||
      markerEnd < duration - (endMarkerMaxTimeFromEnd + epsilon)
    ) {
      // End marker end (i.e., bump) must be within certain range from end duration
      const error: MarkerEndLocationValidationError = {
        kind: 'markerEndLocation',
        marker,
        start: duration - endMarkerMaxTimeFromEnd,
        end: duration - endMarkerMinTimeFromEnd,
        message: `Final note must be between ${endMarkerMinTimeFromEnd} and ${endMarkerMaxTimeFromEnd} seconds from the end.`,
      };
      errors.push(error);
    }

    if (
      markerStart >
        markerEnd - (falseEndingMarkerMinTimeFromEndMarker - epsilon) ||
      markerStart <
        markerEnd - (falseEndingMarkerMaxTimeFromEndMarker + epsilon)
    ) {
      // End marker start (i.e., ending start) must be within certain range from end marker end (i.e., bump)
      const error: MarkerFalseEndingLocationValidationError = {
        kind: 'markerFalseEndingLocation',
        marker,
        start: markerEnd - falseEndingMarkerMaxTimeFromEndMarker,
        end: markerEnd - falseEndingMarkerMinTimeFromEndMarker,
        message: `Ending start must be between ${falseEndingMarkerMinTimeFromEndMarker} and ${falseEndingMarkerMaxTimeFromEndMarker} seconds from the final note.`,
      };
      errors.push(error);
    }
  }

  return errors;
};

// Validate where a marker of a particular type can (and more importantly, can
// NOT) be placed relative to a duration and other markers. Returns a list of
// validation errors representing locations where a given marker type may NOT
// be placed. If any errors are not time interval validation errors, then the
// marker may not be placed anywhere.
const invalidMarkerLocations = (markers: IMarker[], marker: IMarker) => {
  const { markerType } = marker;

  const errors: ValidationError[] = [];

  // RULE: Maximum number of markers of type
  const maxNumOfMarkerTypes = maxMarkersOfType(markerType);
  if (maxNumOfMarkerTypes !== undefined) {
    const currentNumOfType = markers.reduce((total, m) => {
      return m.markerType === markerType ? total + 1 : total;
    }, 0);

    if (currentNumOfType >= maxNumOfMarkerTypes) {
      const error: MaxNumOfMarkerTypeValidationError = {
        kind: 'maxNumOfMarkerType',
        marker,
        markerType,
        maxNumOfMarkerTypes,
        message: `Can only have ${maxNumOfMarkerTypes} markers of type ${markerType}.`,
      };
      errors.push(error);
    }
  }

  // RULE: All markers must be X seconds from beginning
  // (end is handled explicitly by end/falseEnding markers and minimum space
  // between those markers and other markers)
  const { markerMinTimeFromStart } = defaultValidationRules;
  const startError: MarkerTooCloseToStartValidationError = {
    kind: 'markerTooCloseToStart',
    marker,
    start: Number.NEGATIVE_INFINITY,
    end: markerMinTimeFromStart,
    minTimeFromStart: markerMinTimeFromStart,
    message: `Marker must be at least ${markerMinTimeFromStart} seconds from the start.`,
  };
  errors.push(startError);

  // RULE: There must be a minimum space between markers
  const { minTimeBetweenMarkers } = defaultValidationRules;
  for (const otherMarker of markers) {
    const error: MinTimeBetweenMarkersValidationError = {
      kind: 'minTimeBetweenMarkers',
      marker,
      start: otherMarker.timeCode - minTimeBetweenMarkers,
      end:
        otherMarker.timeCode +
        (isRangeMarker(otherMarker) ? otherMarker.duration : 0) +
        minTimeBetweenMarkers,
      minTimeBetweenMarkers,
      message: `Marker must be at least ${minTimeBetweenMarkers} seconds away from other markers.`,
    };
    errors.push(error);
  }

  // RULE: All markers must come before the end marker
  const isEndMarkerType = (markerType: MarkerType) => markerType === 'end';
  if (!isEndMarkerType(markerType)) {
    for (const otherMarker of markers.filter((m) =>
      isEndMarkerType(m.markerType),
    )) {
      const error: MarkersBeforeEndMarkersValidationError = {
        kind: 'markersBeforeEndMarkers',
        marker,
        markerType,
        start: otherMarker.timeCode,
        end: Number.POSITIVE_INFINITY,
        message: `Marker of type ${markerType} must come before ${otherMarker.markerType} marker.`,
      };
      errors.push(error);
    }
  }

  return errors;
};

// Returns a numeric priority for a validation error in terms of how important
// it is to show users. The actual number doesn't matter, and it only relative
// to the other priority numbers.
const validationErrorPriority = (error: ValidationError) => {
  switch (error.kind) {
    case 'minDuration':
      return 80;
    case 'maxDuration':
      return 80;
    case 'minNumOfMarkerType':
      return 70;
    case 'maxNumOfMarkerType':
      return 60;
    case 'rangeMarkerMinDuration':
      return 30;
    case 'rangeMarkerMaxDuration':
      return 25;
    case 'markerEndLocation':
      return 50;
    case 'markerFalseEndingLocation':
      return 45;
    case 'markerTooCloseToStart':
      return 20;
    case 'minTimeBetweenMarkers':
      return 10;
    case 'markersBeforeEndMarkers':
      return 40;
    default:
      const _exhaustiveCheck: never = error.kind;
      throw new Error(`Unexpected validation error kind: ${_exhaustiveCheck}`);
  }
};

export const makeDefaultMarkers = (
  compositionRules: CompositionRules,
  duration: number,
): IMarker[] => {
  const rules = makeValidationRules(compositionRules);
  const {
    endMarkerMinTimeFromEnd,
    endMarkerDefaultTimeFromEnd,
    falseEndingMarkerMinTimeFromEndMarker,
    falseEndingMarkerDefaultTimeFromEndMarker,
  } = rules;

  const markers: IMarker[] = [];

  // First, attempt to add end marker with full range
  // NOTE: Other logic (e.g., validateChangeDuration) assumes that this
  // function will always return a default end marker, so be careful if you
  // want to no longer return a default end marker.
  const endMarker = makeRangeMarker(
    'end',
    duration -
      endMarkerDefaultTimeFromEnd -
      falseEndingMarkerDefaultTimeFromEndMarker,
    falseEndingMarkerDefaultTimeFromEndMarker,
  );

  if (validateMarker(duration, markers, endMarker).length === 0) {
    markers.push(endMarker);
  } else {
    // If the default duration is too short for the range, just add the
    // shortest possible end range marker
    markers.push(
      makeRangeMarker(
        'end',
        duration -
          endMarkerMinTimeFromEnd -
          falseEndingMarkerMinTimeFromEndMarker,
        falseEndingMarkerMinTimeFromEndMarker,
      ),
    );
  }

  return markers;
};

export const minMarkersOfType = (
  markerType: IMarker['markerType'],
  customRules?: CompositionRules,
) => {
  switch (markerType) {
    case 'end':
      return 1;
    case 'transition':
      if (customRules?.minTransitionMarkers !== undefined) {
        return customRules.minTransitionMarkers;
      }
    // fallthrough
    case 'pause':
      return 0; // no minimum
    default:
      const _exhaustiveCheck: never = markerType;
      throw new Error(`Unexpected markerType: ${_exhaustiveCheck}`);
  }
};

export const maxMarkersOfType = (
  markerType: IMarker['markerType'],
  customRules?: CompositionRules,
): number | undefined => {
  switch (markerType) {
    case 'transition':
      if (customRules?.maxTransitionMarkers !== undefined) {
        return customRules.maxTransitionMarkers;
      }
    // fallthrough
    case 'pause':
      return undefined; // no limit
    case 'end':
      return 1;
    default:
      const _exhaustiveCheck: never = markerType;
      throw new Error(`Unexpected markerType: ${_exhaustiveCheck}`);
  }
};

export const minRangeMarkerDuration = (
  markerType: IRangeMarker['markerType'],
) => {
  switch (markerType) {
    case 'pause':
      return 1;
    case 'end':
      return defaultValidationRules.falseEndingMarkerMinTimeFromEndMarker;
    default:
      const _exhaustiveCheck: never = markerType;
      throw new Error(`Unexpected markerType: ${_exhaustiveCheck}`);
  }
};

const maxRangeMarkerDuration = (
  markerType: IRangeMarker['markerType'],
): number | undefined => {
  switch (markerType) {
    case 'pause':
      return undefined;
    case 'end':
      return defaultValidationRules.falseEndingMarkerMaxTimeFromEndMarker;
    default:
      const _exhaustiveCheck: never = markerType;
      throw new Error(`Unexpected markerType: ${_exhaustiveCheck}`);
  }
};
