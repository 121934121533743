import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

const NestingCard = (props: BoxProps) => {
  return (
    <Box
      bg="rgba(0, 20, 60, 0.1)"
      borderRadius="lg"
      p={4}
      w="100%"
      {...props}
    />
  );
};

export default NestingCard;
