import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Composition } from '../../entities/Composition';
import { UNPROCESSABLE_ENTITY } from 'http-status-codes';
import { formatDuration } from '../../../../../common/client/formatters/formatDuration';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { BulkQARun, TestCase } from '../../../../common/entities/BulkQARun';

export const BulkQA = ({ composition }: { composition: Composition }) => {
  const [totalTrials, setTotalTrials] = useState(99);

  // The /bulkQA endpoint will kick off an async task and return a UUID
  const [uuid, setUuid] = useState<string | undefined>();

  // After starting the async task, poll for the result every few seconds.
  // If the result is not ready, increase pollNum after a few seconds to poll again.
  const [pollNum, setPollNum] = useState(1);

  const [failedCases, setFailedCases] = useState<TestCase[]>([]);
  const [succeededCases, setSucceededCases] = useState<TestCase[]>([]);
  const numCases = failedCases.length + succeededCases.length;

  const [expandedFailedCases, setExpandedFailedCases] = useState(
    new Set<number>(),
  );
  const [expandedSucceededCases, setExpandedSucceededCases] = useState(
    new Set<number>(),
  );

  const [timeStart, setTimeStart] = useState<number | undefined>();
  const [timeEnd, setTimeEnd] = useState<number | undefined>();
  const [error, setError] = useState('');
  const [priorRuns, setPriorRuns] = useState<BulkQARun[]>([]);
  const [velocirender, setVelocirender] = useState<boolean>(false);

  const generateBulkQA = () => {
    setTimeStart(new Date().getTime());

    // The bulk tested script runs numTrials once for each of short, medium,
    // and long durations, so only request 1/3 of what the user wants.
    const numTrials = Math.ceil(totalTrials / 3);

    axios
      .post<{ uuid: string }>(
        `/compositions/${composition.id}/bulkQA`,
        { numTrials, velocirender },
        { headers: { 'Content-Type': 'application/json' } },
      )
      .then((response) => {
        const { uuid } = response.data;
        setUuid(uuid);
      })
      .catch((error) => {
        setError(`Unable to generate bulk QA: ${error.message}`);
        setTimeEnd(new Date().getTime());
      });
  };

  const fetchPriorRuns = () => {
    axios
      .get<BulkQARun[]>(
        `/compositions/bulkQAResults?compositionId=${composition.id}`,
      )
      .then((response) => {
        setPriorRuns(response.data);
      });
  };

  useEffect(() => {
    fetchPriorRuns();
  }, []);

  useEffect(() => {
    if (!uuid) {
      return;
    }

    axios
      .get<{ results: { [duration in string]: TestCase[] } }>(
        `/compositions/bulkQAResults/${uuid}`,
      )
      .then((response) => {
        setError(undefined);
        const { results } = response.data;

        const allResults = Object.keys(results)
          .reduce((arr, duration) => {
            arr.push(...results[duration]);
            return arr;
          }, [] as TestCase[])
          .sort((a, b) => a.duration - b.duration);

        setFailedCases(allResults.filter((r) => !!r.errors));
        setSucceededCases(allResults.filter((r) => !r.errors));
        setTimeEnd(new Date().getTime());
        fetchPriorRuns();
      })
      .catch((error) => {
        setFailedCases([]);
        setSucceededCases([]);
        if (error.response?.status === UNPROCESSABLE_ENTITY) {
          // After a timeout, increase the pollNum to poll again
          setTimeout(() => setPollNum(pollNum + 1), 5000);
        } else {
          setError(
            `Unable to get QA results: ${
              error.response.data?.error ?? error.message
            }`,
          );
          setTimeEnd(new Date().getTime());
        }
      });
  }, [uuid, pollNum, error]);

  return (
    <VStack align="flex-start" spacing={4} w="100%">
      <Stack
        direction={['column', 'row']}
        align="flex-start"
        spacing={4}
        w="100%"
      >
        <Box borderWidth="1px" borderRadius="lg" p={4} flexShrink={0}>
          <Heading as="h4" size="md" mb={2}>
            Bulk Composer QA
          </Heading>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              generateBulkQA();
            }}
          >
            <VStack align="flex-start">
              <FormControl>
                <FormLabel>Number of Trials</FormLabel>
                <NumberInput
                  step={3}
                  min={3}
                  max={300}
                  value={totalTrials}
                  onChange={(value) => setTotalTrials(parseInt(value, 10))}
                >
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
                <Checkbox
                  mt={3}
                  isChecked={velocirender}
                  onChange={(e) => setVelocirender(e.target.checked)}
                >
                  Velocirender QA
                </Checkbox>
              </FormControl>

              <Button type="submit" colorScheme="blue" isDisabled={!!timeStart}>
                Start
              </Button>
            </VStack>
          </form>
        </Box>

        <VStack align="flex-start" w="100%" mb={4}>
          {(error || !!timeStart || numCases > 0) && (
            <Alert
              status={
                error
                  ? 'error'
                  : !!timeStart && numCases === 0
                  ? 'info'
                  : 'success'
              }
              flexDirection="column"
              alignItems="flex-start"
            >
              <AlertTitle mb={1} fontSize="lg">
                {error
                  ? error
                  : !!timeStart && numCases === 0
                  ? `Please Wait... (${pollNum})`
                  : `${succeededCases.length} / ${numCases}`}
              </AlertTitle>
              {timeStart && timeEnd && (
                <AlertDescription>
                  Time Elapsed: {formatDuration((timeEnd - timeStart) / 1000)}
                </AlertDescription>
              )}
            </Alert>
          )}

          {failedCases.length > 0 && (
            <>
              <HStack>
                <Heading as="h4" size="md">
                  Failed
                </Heading>

                <Button
                  size="sm"
                  onClick={() => {
                    if (expandedFailedCases.size >= failedCases.length) {
                      setExpandedFailedCases(new Set());
                    } else {
                      setExpandedFailedCases(
                        new Set([...Array(failedCases.length).keys()]),
                      );
                    }
                  }}
                >
                  {expandedFailedCases.size >= failedCases.length
                    ? 'Collapse All'
                    : 'Expand All'}
                </Button>
              </HStack>

              <Box
                borderWidth="1px"
                borderRadius="lg"
                w="100%"
                overflowX="auto"
              >
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>Duration</Th>
                      <Th>Markers</Th>
                      <Th>Pauses</Th>
                      <Th>Ending Start</Th>
                      <Th>Bump</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {failedCases.map((testCase, i) => (
                      <>
                        <Tr
                          key={`${uuid}.${i}`}
                          onClick={() => {
                            const newSet = new Set(expandedFailedCases);
                            if (expandedFailedCases.has(i)) {
                              newSet.delete(i);
                            } else {
                              newSet.add(i);
                            }
                            setExpandedFailedCases(newSet);
                          }}
                        >
                          <Td>{expandedFailedCases.has(i) ? '▼' : '▶️'}</Td>
                          <Td>{testCase.duration}</Td>
                          <Td>{testCase.markers.join(',')}</Td>
                          <Td>{testCase.pauses?.join(',')}</Td>
                          <Td>{testCase.falseEndingMarker}</Td>
                          <Td>{testCase.endingMarker}</Td>
                        </Tr>
                        {expandedFailedCases.has(i) && (
                          <Tr key={`${uuid}.${i}expanded`} bgColor="red.100">
                            <Td colSpan={2}></Td>
                            <Td colSpan={4}>
                              <pre>{testCase.errors}</pre>
                            </Td>
                          </Tr>
                        )}
                      </>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}

          {succeededCases.length > 0 && (
            <>
              <HStack>
                <Heading as="h4" size="md">
                  Succeeded
                </Heading>

                <Button
                  size="sm"
                  onClick={() => {
                    if (expandedSucceededCases.size >= succeededCases.length) {
                      setExpandedSucceededCases(new Set());
                    } else {
                      setExpandedSucceededCases(
                        new Set([...Array(succeededCases.length).keys()]),
                      );
                    }
                  }}
                >
                  {expandedSucceededCases.size >= succeededCases.length
                    ? 'Collapse All'
                    : 'Expand All'}
                </Button>
              </HStack>
              <Box
                borderWidth="1px"
                borderRadius="lg"
                w="100%"
                overflowX="auto"
              >
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th></Th>
                      <Th>Duration</Th>
                      <Th>Markers</Th>
                      <Th>Pauses</Th>
                      <Th>Ending Start</Th>
                      <Th>Bump</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {succeededCases.map((testCase, i) => (
                      <>
                        <Tr
                          key={`${uuid}.${i}`}
                          onClick={() => {
                            const newSet = new Set(expandedSucceededCases);
                            if (expandedSucceededCases.has(i)) {
                              newSet.delete(i);
                            } else {
                              newSet.add(i);
                            }
                            setExpandedSucceededCases(newSet);
                          }}
                        >
                          <Td>{expandedSucceededCases.has(i) ? '▼' : '▶️'}</Td>
                          <Td>{testCase.duration}</Td>
                          <Td>{testCase.markers.join(',')}</Td>
                          <Td>{testCase.pauses?.join(',')}</Td>
                          <Td>{testCase.falseEndingMarker}</Td>
                          <Td>{testCase.endingMarker}</Td>
                        </Tr>
                        {expandedSucceededCases.has(i) && (
                          <Tr key={`${uuid}.${i}expanded`} bgColor="green.100">
                            <Td colSpan={2}></Td>
                            <Td colSpan={4}>
                              <pre>{testCase.compositionGuide}</pre>
                            </Td>
                          </Tr>
                        )}
                      </>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            </>
          )}
        </VStack>
      </Stack>

      <Box borderWidth="1px" borderRadius="lg" p={4} flexShrink={0}>
        <Heading as="h4" size="md" mb={2}>
          Prior Runs
        </Heading>

        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Trials</Th>
              <Th>Failed Runs</Th>
              <Th>Run Date</Th>
              <Th>Velocirender QA</Th>
            </Tr>
          </Thead>
          <Tbody>
            {priorRuns?.map((priorRun) => (
              <Tr
                key={priorRun.uuid}
                onClick={() => {
                  setUuid(priorRun.uuid);
                }}
                bgColor={priorRun.uuid == uuid ? 'green.100' : undefined}
              >
                <Td>{priorRun.numRuns}</Td>
                <Td>{priorRun.numFailedRuns}</Td>
                <Td>{new Date(priorRun.runDate).toLocaleString()}</Td>
                <Td>{priorRun.velocirender ? 'Yes' : 'Composer Only'}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </VStack>
  );
};
