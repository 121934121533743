import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { NewFile } from './new_file';
import { CompositionEditForm } from './edit_form';
import { CompositionMasterView } from './master_view';
import { ConfigEditor } from './config_editor';
import { Composition } from '../../entities/Composition';
import { S3File } from '../../entities/S3File';
import { FilesTable } from './files_table';
import { TestGeneration } from './test_generation';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  CircularProgress,
  CloseButton,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react';
import {
  BsFileEarmarkCode,
  BsGem,
  BsMusicNoteBeamed,
  BsFiles,
  BsShuffle,
} from 'react-icons/bs';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { useUserContext } from '../../contexts/user';
import { MasteringPresetSelector } from './mastering_presets/preset_selector';
import { logger } from '../../../../common/infra/logger';
import { DynasampleStatus } from './dynasample_status';

export const CompositionDetail: React.FC<{}> = () => {
  const { user } = useUserContext();
  const { compositionId } = useParams<{ compositionId: string }>();
  const [files, setFiles] = useState<S3File[] | undefined>(undefined);
  const [errors, setErrors] = useState<string | undefined>(undefined);
  const [composition, setComposition] = useState<Composition | undefined>(
    undefined,
  );
  const [updating, setUpdating] = useState<boolean>(false);
  const [configFile, setConfigFile] = useState<S3File | undefined>(undefined);
  const [canPromote, setCanPromote] = useState(false);
  const [canDemote, setCanDemote] = useState(false);
  const [dynasamplesExist, setDynasamplesExist] = useState<boolean>(false);
  const [dynasamplesReady, setDynasamplesReady] = useState<boolean>(false);
  const [dynasampleErrorLog, setDynasampleErrorLog] = useState<
    string | undefined
  >(undefined);
  const [canTransitionToNeedsApproval, setCanTransitionToNeedsApproval] =
    useState(false);

  useEffect(() => {
    fetchFiles();
    fetchComposition();
    fetchDynasampleStatus();
  }, []);

  const fetchFiles = () => {
    const request = axios.get<{ compositionName: string; files: S3File[] }>(
      `/compositions/${compositionId}/files/all`,
    );
    request.then(({ data }) => {
      setFiles(data.files);
      setConfigFile(
        data.files.find((f) => f.name.includes('.json')) ?? {
          name: undefined,
          id: 0,
          lastModified: undefined,
        },
      );
    });
  };

  const fetchDynasampleStatus = () => {
    const request = axios.get<{
      dynasamplesExist: boolean;
      dynasamplesReady: boolean;
      dynasampleErrorLog: string;
    }>(`/compositions/${compositionId}/dynasamples/status`);
    request.then(({ data }) => {
      setDynasamplesExist(data.dynasamplesExist);
      setDynasamplesReady(data.dynasamplesReady);
      setDynasampleErrorLog(data.dynasampleErrorLog);
      logger.info(data);
    });
  };

  const fetchComposition = () => {
    const compositionRequest = axios.get<{
      composition: Composition;
      canPromote: boolean;
      canDemote: boolean;
      canTransitionToNeedsApproval: boolean;
    }>(`/compositions/${compositionId}/detailed`);
    compositionRequest
      .then(({ data }) => {
        setComposition(data.composition);
        setUpdating(false);
        setCanPromote(data.canPromote);
        setCanDemote(data.canDemote);
        setCanTransitionToNeedsApproval(data.canTransitionToNeedsApproval);
      })
      .catch((error) => {
        setUpdating(false);
      });
  };

  const onAction = async (errors: string | undefined) => {
    if (errors === undefined) {
      setConfigFile(undefined);
      fetchComposition();
      fetchFiles();
    } else {
      setErrors(errors);
    }
  };

  const submitCompositionUpdate = () => {
    setUpdating(true);
    axios
      .patch(`/compositions/${composition.id}/update`, composition, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        fetchComposition();
        onAction(undefined);
      })
      .catch((error) => {
        logger.warn(`Error updating composition ${error.message}`);
        logger.warn(error.response.data);
        logger.warn(error.response.status);
        logger.warn(error.response.headers);
        if (error.response.data.error) {
          onAction(error.response.data.error);
        }
        setUpdating(false);
      });
  };

  if (!composition) {
    return <CircularProgress isIndeterminate />;
  }

  return (
    <>
      <Heading fontSize="xl" w="100%" mb={4}>
        <Breadcrumb spacing={2} separator={<Icon as={HiOutlineChevronRight} />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/compositions">Dynascores</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <Text>{composition.name}</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </Heading>

      {errors && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <AlertTitle mr={2}>Error</AlertTitle>
          <AlertDescription>{errors}</AlertDescription>
          <CloseButton
            position="absolute"
            right={2}
            top={2}
            onClick={() => setErrors(undefined)}
          />
        </Alert>
      )}

      <Tabs w="100%">
        <TabList>
          <Tab>
            <Icon as={BsMusicNoteBeamed} mr={2} /> General
          </Tab>
          <Tab>
            <Icon as={BsFiles} mr={2} /> Files
          </Tab>
          <Tab>
            <Icon as={BsFileEarmarkCode} mr={2} /> Configuration
          </Tab>
          <Tab>
            <Icon as={BsGem} mr={2} /> QA
          </Tab>
          <Tab>
            <Icon as={BsShuffle} mr={2} /> Dynasample Status
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {/* General */}
            <Stack direction={['column', 'row']} align="flex-start" spacing={4}>
              <CompositionEditForm
                composition={composition}
                onChange={setComposition}
                onSubmit={submitCompositionUpdate}
                isUpdating={updating}
              />

              <CompositionMasterView
                composition={composition}
                fetchComposition={fetchComposition}
                onUpdate={onAction}
              />
            </Stack>
          </TabPanel>
          <TabPanel>
            {/* Files */}
            <Stack
              direction={['column', 'row']}
              align="flex-start"
              spacing={4}
              mb={4}
            >
              <Box borderWidth="1px" borderRadius="lg" p={4}>
                <VStack align="flex-start">
                  <NewFile
                    compositionId={compositionId}
                    label="MP3 Demo"
                    prefix="demo"
                    onUpload={onAction}
                    accept=".mp3"
                  />

                  <NewFile
                    compositionId={compositionId}
                    label="Config Files (master.mid, mix.csv, &amp; config.json)"
                    onUpload={onAction}
                    accept=".mid,.csv,.json"
                    multiple={true}
                  />

                  <MasteringPresetSelector
                    compositionId={compositionId}
                    onUpload={onAction}
                  />

                  <NewFile
                    compositionId={compositionId}
                    label="Samples (for custom presets)"
                    prefix="sample"
                    onUpload={onAction}
                    accept=".zip"
                  />

                  <NewFile
                    compositionId={compositionId}
                    label="Custom preset"
                    prefix="preset"
                    onUpload={onAction}
                    accept=".nkm,.nbkt,.nksr,.nksf,.nabs,.prt_omn,.prt_trl,.prt_key"
                    multiple={true}
                  />

                  <NewFile
                    compositionId={compositionId}
                    label="Supplementary (such as Session files, not used for render)"
                    prefix="archive"
                    onUpload={onAction}
                  />
                </VStack>
              </Box>

              <FilesTable
                files={files}
                onUpdate={onAction}
                onDelete={onAction}
                compositionId={parseInt(compositionId)}
              />
            </Stack>
          </TabPanel>
          <TabPanel>
            {/* Configuration */}
            {configFile && (
              <>
                <Box borderWidth="1px" borderRadius="lg" p={4}>
                  <ConfigEditor
                    filePath={configFile.name}
                    compositionId={composition.id}
                    onUpload={onAction}
                  />
                </Box>
              </>
            )}
          </TabPanel>
          <TabPanel>
            {/* QA */}
            {composition && (
              <TestGeneration
                composition={composition}
                canPromote={canPromote}
                canDemote={canDemote}
                canTransitionToNeedsApproval={canTransitionToNeedsApproval}
                updating={updating}
                onUpdate={onAction}
                setUpdating={setUpdating}
              />
            )}
          </TabPanel>
          <TabPanel>
            <DynasampleStatus
              dynasamplesExist={dynasamplesExist}
              dynasamplesReady={dynasamplesReady}
              samplerErrorLog={dynasampleErrorLog}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
