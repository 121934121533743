export function formatDuration(
  duration: number,
  options?: {
    frameRate?: number;
    neverShowMilliseconds?: boolean;
    alwaysShowMilliseconds?: boolean;
  },
) {
  if (isNaN(duration)) return '';
  duration = duration / (options?.frameRate || 1);

  const hours = Math.floor(duration / 60 / 60);
  const minutes = Math.floor((duration - hours * 60 * 60) / 60);
  const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);
  const milliseconds = Math.round((duration - Math.floor(duration)) * 1000);

  const pad = (n: number, minimumIntegerDigits: number) => {
    return n.toLocaleString('en-US', {
      minimumIntegerDigits,
      maximumFractionDigits: 0,
      useGrouping: false,
    });
  };

  let ret = '';

  // Only show hours if there are any
  if (hours > 0) {
    ret += `${pad(hours, 2)}:`;
  }

  ret += `${pad(minutes, 2)}:${pad(seconds, 2)}`;

  if (
    options?.neverShowMilliseconds !== true &&
    (options?.alwaysShowMilliseconds || milliseconds > 0)
  ) {
    ret += `.${pad(milliseconds, 3)}`;
  }

  return ret;
}

export function parseDurationString(durationString: string) {
  let milliseconds = 0;

  const msSplit = durationString.split('.');
  if (msSplit.length > 2) {
    return NaN;
  } else if (msSplit.length == 2 && msSplit[1].length > 0) {
    if (isNaN(parseInt(msSplit[1], 10))) {
      return NaN;
    }

    milliseconds = Math.round(parseFloat(`0.${msSplit[1]}`) * 1000);
    if (isNaN(milliseconds) || milliseconds < 0 || milliseconds > 1000) {
      return NaN;
    }
  }

  const [str1, str2, str3] = msSplit[0].split(':');

  const val1 = parseInt(str1, 10);
  const val2 = parseInt(str2, 10);
  const val3 = parseInt(str3, 10);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    return val1 + milliseconds / 1000;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    return val1 * 60 + val2 + milliseconds / 1000;
  }

  if (!isNaN(val1) && !isNaN(val2) && !isNaN(val3)) {
    return val1 * 60 * 60 + val2 * 60 + val3 + milliseconds / 1000;
  }

  return NaN;
}
