import axios from 'axios';
import React, { useState, useEffect } from 'react';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import { ConfigForm } from './config_form/config_form';
import { CompositionConfig, emptyCompositionConfig } from './configType';
import { Permissions } from '../../components/Permissions';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  CircularProgress,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { useUserContext } from '../../contexts/user';
import { Permission } from '../../../../common/entities/Permission';
import { logger } from '../../../../common/infra/logger';

export const ConfigEditor: React.FC<{
  compositionId: number;
  filePath?: string;
  onUpload(error: string | undefined): void;
}> = ({ compositionId, filePath, onUpload }) => {
  const { user } = useUserContext();
  const [loaded, setLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);

  const [initialConfigJson, setInitialConfigJson] = useState<CompositionConfig>(
    emptyCompositionConfig,
  );
  const [configJsonString, setConfigJsonString] = useState<string>(
    JSON.stringify(emptyCompositionConfig),
  );
  const [formEditor, setFormEditor] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  useEffect(() => {
    fetchConfigJson(filePath);
  }, []);

  const fetchConfigJson = (configFile?: string) => {
    if (!configFile) {
      // No file, just use default config
      setLoaded(true);
      return;
    }

    const configRequest = axios.get<CompositionConfig>(
      `/compositions/${compositionId}/files/download?fileName=${encodeURIComponent(
        configFile,
      )}`,
    );
    configRequest
      .then(({ data }) => {
        if (typeof data === 'object') {
          setInitialConfigJson(data);
          setConfigJsonString(JSON.stringify(data));
          setLoaded(true);
        } else {
          logger.debug('Could not parse valid json config file');
          setLoadingError(true);
        }
      })
      .catch((error) => {
        logger.warn(
          `Error fetching config json ${configFile}: ${error.message}`,
        );
        setLoadingError(true);
      });
  };

  const submitFile = (configJson: string) => {
    setSaving(true);
    const blob = new Blob([configJson], { type: 'application/json' });
    let fileName = 'config.json';

    if (filePath) {
      fileName = filePath.substring(
        filePath.lastIndexOf('/') + 1,
        filePath.length,
      );
    }

    const file = new File([blob], fileName);

    const formData = new FormData();
    formData.append('file', file, fileName);
    formData.append('is_master_chain_file', 'false');
    axios
      .post(`/compositions/${compositionId}/files/new`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        onUpload(undefined);
        fetchConfigJson(filePath);
        setFormEditor(false);
      })
      .catch((error) => {
        logger.warn(error);
        if (error.response.data.error) {
          onUpload(error.response.data.error);
        } else {
          onUpload('Unexpected error saving edited json config file!');
        }
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!loaded) {
    return <CircularProgress isIndeterminate />;
  }

  if (loadingError) {
    return (
      <Alert status="error" mb={4}>
        <AlertIcon />
        <AlertTitle mr={2}>Error</AlertTitle>
        <AlertDescription>Unable to load configuration JSON.</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      {formEditor && (
        <ConfigForm
          initialConfigJson={initialConfigJson}
          setFormEditor={setFormEditor}
          save={submitFile}
          saving={saving}
          compositionId={compositionId}
        />
      )}
      {!formEditor && (
        <VStack>
          <Permissions user={user} show={[Permission.ViewAllDynascores]}>
            <HStack justify="flex-end" w="100%">
              <Button size="sm" onClick={() => setFormEditor(true)}>
                Form Editor
              </Button>

              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => submitFile(configJsonString)}
                isDisabled={saving}
              >
                Save
              </Button>
            </HStack>

            <JSONInput
              id="1"
              placeholder={initialConfigJson}
              locale={locale}
              height="500px"
              onChange={(content) => {
                if (!content.error) {
                  setConfigJsonString(content.json);
                }
              }}
            />
          </Permissions>
        </VStack>
      )}
    </>
  );
};
