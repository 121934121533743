import React from 'react';
import { CompositionBlockRules } from '../configType';
import { Block } from './block';
import { Select } from '@chakra-ui/react';

interface Props {
  blocks: string[];
  values: { [name in string]: CompositionBlockRules };
  onChange: (values: { [name in string]: CompositionBlockRules }) => void;
  onAdd: (name: string) => void;
}

export const BlockRules = ({ blocks, values, onChange, onAdd }: Props) => {
  return (
    <>
      {Object.keys(values).map((name) => (
        <Block
          key={name}
          name={name}
          values={values[name]}
          onChange={(rules) => {
            const copy = { ...values };
            copy[name] = rules;
            onChange(copy);
          }}
          onDelete={() => {
            const copy = { ...values };
            delete copy[name];
            onChange(copy);
          }}
        />
      ))}

      <Select
        variant="filled"
        placeholder="Add Block Rules"
        value=""
        onChange={(e) => onAdd(e.target.value)}
      >
        {blocks.map((name) => (
          <option key={name} value={name} disabled={!!values[name]}>
            {name}
          </option>
        ))}
      </Select>
    </>
  );
};
