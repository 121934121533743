import React from 'react';
import {
  CompositionBlockRules,
  SHORTENING_OPTIONS,
  ELONGATION_OPTIONS,
} from '../configType';
import { RuleSets } from './rule_sets';
import NestingCard from './nesting_card';
import { Button, Flex, Heading, Stack } from '@chakra-ui/react';

interface Props {
  name: string;
  values: CompositionBlockRules;
  onChange: (values: CompositionBlockRules) => void;
  onDelete: () => void;
}

export const Block = ({ name, values, onChange, onDelete }: Props) => {
  return (
    <NestingCard>
      <Flex align="center" justify="space-between" w="100%" mb={2}>
        <Heading as="h3" fontSize="lg">
          {name}
        </Heading>

        <Button
          size="sm"
          colorScheme="red"
          tabIndex={-1}
          onClick={() => onDelete()}
        >
          Delete Block Rule
        </Button>
      </Flex>

      <Stack direction={['column', 'row']} spacing={4}>
        <NestingCard>
          <Heading as="h4" fontSize="md" mb={2}>
            Shortening Rules
          </Heading>
          <RuleSets
            options={SHORTENING_OPTIONS}
            values={values.shortening_rules || []}
            onChange={(shortening_rules) =>
              onChange({ ...values, shortening_rules })
            }
          />
        </NestingCard>

        <NestingCard>
          <Heading as="h4" fontSize="md" mb={2}>
            Elongation Rules
          </Heading>
          <RuleSets
            options={ELONGATION_OPTIONS}
            values={values.elongation_rules || []}
            onChange={(elongation_rules) =>
              onChange({ ...values, elongation_rules })
            }
          />
        </NestingCard>
      </Stack>
    </NestingCard>
  );
};
