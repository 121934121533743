import React from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Button, Flex, Select } from '@chakra-ui/react';

interface Props<T> {
  options: T[];
  values: T[];
  onChange: (values: T[]) => void;
}

export const RuleSet = <T extends string>({
  options,
  values,
  onChange,
}: Props<T>) => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const copy = [...values];
    const [item] = copy.splice(result.source.index, 1);
    copy.splice(result.destination.index, 0, item);
    onChange(copy);
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{}}
            >
              {values.map((rule, index) => (
                <Draggable key={rule} draggableId={rule} index={index}>
                  {(provided, snapshot) => (
                    <Flex
                      align="center"
                      justify="space-between"
                      mb={2}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        backgroundColor: snapshot.isDragging
                          ? 'rgba(0, 0, 0, 0.1)'
                          : 'inherit',
                      }}
                    >
                      {rule}

                      <Button
                        size="sm"
                        colorScheme="red"
                        tabIndex={-1}
                        onClick={() =>
                          onChange([
                            ...values.slice(0, index),
                            ...values.slice(index + 1),
                          ])
                        }
                      >
                        Delete Rule
                      </Button>
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Select
        bg="white"
        size="sm"
        placeholder="Add Rule"
        required={values.length === 0} // don't allow empty rule set
        value=""
        onChange={(e) => onChange([...values, e.target.value as T])}
      >
        {options.map((option) => (
          <option
            key={option}
            value={option}
            disabled={values.includes(option)}
          >
            {option}
          </option>
        ))}
      </Select>
    </div>
  );
};
