const defaultDateFormatter = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

// TODO: Niceties like 'Just Now' for very recently created dynascores.
export function formatDate(date: Date) {
  return defaultDateFormatter.format(date);
}

const approximateDateFormatter = new Intl.DateTimeFormat('default', {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
});

export function formatApproximateDate(date: Date | undefined) {
  if (!date) {
    return '';
  }

  const now = new Date();
  const startOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
  );
  if (date >= startOfToday) {
    return 'Today';
  }

  const startOfYesterday = new Date(startOfToday);
  startOfYesterday.setDate(startOfToday.getDate() - 1);
  if (date >= startOfYesterday) {
    return 'Yesterday';
  }

  return approximateDateFormatter.format(date);
}
