import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Playlist } from '../../../entities/Playlist';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Icon,
  Skeleton,
  Table,
  Text,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Permission } from '../../../../../common/entities/Permission';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useUserContext } from '../../../contexts/user';
import { NewPlaylist } from './new_playlist';
import { EditModal } from './edit_modal';
import { LoadingModal } from '../../../components/LoadingModal';
import { logger } from '../../../../../common/infra/logger';

export const PlaylistsTable: React.FC<{}> = () => {
  const { user } = useUserContext();
  const [playlists, setPlaylists] = useState<Playlist[] | undefined>(undefined);
  const [edited, setEdited] = useState<boolean>(false);
  const [edittingId, setEdittingId] = useState<number>(undefined);
  const [canPromote, setCanPromote] = useState<boolean>(false);
  const [promoting, setPromoting] = useState<boolean>(false);

  const hasPermission =
    user.aspects?.employee &&
    user.permissions.includes(Permission.ViewAllDynascores);
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isLoadingOpen,
    onOpen: onLoadingOpen,
    onClose: onLoadingClose,
  } = useDisclosure();
  const toast = useToast();

  const fetchPlaylists = () => {
    setLoading(true);
    const request = axios.get<{ playlists: Playlist[] }>('/playlists/all');
    request
      .then(({ data }) => {
        let playlists = data.playlists;
        setPlaylists(playlists);
      })
      .finally(() => setLoading(false));
  };

  const fetchCanPromoteDemote = () => {
    const request = axios.get<{ canPromote: boolean; canDemote: boolean }>(
      '/compositions/canPromoteDemote',
    );
    request.then(({ data }) => {
      setCanPromote(data.canPromote);
    });
  };

  useEffect(() => {
    fetchPlaylists();
    fetchCanPromoteDemote();
  }, []);

  const updatePlaylistsOrder = () => {
    axios
      .patch(
        `/playlists/bulkUpdateOrder`,
        {
          playlistsOrder: playlists.map((p) => ({
            id: p.id,
            displayOrder: p.displayOrder,
          })),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        fetchPlaylists();
      })
      .catch((error) => {
        logger.warn(`Error bulk updating orders ${error.message}`);
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index == result.source.index) {
      return;
    }

    // // Create new copy of playlists with priority set in displayOrder
    const copy = [...playlists];
    const [item] = copy.splice(result.source.index, 1);
    item.displayOrder = result.destination.index + 1;
    copy.splice(result.destination.index, 0, item);

    // store new playlists used in params of update
    const newOrder = copy.map((p, index) => {
      const displayOrder = index + 1;
      return {
        id: p.id,
        displayOrder: displayOrder,
      };
    });

    // reset playlists in displayOrder of draft playlists
    setPlaylists(
      copy.map((p) => ({
        ...p,
        displayOrder: newOrder.find((s) => s.id === p.id)?.displayOrder,
      })),
    );
    setEdited(true);
  };

  const onDelete = (id: number, name?: string) => {
    if (window.confirm(`Are you sure you want to delete ${name}?`)) {
      axios
        .delete(`/playlists/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          fetchPlaylists();
        })
        .catch((error) => {
          toast({
            position: 'top',
            title: 'Error Deleting Playlist',
            description: (
              <VStack align="flex-start">
                <Text>{error.response.data.error || 'Unkown Error'}</Text>
              </VStack>
            ),
            status: 'error',
            duration: null,
            isClosable: true,
          });
        });
    }
  };

  const promoteAllPlaylists = () => {
    const confirmMessage = `Are you sure you want to promote all playlists?`;
    if (window.confirm(confirmMessage)) {
      setPromoting(true);
      onLoadingOpen();
      axios
        .patch(`/playlists/sendPromote`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          toast({
            position: 'top',
            title: 'Promoted Playlists',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          logger.warn(`Error promoting all playlists ${error.message}`);
          if (error.response?.data?.error) {
            logger.warn(error.response.data.error);
          }
          toast({
            position: 'top',
            title: 'Error Promoting Playlist',
            description: (
              <VStack align="flex-start">
                <Text>{error.response?.data?.error || 'Unkown Error'}</Text>
              </VStack>
            ),
            status: 'error',
            duration: null,
            isClosable: true,
          });
        })
        .finally(() => {
          setPromoting(false);
          onLoadingClose();
        });
    }
  };

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        w="100%"
        mb={4}
      >
        <Heading fontSize="xl">Playlists</Heading>

        <HStack>
          {canPromote && (
            <Button onClick={promoteAllPlaylists} isDisabled={promoting}>
              Promote Playlists
            </Button>
          )}
          <Button
            colorScheme="gray"
            size="sm"
            onClick={updatePlaylistsOrder}
            isDisabled={!edited}
          >
            Save Order
          </Button>
          <NewPlaylist
            onSave={() => {
              fetchPlaylists();
            }}
          />
        </HStack>
      </Flex>

      <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th w="1%"></Th>
              <Th w="1%">Order</Th>
              <Th w="80%">Name</Th>
              <Th></Th>
            </Tr>
          </Thead>
          {loading && (
            <Tbody>
              {[...Array(10)].map((_, index) => (
                <Tr key={index}>
                  <Td>
                    <Icon as={BsThreeDotsVertical} mx={2} boxSize={3} />
                  </Td>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>
                  <Td>
                    <Skeleton height="20px" />
                  </Td>
                  <Td></Td>
                </Tr>
              ))}
            </Tbody>
          )}
          {!loading && (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable
                droppableId="droppable"
                isDropDisabled={!hasPermission}
              >
                {(provided) => (
                  <Tbody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{}}
                  >
                    {playlists &&
                      playlists.map((playlist, index) => (
                        <Draggable
                          key={`draggable_${playlist.id}`}
                          draggableId={playlist.id.toString()}
                          index={index}
                          isDragDisabled={!hasPermission}
                        >
                          {(provided, snapshot) => (
                            <Tr
                              key={playlist.id}
                              align="center"
                              justify="space-between"
                              mb={2}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                backgroundColor: snapshot.isDragging
                                  ? 'rgba(0, 0, 0, 0.1)'
                                  : 'inherit',
                              }}
                            >
                              <Td>
                                <Icon
                                  as={BsThreeDotsVertical}
                                  mx={2}
                                  boxSize={3}
                                />
                              </Td>
                              <Td>{index + 1}</Td>
                              <Td>{playlist.name}</Td>
                              <Td>
                                <Flex justify="flex-end">
                                  <HStack>
                                    <Button
                                      colorScheme="blue"
                                      size="sm"
                                      onClick={() => {
                                        onOpen();
                                        setEdittingId(playlist.id);
                                      }}
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      colorScheme="red"
                                      size="sm"
                                      onClick={() =>
                                        onDelete(playlist.id, playlist.name)
                                      }
                                      disabled={!hasPermission}
                                    >
                                      Delete
                                    </Button>
                                  </HStack>
                                </Flex>
                              </Td>
                            </Tr>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Tbody>
                )}
              </Droppable>
            </DragDropContext>
          )}
        </Table>
      </Box>
      <EditModal
        isOpen={isOpen}
        onClose={onClose}
        playlistId={edittingId}
        hasPermission={hasPermission}
        playlistName={playlists?.find((p) => p.id === edittingId)?.name}
      />
      <LoadingModal isOpen={isLoadingOpen} onClose={onLoadingClose} />
    </>
  );
};
