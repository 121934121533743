import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { dateReviver } from '../../../../../../common/api/http';
import { Composition } from '../../../entities/Composition';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Flex,
  Link,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { IUser } from '../../../entities/User';
import { Permission } from '../../../../../common/entities/Permission';
import { Permissions } from '../../../components/Permissions';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Facet, FacetOption } from '../../../../../common/entities/Facet';
import { Filters } from '../search_filters';
import {
  DEFAULT_INITIAL_PAGE_SIZE,
  Pagination,
} from '../../../components/Pagination';
import { useUserContext } from '../../../contexts/user';
import { LoadingModal } from '../../../components/LoadingModal';
import { logger } from '../../../../../common/infra/logger';

type SearchPriorities = Array<{ id: number; searchPriority: number }>;

export const PriorityTable: React.FC<{}> = () => {
  const [compositions, setCompositions] = useState<Composition[] | undefined>(
    undefined,
  );
  const [totalCompositionCount, setTotalCompositionCount] =
    useState<number>(undefined);
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const [genreFacetOptions, setGenreFacetOptions] = useState<FacetOption[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_INITIAL_PAGE_SIZE);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchPriorites, setSearchPriorities] = useState<
    SearchPriorities | undefined
  >(undefined);
  const [edited, setEdited] = useState<boolean>(false);
  const { user } = useUserContext();
  const hasPermission =
    user.aspects?.employee &&
    user.permissions.includes(Permission.ViewAllDynascores);
  const [canPromote, setCanPromote] = useState<boolean>(false);
  const [promoting, setPromoting] = useState<boolean>(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const fetchCompositions = (filters: Filters) => {
    setLoading(true);
    const request = axios.get<{ compositions: Composition[] }>(
      '/compositions/all',
      {
        params: {
          ...filters,
          creatorId: hasPermission ? undefined : user.id,
          sortColumn: 'searchPriority',
        },
        transformResponse: [(data) => JSON.parse(data, dateReviver)],
      },
    );
    request
      .then(({ data }) => {
        setCompositions(data.compositions);
        setSearchPriorities(
          data.compositions
            .filter((c) => c.searchPriority)
            .map((c, index) => {
              return {
                id: c.id,
                searchPriority: (page - 1) * (pageSize ?? 0) + index + 1,
              };
            }),
        );
      })
      .finally(() => {
        setLoading(false);
        setEdited(false);
      });
  };

  const fetchCompositionCount = (filters: Filters) => {
    const request = axios.get<{ count: number }>('/compositions/count', {
      params: filters,
    });
    request.then(({ data }) => {
      setTotalCompositionCount(data.count);
    });
  };

  const fetchFacets = () => {
    axios
      .get<{ facets: Facet[] }>(`/facets/all`)
      .then(({ data }) =>
        setGenreFacetOptions(
          data.facets.find((f) => f.name === 'Genre')?.options || [],
        ),
      );
  };

  const fetchUsers = () => {
    axios
      .get<{ options: { users: IUser[] } }>(`/compositions/composer_options`)
      .then(({ data }) => setUsers(data.options.users));
  };

  const findUser = (userId?: number): IUser | undefined => {
    if (users && userId) {
      return users.find((u) => u.id === userId);
    }
  };

  const getGenreName = (composition: Composition) => {
    // There should only be 1 genre, but join as a fallback in case that changes
    return genreFacetOptions
      ?.filter((o) => composition.facetOptionIds?.includes(o.id))
      .map((o) => o.name)
      .join(', ');
  };

  const fetchCanPromoteDemote = () => {
    const request = axios.get<{ canPromote: boolean; canDemote: boolean }>(
      '/compositions/canPromoteDemote',
    );
    request.then(({ data }) => {
      setCanPromote(data.canPromote);
    });
  };

  useEffect(() => {
    fetchUsers();
    fetchFacets();
    fetchCanPromoteDemote();
  }, []);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setPage(page);
    setPageSize(pageSize);
    fetchCompositions({ page, pageSize });
    fetchCompositionCount({ page, pageSize });
  };

  const bulkUpdateSearchPriorities = () => {
    axios
      .patch(
        `/compositions/bulkUpdateSearchPriorities`,
        { compositionSearchPriorities: searchPriorites },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        fetchCompositions({ page, pageSize });
        fetchCompositionCount({ page, pageSize });
      })
      .catch((error) => {
        logger.warn(`Error bulk updating search priorities ${error.message}`);
      });
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index == result.source.index) {
      return;
    }

    // Create new copy of compositions with priority set in order
    const copy = [...compositions];
    const [item] = copy.splice(result.source.index, 1);
    item.searchPriority =
      (page - 1) * (pageSize ?? 0) + result.destination.index + 1;
    copy.splice(result.destination.index, 0, item);

    // store new search priorities used in params of update
    const newSearchPriorities = copy
      .filter((c) => c.searchPriority)
      .map((c, index) => {
        // Only keep top 200 search priorities
        const searchPriority = (page - 1) * (pageSize ?? 0) + index + 1;
        return {
          id: c.id,
          searchPriority: searchPriority <= 200 ? searchPriority : null,
        };
      });

    // reset compositions in order of draft priorities
    setCompositions(
      copy.map((c) => ({
        ...c,
        searchPriority: newSearchPriorities.find((s) => s.id === c.id)
          ?.searchPriority,
      })),
    );
    setSearchPriorities(newSearchPriorities);
    setEdited(true);
  };

  const promoteAllSearchPriorities = () => {
    const confirmMessage = `Are you sure you want to promote all search priorities?`;
    if (window.confirm(confirmMessage)) {
      setPromoting(true);
      onOpen();
      axios
        .patch(`/compositions/sendPromoteAllSearchPriorities`, {
          headers: { 'Content-Type': 'application/json' },
        })
        .then(() => {
          toast({
            position: 'top',
            title: 'Promoted Search Priorities',
            status: 'success',
            duration: 3000,
            isClosable: true,
          });
        })
        .catch((error) => {
          logger.warn(`Error promoting all search priorities ${error.message}`);
          if (error.response?.data?.error) {
            logger.warn(error.response.data.error);
          }
          toast({
            position: 'top',
            title: 'Error Promoting Playlist',
            description: (
              <VStack align="flex-start">
                <Text>{error.response?.data?.error || 'Unkown Error'}</Text>
              </VStack>
            ),
            status: 'error',
            duration: null,
            isClosable: true,
          });
        })
        .finally(() => {
          setPromoting(false);
          onClose();
        });
    }
  };

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        w="100%"
        mb={4}
      >
        <Heading fontSize="xl">Search Priorities</Heading>

        <Permissions user={user} show={[Permission.ViewAllDynascores]}>
          <HStack>
            {canPromote && (
              <Button
                onClick={promoteAllSearchPriorities}
                isDisabled={promoting}
              >
                Promote Priorities
              </Button>
            )}
            <Button
              colorScheme="red"
              onClick={() => fetchCompositions({ page, pageSize })}
              isDisabled={!edited}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              onClick={bulkUpdateSearchPriorities}
              isDisabled={!edited}
            >
              Save
            </Button>
          </HStack>
        </Permissions>
      </Flex>
      <Pagination
        initialPage={1}
        initialPageSize={100}
        totalCount={totalCompositionCount}
        onChange={(page, pageSize) => handlePaginationChange(page, pageSize)}
      >
        <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Priority</Th>
                <Th>Name</Th>
                <Th>Composer</Th>
                <Th>Artist</Th>
                <Th>Genre</Th>
                <Th>Created</Th>
              </Tr>
            </Thead>
            {loading && (
              <Tbody>
                {[
                  ...Array(
                    Math.min(totalCompositionCount || 10, pageSize || 10),
                  ),
                ].map((_, index) => (
                  <Tr key={index}>
                    <Td>
                      <Icon as={BsThreeDotsVertical} mx={2} boxSize={3} />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
            {!loading && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="droppable"
                  isDropDisabled={!hasPermission}
                >
                  {(provided) => (
                    <Tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{}}
                    >
                      {compositions &&
                        compositions.map((composition, index) => (
                          <Draggable
                            key={`draggable_${composition.id}`}
                            draggableId={composition.id.toString()}
                            index={index}
                            isDragDisabled={!hasPermission}
                          >
                            {(provided, snapshot) => (
                              <Tr
                                key={composition.id}
                                align="center"
                                justify="space-between"
                                mb={2}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging
                                    ? 'rgba(0, 0, 0, 0.1)'
                                    : 'inherit',
                                }}
                              >
                                <Td>
                                  <Icon
                                    as={BsThreeDotsVertical}
                                    mx={2}
                                    boxSize={3}
                                  />
                                </Td>
                                <Td>
                                  {composition.searchPriority ?? 'Not Set'}
                                </Td>
                                <Td>
                                  <Link
                                    href={'/compositions/' + composition.id}
                                    target="_blank"
                                  >
                                    {composition.name}
                                  </Link>
                                </Td>
                                <Td>
                                  {composition.creatorId &&
                                    findUser(composition.creatorId)?.name}
                                </Td>
                                <Td>{composition.artist}</Td>
                                <Td>{getGenreName(composition)}</Td>
                                <Td>
                                  {composition.createdDate.toLocaleString()}
                                </Td>
                              </Tr>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Table>
        </Box>
      </Pagination>
      <LoadingModal onClose={onClose} isOpen={isOpen} />
    </>
  );
};
