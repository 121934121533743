import axios from 'axios';
import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Box, FormControl, HStack, Input, Text } from '@chakra-ui/react';

import autosuggestTheme from '../Autosuggest.module.css';

export const CompositionSearch: React.FC<{
  onSelection(id: number, name: string): void;
  placeHolder?: string;
  hasPermission: boolean;
}> = ({ onSelection, placeHolder, hasPermission }) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<
    { name: string; id: number; artist?: string }[]
  >([]);

  const fetchAutocompleteSuggestions = (value: string) => {
    const request = axios.get<{ stuff: any }>(
      `/compositions/all?searchTerm=${value}&pageSize=200&page=1`,
    );
    request.then(({ data }) => {
      const results = data['compositions']
        ?.map((result) => ({
          id: parseInt(result['id']),
          name: result['name'],
          artist: result['artist'],
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      setSuggestions(results ?? []);
    });
  };

  const handleSearchChange = (value: string) => {
    setInputValue(value);
  };

  return (
    <>
      <FormControl>
        <Autosuggest
          suggestions={suggestions}
          getSuggestionValue={(suggestion) => suggestion.name}
          inputProps={{
            placeholder: placeHolder ?? 'Search Dynascores',
            value: inputValue,
            onChange: (_, { newValue }) => handleSearchChange(newValue),
          }}
          onSuggestionsFetchRequested={({ value }) => {
            fetchAutocompleteSuggestions(value);
          }}
          onSuggestionsClearRequested={() => setSuggestions([])}
          focusInputOnSuggestionClick={false}
          onSuggestionSelected={(_, { suggestion }) => {
            onSelection(suggestion.id, suggestion.name);
          }}
          shouldRenderSuggestions={() => true}
          renderInputComponent={({ size, ...inputProps }) => (
            <Input
              {...inputProps}
              {...(suggestions.length > 0 ? { borderBottomRadius: 0 } : {})}
              disabled={!hasPermission}
            />
          )}
          renderSuggestionsContainer={({ containerProps, children }) =>
            children && (
              <Box
                {...containerProps}
                borderWidth="1px"
                borderBottomRadius="lg"
                py={2}
              >
                {children}
              </Box>
            )
          }
          renderSuggestion={(suggestion, { isHighlighted }) => (
            <Box
              px={4}
              py={2}
              cursor="pointer"
              bgColor={isHighlighted && 'gray.100'}
            >
              <HStack>
                <Text>{suggestion.name}</Text>
                <Text fontSize="xs">
                  {suggestion.artist ? ` (${suggestion.artist})` : ''}
                </Text>
              </HStack>
            </Box>
          )}
          theme={autosuggestTheme}
        />
      </FormControl>
    </>
  );
};
