import axios from 'axios';
import FormData from 'form-data';
import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { logger } from '../../../../common/infra/logger';

export const NewFile: React.FC<{
  compositionId: string;
  onUpload(error: string | undefined): void;
  label?: string;
  prefix?: string;
  accept?: string;
  multiple?: boolean;
  focus?: boolean;
}> = ({ compositionId, onUpload, label, prefix, accept, multiple, focus }) => {
  const toast = useToast();

  const [uploadedFile, setUploadedFile] = useState<FileList | undefined>(
    undefined,
  );
  const [disableSubmit, setDisabledSubmit] = useState<boolean>(true);
  const inputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, []);

  const submitFile = () => {
    setDisabledSubmit(true);
    const formData = new FormData();
    for (var i = 0; i < uploadedFile.length; i++) {
      if (i == 0 || multiple) {
        formData.append('file', uploadedFile[i]);
      }
    }
    formData.append('prefix', prefix);
    axios
      .post<{ warnings?: string[] }>(
        `/compositions/${compositionId}/files/new`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } },
      )
      .then((response) => {
        logger.debug(response);

        const { warnings } = response.data;
        if (warnings?.length > 0) {
          toast({
            position: 'top',
            title: 'Warning Uploading File',
            description: (
              <VStack align="flex-start">
                {warnings.map((w) => (
                  <Text key={w}>{w}</Text>
                ))}
              </VStack>
            ),
            status: 'warning',
            duration: null,
            isClosable: true,
          });
        }

        onUpload(undefined);
      })
      .catch((error) => {
        logger.warn(error);
        if (error.response.data.error) {
          onUpload(error.response.data.error);
        } else {
          onUpload('Unexpected error uploading file!');
        }
      })
      .finally(() => {
        setUploadedFile(undefined);
        setDisabledSubmit(true);
      });
  };

  const onFileChange = (files) => {
    setUploadedFile(files);
    setDisabledSubmit(false);
  };

  return (
    <HStack align="flex-end" paddingBottom="15px">
      <FormControl>
        <FormLabel>{label || 'Config Files'}</FormLabel>
        <Input
          type="file"
          size="sm"
          onChange={(event) => onFileChange(event.target.files)}
          accept={accept}
          multiple={multiple}
          ref={inputRef}
        />
      </FormControl>
      <Button size="sm" onClick={submitFile} isDisabled={disableSubmit}>
        Upload
      </Button>
    </HStack>
  );
};

NewFile.defaultProps = {
  multiple: false,
};
