import axios from 'axios';
import React, { useState } from 'react';
import { Composition } from '../../entities/Composition';
import { BulkQA } from './bulk_qa';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { logger } from '../../../../common/infra/logger';
import { Status } from '../../../../common/entities/Composition';
import { LoadingModal } from '../../components/LoadingModal';

export const TestGeneration: React.FC<{
  composition: Composition;
  updating: boolean;
  canPromote: boolean;
  canDemote: boolean;
  canTransitionToNeedsApproval: boolean;
  onUpdate(error: string | undefined): void;
  setUpdating(value: boolean): void;
}> = ({
  composition,
  updating,
  canPromote,
  canDemote,
  canTransitionToNeedsApproval,
  onUpdate,
  setUpdating,
}) => {
  const [render, setRender] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const generateQaSuite = () => {
    setUpdating(true);
    axios
      .patch(
        `/compositions/${composition.id}/generateQaSuite?render=${render}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        setUpdating(false);
        onUpdate(undefined);
        window.location.href = `/dynascore?includeQAGenerated=true`;
      })
      .catch((error) => {
        logger.warn(`Error generating QA suite ${error.message}`);
        if (error.response.data.error) {
          onUpdate(error.response.data.error);
        }
        setUpdating(false);
      });
  };

  const doPromoteDemote = (
    action: 'promote' | 'demote',
    generateMaster: boolean,
  ) => {
    if (window.confirm(`${action} ${composition.name}?`)) {
      setUpdating(true);
      onOpen();
      axios
        .post(
          `/compositions/${composition.id}/sendPromotionDemotion?generateMaster=${generateMaster}&action=${action}`,
        )
        .then(() => {
          setUpdating(false);
          onUpdate(undefined);
          toast({
            position: 'top',
            title: 'Promoted Dynascore',
            description: (
              <VStack align="flex-start">
                <Text>
                  {generateMaster
                    ? 'Sample or Master Generation may take a bit longer to complete.'
                    : ''}
                </Text>
              </VStack>
            ),
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          logger.warn(`${action} failure for composition ${error.message}`);
          if (error.response.data.error) {
            onUpdate(error.response.data.error);
          }
          setUpdating(false);
        })
        .finally(onClose);
    }
  };

  const dynasamplePromoteDemote = (action: 'promote' | 'demote') => {
    if (window.confirm(`${action} dynasamples for ${composition.name}?`)) {
      setUpdating(true);
      onOpen();
      axios
        .post(
          `/compositions/${composition.id}/dynasamplePromotionDemotion?action=${action}`,
        )
        .then(() => {
          setUpdating(false);
          onUpdate(undefined);
          toast({
            position: 'top',
            title: 'Promoted Dynasamples',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        })
        .catch((error) => {
          logger.warn(`${action} failure for composition ${error.message}`);
          if (error.response.data.error) {
            onUpdate(error.response.data.error);
          }
          setUpdating(false);
        })
        .finally(onClose);
    }
  };

  const doTransitionToNeedsApproval = () => {
    if (window.confirm(`Request approval for ${composition.name}?`)) {
      setUpdating(true);
      axios
        .post(`/compositions/${composition.id}/transitionToNeedsApproval`)
        .then(() => {
          setUpdating(false);
          onUpdate(undefined);
        })
        .catch((error) => {
          logger.warn(
            `Error requesting approval for composition ${error.message}`,
          );
          if (error.response.data.error) {
            onUpdate(error.response.data.error);
          }
          setUpdating(false);
        });
    }
  };

  return (
    <VStack align="center" spacing={4}>
      <HStack alignSelf="flex-end" align="center" spacing={4}>
        <Box borderWidth="1px" borderRadius="lg" p={4}>
          <HStack align="center" spacing={4}>
            <Checkbox
              isChecked={render}
              onChange={(e) => setRender(e.target.checked)}
            >
              Render
            </Checkbox>
            <Button
              size="sm"
              isDisabled={updating}
              onClick={() => generateQaSuite()}
            >
              Test QA Suite
            </Button>
          </HStack>
        </Box>

        {(canPromote || canDemote) && (
          <Menu autoSelect={false} placement="bottom-end">
            <MenuButton
              as={Button}
              size="sm"
              rightIcon={<Icon as={HiOutlineChevronDown} />}
            >
              {canPromote && !canDemote
                ? 'Promote'
                : !canPromote && canDemote
                ? 'Demote'
                : 'Promote/Demote'}
            </MenuButton>
            <MenuList>
              {canPromote && (
                <MenuGroup title="Promote">
                  <MenuItem
                    isDisabled={
                      updating ||
                      ![+Status.Live, +Status.DynasampleReady].includes(
                        parseInt(composition.status),
                      )
                    }
                    onClick={() => doPromoteDemote('promote', true)}
                  >
                    Promote With Master Generation
                  </MenuItem>
                  <MenuItem
                    isDisabled={
                      updating ||
                      ![+Status.Live, +Status.DynasampleReady].includes(
                        parseInt(composition.status),
                      )
                    }
                    onClick={() => doPromoteDemote('promote', false)}
                  >
                    Promote and Skip Master Generation
                  </MenuItem>
                  <MenuItem
                    isDisabled={
                      updating ||
                      ![+Status.Live, +Status.DynasampleReady].includes(
                        parseInt(composition.status),
                      )
                    }
                    onClick={() => dynasamplePromoteDemote('promote')}
                  >
                    Promote Dynasamples
                  </MenuItem>
                </MenuGroup>
              )}
              {canDemote && (
                <MenuGroup title="Demote">
                  <MenuItem
                    isDisabled={updating}
                    onClick={() => doPromoteDemote('demote', true)}
                  >
                    Demote With Master Generation
                  </MenuItem>
                  <MenuItem
                    isDisabled={updating}
                    onClick={() => doPromoteDemote('demote', false)}
                  >
                    Demote and Skip Master Generation
                  </MenuItem>
                  <MenuItem
                    isDisabled={updating}
                    onClick={() => dynasamplePromoteDemote('demote')}
                  >
                    Demote Dynasamples
                  </MenuItem>
                </MenuGroup>
              )}
            </MenuList>
          </Menu>
        )}

        {canTransitionToNeedsApproval && (
          <Button
            size="sm"
            colorScheme="green"
            isDisabled={updating}
            onClick={() => doTransitionToNeedsApproval()}
          >
            Request Final Approval
          </Button>
        )}
      </HStack>

      <BulkQA composition={composition} />
      <LoadingModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};
