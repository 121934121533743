import React, { createContext, useContext, useState, useEffect } from 'react';
import { IUser } from '../entities/User';

interface UserContextValue {
  user: IUser;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const useUserContext = () => {
  const value = useContext(UserContext);
  if (!value) {
    throw new Error('UserContext used outside of provider');
  }
  return value;
};

export const UserContextProvider: React.FC<{ user: IUser }> = ({
  user,
  children,
}) => {
  const [value, setValue] = useState<UserContextValue>({ user: user });

  useEffect(() => {
    setValue({ user: user });
  }, [user]);
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
