import React from 'react';
import {
  Image,
  Link,
  LinkProps,
  Spacer,
  Stack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { IUser } from '../entities/User';
import { Permission } from '../../../common/entities/Permission';
import { Permissions } from './Permissions';
import EnvironmentMenu from './EnvironmentMenu';
import { useUserContext } from '../contexts/user';

const logo = require('../../static/Dynascore_Logo_White_Wordmark.svg').default;

const NavItem = ({ href, children, ...rest }: LinkProps) => {
  return (
    <Link
      color="white"
      fontWeight={window.location.pathname.startsWith(href) ? 'bold' : 'normal'}
      href={href}
      {...rest}
    >
      {children}
    </Link>
  );
};

const NavBar = () => {
  const { user } = useUserContext();
  return (
    <Stack
      as="nav"
      p={4}
      bg="gray.800"
      align={['flex-start', 'center']}
      direction={['column', 'row']}
      justify="space-between"
      spacing={[2, 8]}
    >
      <Stack
        align={['flex-start', 'center']}
        direction={['column', 'row']}
        spacing={[2, 8]}
      >
        <Image src={logo} height="2rem" alt="Dynascore" />

        <Wrap spacing={8} flexShrink={0}>
          <WrapItem>
            <NavItem href="/facets">Facets</NavItem>
          </WrapItem>
          <WrapItem>
            <NavItem href="/compositions">Dynascores</NavItem>
          </WrapItem>
          <WrapItem>
            <NavItem href="/dynascore">Tracks</NavItem>
          </WrapItem>
          <WrapItem>
            <NavItem href="/reports">Reports</NavItem>
          </WrapItem>
        </Wrap>
      </Stack>

      <Stack
        align={['flex-start', 'center']}
        direction={['column', 'row']}
        spacing={[2, 8]}
      >
        <Permissions user={user} show={[Permission.ManageRenderQueues]}>
          <NavItem href="/settings">Settings</NavItem>
        </Permissions>
        <Permissions user={user} show={[Permission.EnvironmentSelector]}>
          <EnvironmentMenu currentEnvironment={user.environment} />
        </Permissions>
      </Stack>
    </Stack>
  );
};

export default NavBar;
