import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dynascore } from '../../entities/Dynascore';
import { DynascoreSearchFilters, Filters } from './search_filters';
import QueryString from 'query-string';
import {
  Badge,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { logger } from '../../../../common/infra/logger';

export const DynascoreHistory: React.FC = () => {
  const queryParams = QueryString.parse(location.search);
  const [dynascores, setDynascores] = useState<Dynascore[] | undefined>(
    undefined,
  );
  const [archiving, setArchiving] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    onlyDynascoresWithNotes: queryParams['onlyDynascoresWithNotes'] === 'true',
    date: queryParams['date'] && queryParams['date'].toString(),
    compositionId:
      queryParams['compositionId'] &&
      parseInt(queryParams['compositionId'].toString()),
    userId: queryParams['userId'] && parseInt(queryParams['userId'].toString()),
    status: queryParams['status']?.toString(),
    includeQAGenerated: queryParams['includeQAGenerated'] !== 'false',
    includeArchived: queryParams['includeArchived'] !== 'false',
    selectedAccount:
      queryParams['selectedAccount'] && queryParams['selectedAccount'] != 'all'
        ? parseInt(queryParams['selectedAccount'].toString())
        : 'all',
  });

  useEffect(() => {
    filter(filters);
  }, []);

  const filter = (filters: Filters) => {
    setFilters(filters);
    const request = axios.get<{ dynascores: Dynascore[] }>('/dynascores/all', {
      params: filters,
    });
    request.then(({ data }) => {
      setDynascores(data.dynascores);
    });
  };

  const archive = async (dynascore: Dynascore) => {
    setArchiving(true);
    if (
      window.confirm(
        `Are you sure you want to archive track ${
          dynascore.name ? dynascore.name : dynascore.id
        }?`,
      )
    ) {
      axios
        .delete(`/dynascores/${dynascore.uuid}/delete`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          filter(filters);
          setArchiving(false);
        })
        .catch((error) => {
          logger.warn(`Error deleting dynascore ${error.message}`);
          setArchiving(false);
        });
    } else {
      setArchiving(false);
    }
  };

  return (
    <>
      <Flex align="center" justify="space-between" w="100%" mb={4}>
        <Heading fontSize="xl">Tracks</Heading>

        <Button as="a" colorScheme="blue" size="sm" href="/dynascore/new">
          New
        </Button>
      </Flex>

      <DynascoreSearchFilters filter={filter} initialFilters={filters} />

      <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Dynascore</Th>
              <Th>Status</Th>
              <Th>Duration &amp; Markers</Th>
              <Th>Creator</Th>
              <Th>Notes</Th>
              <Th>Created</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {dynascores &&
              dynascores.map((dynascore) => (
                <Tr key={dynascore.id}>
                  <Td>
                    <Link
                      href={`/dynascore/${dynascore.datePrefix}/${dynascore.uuid}`}
                    >
                      {dynascore.name ? dynascore.name : `<<${dynascore.id}>>`}
                    </Link>
                  </Td>
                  <Td>
                    <Link href={`/compositions/${dynascore.compositionId}`}>
                      {dynascore.compositionName}
                    </Link>
                  </Td>
                  <Td>
                    <HStack align="center">
                      <Badge
                        colorScheme={
                          dynascore.status === 'error'
                            ? 'red'
                            : dynascore.status === 'complete'
                            ? 'green'
                            : 'gray'
                        }
                      >
                        {dynascore.status}
                      </Badge>
                      {'composing' === dynascore.status && (
                        <Badge>{dynascore.queueMessage}</Badge>
                      )}
                      {!['composing', 'error', 'complete'].includes(
                        dynascore.status,
                      ) && (
                        <Badge>{dynascore.percentComplete.toFixed(2)}%</Badge>
                      )}
                    </HStack>{' '}
                  </Td>
                  <Td>
                    <div>Duration: {dynascore.duration}</div>
                    {dynascore.frameRate && (
                      <div>Frame Rate: {dynascore.frameRate}</div>
                    )}
                    {dynascore.markers && (
                      <div>
                        {/* Add space after comma so long marker list can wrap */}
                        Markers: {dynascore.markers.replaceAll(',', ', ')}
                      </div>
                    )}
                    {dynascore.pauses && <div>Pauses: {dynascore.pauses}</div>}
                    {dynascore.falseEndingMarker && (
                      <div>Ending Start: {dynascore.falseEndingMarker}</div>
                    )}
                    {dynascore.endingMarker && (
                      <div>Bump: {dynascore.endingMarker}</div>
                    )}
                  </Td>
                  <Td>{dynascore.userName}</Td>
                  <Td>{dynascore.notes}</Td>
                  <Td>{new Date(dynascore.createdDate).toLocaleString()}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <Button
                        colorScheme="red"
                        size="sm"
                        onClick={() => archive(dynascore)}
                        isDisabled={archiving}
                      >
                        Archive
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
