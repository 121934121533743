import React, { useState } from 'react';
import { Button, HStack, Input, Select, Td, Tr } from '@chakra-ui/react';
import {
  AccountPriority,
  QueuePriority,
} from '../../../../../common/entities/AccountQueuePriority';

export const PriorityRow: React.FC<{
  priority: AccountPriority;
  onSave(priority: AccountPriority): void;
  onDelete(accountId: number): void;
}> = ({ priority, onSave, onDelete }) => {
  const [editing, setEditing] = useState<boolean>(
    !priority.accountId || !priority.priority,
  );
  const [newAccountId, setNewAccountId] = useState<string>(
    priority.accountId.toString(),
  );
  const [newPriority, setNewPriority] = useState<QueuePriority>(
    priority.priority,
  );

  const handleSave = () => {
    setEditing(false);
    onSave({ accountId: parseInt(newAccountId), priority: newPriority });
  };

  const handleDelete = () => {
    setEditing(false);
    onDelete(priority.accountId);
  };

  if (
    !editing &&
    (!newAccountId || newAccountId === '0') &&
    priority.accountId === 0
  )
    return <></>;

  return (
    <Tr>
      <Td>
        {!editing && priority.accountId}
        {editing && (
          <Input
            value={newAccountId}
            type="number"
            onChange={(e) => setNewAccountId(e.target.value || '')}
            disabled={priority.accountId !== 0} // Cannot edit account id on an existing account priority
          />
        )}
      </Td>
      <Td>{priority.accountName ?? '<<Unknown>>'}</Td>
      <Td>
        {!editing && newPriority}
        {editing && (
          <Select
            value={newPriority}
            onChange={(e) => setNewPriority(e.target.value as QueuePriority)}
          >
            {[QueuePriority.High, QueuePriority.Medium, QueuePriority.Low]?.map(
              (p) => (
                <option key={p} value={p}>
                  {p}
                </option>
              ),
            )}
          </Select>
        )}
      </Td>
      <Td>
        <HStack justify="flex-end" w="100%">
          {
            <Button
              as="a"
              size="sm"
              colorScheme={editing ? 'red' : 'gray'}
              onClick={() => {
                if (editing) {
                  // Cancelling editing
                  setNewAccountId(priority.accountId.toString());
                  setNewPriority(priority.priority);
                }
                setEditing(!editing);
              }}
            >
              {editing ? 'Cancel' : 'Edit'}
            </Button>
          }
          {editing && !!newAccountId && newAccountId !== '0' && (
            <Button as="a" size="sm" colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          )}
          {!editing && priority.accountId !== 0 && (
            <Button as="a" size="sm" colorScheme="red" onClick={handleDelete}>
              Delete
            </Button>
          )}
        </HStack>
      </Td>
    </Tr>
  );
};
