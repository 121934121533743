import axios from 'axios';
import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { logger } from '../../../../../common/infra/logger';

export const NewMasteringPreset: React.FC<{ onSave(): void }> = ({
  onSave,
}) => {
  const [newPresetName, setNewPresetName] = useState<string>('');
  const [uploadedFile, setUploadedFile] = useState<FileList | undefined>(
    undefined,
  );
  const [submitting, setSubmitting] = useState<boolean>(false);

  const createPreset = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const formData = new FormData();
    formData.append('file', uploadedFile[0]);
    formData.append('name', newPresetName);
    axios
      .post(`/mastering_presets/new`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then((response) => {
        logger.debug(response);
        onSave();
      })
      .catch((error) => {
        logger.warn(error);
      })
      .finally(() => {
        setUploadedFile(undefined);
        setNewPresetName('');
        setSubmitting(false);
      });
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme="blue" size="sm">
          New
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>New Mastering Preset</PopoverHeader>
        <PopoverBody>
          <form onSubmit={createPreset}>
            <Stack
              direction={['column', 'row']}
              align="flex-start"
              spacing={4}
              mb={4}
            >
              <VStack align="flex-start">
                <FormControl id="newPreset">
                  <FormLabel>Name</FormLabel>
                  <Input
                    name="newPresetName"
                    placeholder="Mastering Preset Name"
                    value={newPresetName}
                    onChange={(e) => setNewPresetName(e.target.value)}
                    isRequired
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>File</FormLabel>
                  <Input
                    type="file"
                    size="sm"
                    onChange={(event) => setUploadedFile(event.target.files)}
                    isRequired
                  />
                </FormControl>

                <Button
                  colorScheme="blue"
                  type="submit"
                  isDisabled={submitting || !uploadedFile || !newPresetName}
                >
                  Create
                </Button>
              </VStack>
            </Stack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
