import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Composition } from '../../entities/Composition';
import { randomDynascore } from '../../../../common/qaHelpers/randomDynascore';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Checkbox,
  CloseButton,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  HStack,
  Input,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { IRangeMarker } from '../../../../common/markers/markerType';
import QueryString from 'query-string';
import { logger } from '../../../../common/infra/logger';

export const Compose: React.FC = () => {
  const queryParams = QueryString.parse(location.search);
  const [compositions, setCompositions] = useState<Composition[] | undefined>(
    undefined,
  );
  const [newDynaName, setNewDynaName] = useState<string>(
    queryParams['name'] ? queryParams['name'].toString() : '',
  );
  const [newDynaCompositionId, setNewDynaCompositionId] = useState<number>(
    queryParams['compositionId']
      ? parseInt(queryParams['compositionId'].toString(), 10)
      : 0,
  );
  const [newDynaComposerTypeName, setNewDynaComposerTypeName] =
    useState<string>(
      queryParams['composerType']
        ? queryParams['composerType'].toString()
        : 'astar',
    );
  const [newDynaDuration, setNewDynaDuration] = useState<number | undefined>(
    queryParams['duration']
      ? parseInt(queryParams['duration'].toString(), 10)
      : undefined,
  );
  const [newDynaMarkers, setNewDynaMarkers] = useState<string>(
    queryParams['markers'] ? queryParams['markers'].toString() : '',
  );
  const [newEnding, setNewEnding] = useState<number | undefined>(
    queryParams['endingMarker']
      ? parseInt(queryParams['endingMarker'].toString(), 10)
      : undefined,
  );
  const [newFalseEnding, setNewFalseEnding] = useState<number | undefined>(
    queryParams['falseEndingMarker']
      ? parseInt(queryParams['falseEndingMarker'].toString(), 10)
      : undefined,
  );
  const [newFrameRate, setNewFrameRate] = useState<number>(
    queryParams['frameRate']
      ? parseInt(queryParams['frameRate'].toString(), 10)
      : 1,
  );

  useEffect(() => {
    if (!compositions) {
      return;
    }

    if (newDynaDuration || newDynaMarkers || newEnding || newFalseEnding) {
      return;
    }

    const composition = compositions.find((c) => c.id === newDynaCompositionId);
    if (!composition) {
      return;
    }

    const dynascore = randomDynascore(composition);

    setNewDynaDuration(dynascore.duration);
    setNewDynaMarkers(
      dynascore.markers
        .filter((m) => m.markerType === 'transition')
        .map((m) => m.timeCode)
        .sort((a, b) => a - b)
        .join(','),
    );
    setNewEnding(
      (() => {
        const marker = dynascore.markers.find(
          (m): m is IRangeMarker => m.markerType === 'end',
        );
        return marker ? marker.timeCode + marker.duration : undefined;
      })(),
    );
    setNewFalseEnding(
      dynascore.markers.find((m): m is IRangeMarker => m.markerType === 'end')
        ?.timeCode,
    );
  }, [compositions, newDynaCompositionId]);

  const [newPauses, setNewPauses] = useState<string | undefined>(
    queryParams['pauses'] ? queryParams['pauses'].toString() : undefined,
  );
  const [newNotes, setNewNotes] = useState<string | undefined>(undefined);
  const [onlyGuide, setOnlyGuide] = useState<boolean>(false);
  const [useVSTrex, setUseVSTrex] = useState<boolean>(false);
  const [loopMode, setLoopMode] = useState<boolean>(false);
  const [skipMarkerValidation, setSkipMarkerValidation] = useState(false);
  const [composing, setComposing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const request =
      axios.get<{ compositions: Composition[] }>('/compositions/all');
    request.then(({ data }) => {
      setCompositions(data.compositions);
    });
  }, []);

  let notes = onlyGuide ? `(Not Rendered) ${newNotes ?? ''}` : newNotes;
  if (!useVSTrex) {
    notes = 'VelociRendered';
  }

  const createComposition = (event) => {
    event.preventDefault();
    setComposing(true);
    const data = {
      name: newDynaName,
      compositionId: newDynaCompositionId,
      duration: newDynaDuration,
      composerType: newDynaComposerTypeName,
      markers: newDynaMarkers
        .split(',')
        .map((m) => m.trim())
        .join(','),
      falseEndingMarker: newFalseEnding,
      endingMarker: newEnding,
      pauses: newPauses,
      notes: onlyGuide ? `(Not Rendered) ${newNotes ?? ''}` : newNotes,
      onlyGuide: onlyGuide,
      skipMarkerValidation,
      useVelociRender: !useVSTrex,
      frameRate: newFrameRate,
      loopMode: loopMode,
    };
    axios
      .post(`/dynascores/create`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setError(undefined);
        const dynascore = response.data.dynascore;
        let url = `/dynascore/${dynascore.datePrefix}/${dynascore.uuid}`;
        logger.debug(url);
        window.location.pathname = url;
      })
      .catch((error) => {
        setComposing(false);
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          logger.warn(error.response.data);
          logger.warn(error.response.status);
          logger.warn(error.response.headers);
          if (error.response.data.error) {
            setError(error.response.data.error);
          }
        } else if (error.request) {
          logger.warn(error.request);
          setError('No response');
        } else {
          logger.warn('Error', error.message);
          setError(error.message);
        }
      });
  };

  return (
    <Container maxW="2xl">
      <Heading fontSize="xl" w="100%" mb={4}>
        <Breadcrumb spacing={2} separator={<Icon as={HiOutlineChevronRight} />}>
          <BreadcrumbItem>
            <BreadcrumbLink href="/dynascore">Tracks</BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <Text>New Track</Text>
          </BreadcrumbItem>
        </Breadcrumb>
      </Heading>

      {error && (
        <Alert status="error" mb={4}>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>Error</AlertTitle>
            <AlertDescription display="block">{error}</AlertDescription>
          </Box>
          <CloseButton
            position="absolute"
            right={2}
            top={2}
            onClick={() => setError(undefined)}
          />
        </Alert>
      )}

      {composing && (
        <Alert status="info" mb={4}>
          <AlertIcon />
          Composing... Please wait.
        </Alert>
      )}

      <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
        <form onSubmit={createComposition} method="POST">
          <VStack align="flex-start">
            <FormControl>
              <FormLabel>Dynascore</FormLabel>
              <Select
                placeholder="Select Dynascore"
                isRequired
                value={newDynaCompositionId}
                onChange={(e) =>
                  setNewDynaCompositionId(parseInt(e.target.value))
                }
              >
                {compositions?.map((composition) => (
                  <option key={composition.id} value={composition.id}>
                    {composition.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                value={newDynaName}
                onChange={(e) => setNewDynaName(e.target.value)}
              />
            </FormControl>

            <HStack spacing={4} width="100%">
              <FormControl flex={1}>
                <FormLabel>Duration</FormLabel>
                <Input
                  type="number"
                  placeholder="120"
                  isRequired
                  value={newDynaDuration}
                  onChange={(e) => setNewDynaDuration(parseInt(e.target.value))}
                />
              </FormControl>

              <FormControl flex={1.5}>
                <FormLabel>Frame Rate</FormLabel>
                <Input
                  type="number"
                  placeholder="1"
                  isRequired
                  value={newFrameRate}
                  onChange={(e) => setNewFrameRate(parseInt(e.target.value))}
                />
              </FormControl>

              <FormControl flex={3}>
                <FormLabel>Markers</FormLabel>
                <Input
                  placeholder="30,50,75"
                  value={newDynaMarkers}
                  onChange={(e) => setNewDynaMarkers(e.target.value)}
                />
              </FormControl>

              <FormControl flex={2}>
                <FormLabel>Pause</FormLabel>
                <Input
                  placeholder="14:15,25:29"
                  value={newPauses}
                  onChange={(e) => setNewPauses(e.target.value)}
                />
              </FormControl>
            </HStack>

            <HStack spacing={4} width="100%">
              <FormControl>
                <FormLabel>Ending Start</FormLabel>
                <Input
                  type="number"
                  placeholder="In Seconds"
                  isRequired
                  value={newFalseEnding}
                  onChange={(e) =>
                    setNewFalseEnding(parseInt(e.target.value, 10))
                  }
                />
              </FormControl>

              <FormControl>
                <FormLabel>Bump</FormLabel>
                <Input
                  type="number"
                  placeholder="In Seconds"
                  isRequired
                  value={newEnding}
                  onChange={(e) => setNewEnding(parseInt(e.target.value, 10))}
                />
              </FormControl>
            </HStack>

            <HStack spacing={4} width="100%">
              <FormControl>
                <FormLabel>Composer Type</FormLabel>
                <Select
                  isRequired
                  value={newDynaComposerTypeName}
                  onChange={(e) => setNewDynaComposerTypeName(e.target.value)}
                >
                  <option value="astar">A-Star</option>
                  <option value="knapsack">Knapsack</option>
                </Select>
              </FormControl>
            </HStack>

            <FormControl>
              <FormLabel>Notes</FormLabel>
              <Textarea
                value={newNotes}
                onChange={(e) => setNewNotes(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={onlyGuide}
                onChange={(e) => setOnlyGuide(e.target.checked)}
              >
                Only Guide (Not Rendered for QA)
              </Checkbox>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={skipMarkerValidation}
                onChange={(e) => setSkipMarkerValidation(e.target.checked)}
              >
                Skip Marker Validation
              </Checkbox>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={useVSTrex}
                onChange={(e) => setUseVSTrex(e.target.checked)}
              >
                Use VSTrex (Dev Only)
              </Checkbox>
            </FormControl>

            <FormControl>
              <Checkbox
                isChecked={loopMode}
                onChange={(e) => setLoopMode(e.target.checked)}
              >
                Loop Mode (Compies Only)
              </Checkbox>
            </FormControl>

            <Button type="submit" colorScheme="blue" isDisabled={composing}>
              Create
            </Button>
          </VStack>
        </form>
      </Box>
    </Container>
  );
};
