import axios from 'axios';
import React, { useState } from 'react';
import { Dynascore } from '../../entities/Dynascore';
import {
  Button,
  Icon,
  Menu,
  MenuDivider,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Permission } from '../../../../common/entities/Permission';
import { Permissions } from '../../components/Permissions';
import { useUserContext } from '../../contexts/user';
import { QueuePriority } from '../../../../common/entities/AccountQueuePriority';
import { logger } from '../../../../common/infra/logger';

export const ManageProgressMenu: React.FC<{ dynascore: Dynascore }> = ({
  dynascore,
}) => {
  const { user } = useUserContext();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleAdjust = (destinationPriority: QueuePriority) => {
    setSubmitting(true);
    axios
      .post(
        `/settings/adjust_message_priority`,
        {
          regexPattern: `"midiUuid":"${dynascore.uuid}"`,
          destinationPriority,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .catch((error) => {
        logger.warn(error);
      })
      .finally(() => setSubmitting(false));
  };

  const handleCancel = async () => {
    setSubmitting(true);
    if (
      window.confirm(
        `Are you sure you want to cancel rendering for track ${
          dynascore.name ? dynascore.name : dynascore.id
        }?`,
      )
    ) {
      axios
        .patch(`/dynascores/${dynascore.uuid}/cancel_render`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .catch((error) => {
          logger.warn(`Error canceling render for track ${error.message}`);
        })
        .finally(() => setSubmitting(false));
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Permissions user={user} show={[Permission.ManageRenderQueues]}>
      <Menu autoSelect={false}>
        <MenuButton
          as={Button}
          size="sm"
          rightIcon={<Icon as={HiOutlineChevronDown} />}
          isDisabled={submitting || !['composing'].includes(dynascore.status)}
        >
          Manage Progress
        </MenuButton>
        <MenuList fontSize="1rem">
          <MenuItem
            onClick={() => handleAdjust(QueuePriority.High)}
            isDisabled={submitting}
          >
            High Priority
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => handleAdjust(QueuePriority.Medium)}
            isDisabled={submitting}
          >
            Medium Priority
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => handleAdjust(QueuePriority.Low)}
            isDisabled={submitting}
          >
            Low Priority
          </MenuItem>
          <MenuDivider />
          <MenuItem
            onClick={() => handleCancel()}
            isDisabled={submitting}
            color="red"
          >
            Cancel
          </MenuItem>
        </MenuList>
      </Menu>
    </Permissions>
  );
};
