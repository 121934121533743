import {
  makeDefaultMarkers,
  makeValidationRules,
  validateDynascoreInput,
  CompositionRules,
} from '../markers/markerRules';
import { makePointMarker } from '../markers/makeMarker';

export const randomDynascore = (compositionRules: CompositionRules) => {
  const duration = (() => {
    const preferredMax = 120;

    const { defaultDuration, maxDuration } = makeValidationRules(
      compositionRules,
    );
    const min = defaultDuration;
    const max = Math.min(Math.max(preferredMax, min), maxDuration);

    return Math.floor(Math.random() * (max - min + 1) + min);
  })();

  const markers = makeDefaultMarkers(compositionRules, duration);
  const numDefaultMarkers = markers.length;

  // Ensure there is no infinite loop (although it shouldn't happen)
  for (let i = 0; i < 20; i++) {
    // Create a random transition marker
    const timeCode = Math.floor(Math.random() * duration);
    const marker = makePointMarker('transition', timeCode);

    if (validateDynascoreInput(duration, [...markers, marker]).length === 0) {
      // Add it if it's valid to do so, and continue generating random numbers
      // until we get an invalid one
      markers.push(marker);
    } else if (markers.length > numDefaultMarkers) {
      // If it would be invalid to add marker, stop if we have added at least 1
      // transition marker, otherwise continue
      break;
    }
  }

  return { duration, markers };
};
