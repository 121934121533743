import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  Icon,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import {
  BsMusicNoteList,
  BsGearFill,
  BsSearch,
  BsListOl,
} from 'react-icons/bs';
import { CompositionDetail } from './detail';
import { CompositionsTable } from './index_table';
import { MasteringPresetsTable } from './mastering_presets/index_table';
import { PriorityTable } from './search_priority/priority_table';
import { Permission } from '../../../../common/entities/Permission';
import { Permissions } from '../../components/Permissions';
import { useUserContext } from '../../contexts/user';
import { PlaylistsTable } from './playlists/playlists_table';

export const Compositions: React.FC<{}> = () => {
  const match = useRouteMatch();
  const { user } = useUserContext();

  const defaultTabIndex = () => {
    switch (match.path) {
      case '/masteringPresets':
        return 1;
      case '/searchPriorities':
        return 2;
      case '/playlists':
        return 3;
      default:
        return 0;
    }
  };

  return (
    <Switch>
      <Route path={`/compositions/:compositionId`}>
        <CompositionDetail />
      </Route>
      <Route
        path={[
          '/compositions',
          '/masteringPresets',
          '/searchPriorities',
          '/playlists',
        ]}
      >
        <Tabs w="100%" isLazy defaultIndex={defaultTabIndex()}>
          <TabList>
            <Tab>
              <Icon as={BsMusicNoteList} mr={2} /> Dynascores
            </Tab>
            <Tab>
              <Icon as={BsGearFill} mr={2} /> Mastering Presets
            </Tab>
            <Tab
              isDisabled={
                !user.permissions.includes(Permission.ViewAllDynascores)
              }
            >
              <Icon as={BsSearch} mr={2} /> Search Priorities
            </Tab>
            <Tab
              isDisabled={
                !user.permissions.includes(Permission.ViewAllDynascores)
              }
            >
              <Icon as={BsListOl} mr={2} /> Playlists
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {/* Dynascores */}
              <CompositionsTable />
            </TabPanel>
            <TabPanel>
              {/* Mastering Presets */}
              <MasteringPresetsTable />
            </TabPanel>
            <TabPanel>
              {/* Priority Table */}
              <PriorityTable />
            </TabPanel>
            <TabPanel>
              {/* Playlists Table */}
              <PlaylistsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Route>
    </Switch>
  );
};
