import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Composition } from '../../entities/Composition';
import { IUser } from '../../entities/User';
import QueryString from 'query-string';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

interface FilterOptions {
  compositions: Array<Composition>;
  users: Array<IUser>;
  accounts?: Array<Account>;
}

type SelectedAccount = 'all' | number;

type Account = {
  id: number;
  name: string;
};

export interface Filters {
  onlyDynascoresWithNotes: boolean;
  date?: string;
  compositionId?: number;
  userId?: number;
  status?: string;
  includeQAGenerated?: boolean;
  includeArchived?: boolean;
  selectedAccount?: SelectedAccount;
}

export const DynascoreSearchFilters: React.FC<{
  filter(filters: Filters): void;
  initialFilters: Filters;
}> = ({ filter, initialFilters }) => {
  const [
    onlyDynascoresWithNotes,
    setOnlyDynascoresWithNotes,
  ] = useState<boolean>(initialFilters.onlyDynascoresWithNotes);
  const [status, setStatus] = useState<string>(initialFilters.status ?? '');
  const [date, setDate] = useState<string>(initialFilters.date);
  const [users, setUsers] = useState<IUser[]>(undefined);
  const [accounts, setAccounts] = useState<Account[]>(undefined);
  const [compositions, setCompositions] = useState<Composition[]>(undefined);
  const [selectedCompositionId, setSelectedCompositionId] = useState<number>(
    initialFilters.compositionId ?? 0,
  );
  const [selectedUserId, setSelectedUserId] = useState<number>(
    initialFilters.userId ?? 0,
  );
  const [includeQAGenerated, setIncludeQAGenerated] = useState<boolean>(
    initialFilters.includeQAGenerated,
  );
  const [includeArchived, setIncludeArchived] = useState<boolean>(
    initialFilters.includeArchived,
  );
  const [selectedAccount, setSelectedAccount] = useState<SelectedAccount>(
    initialFilters.selectedAccount ?? 'all',
  );

  const fetchOptions = () => {
    const request = axios.get<{ options: FilterOptions }>(
      `/dynascores/filter_options`,
    );
    request.then(({ data }) => {
      setCompositions(
        data.options.compositions.sort((a, b) => a.name.localeCompare(b.name)),
      );
      setUsers(data.options.users.sort((a, b) => a.name.localeCompare(b.name)));
      setAccounts(
        data.options.accounts.sort((a, b) => a.name.localeCompare(b.name)),
      );
    });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    const stringified = QueryString.stringify({
      onlyDynascoresWithNotes: onlyDynascoresWithNotes,
      date: date,
      userId: selectedUserId,
      compositionId: selectedCompositionId,
      status: status,
      includeQAGenerated: includeQAGenerated,
      includeArchived: includeArchived,
      selectedAccount: selectedAccount,
    });

    history.replaceState(undefined, 'Dynascores', `/dynascore?${stringified}`);
  }, [
    onlyDynascoresWithNotes,
    date,
    selectedUserId,
    selectedCompositionId,
    status,
    includeQAGenerated,
    includeArchived,
    selectedAccount,
  ]);

  const onFilter = () => {
    filter({
      onlyDynascoresWithNotes: onlyDynascoresWithNotes,
      date: date,
      userId: selectedUserId,
      compositionId: selectedCompositionId,
      status: status,
      includeQAGenerated: includeQAGenerated,
      includeArchived: includeArchived,
      selectedAccount: selectedAccount,
    });
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" w="100%" mb={4} p={3}>
      <VStack align="flex-start">
        <Wrap>
          <WrapItem>
            <FormControl>
              <FormLabel>Dynascore</FormLabel>
              <Select
                placeholder="Select Dynascore"
                value={selectedCompositionId}
                onChange={(e) =>
                  setSelectedCompositionId(parseInt(e.target.value))
                }
              >
                {compositions?.map((composition) => (
                  <option key={`comp_${composition.id}`} value={composition.id}>
                    {composition.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </WrapItem>

          <WrapItem>
            <FormControl>
              <FormLabel>Status</FormLabel>
              <Select
                placeholder="Select Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option key="new" value="new">
                  New
                </option>
                <option key="composing" value="composing">
                  Composing
                </option>
                <option key="complete" value="complete">
                  Complete
                </option>
                <option key="error" value="error">
                  Error
                </option>
              </Select>
            </FormControl>
          </WrapItem>

          <WrapItem>
            <FormControl>
              <FormLabel>Account</FormLabel>
              <Select
                placeholder="Select Account"
                value={selectedAccount}
                onChange={(e) =>
                  setSelectedAccount(
                    e.target.value === 'all' ? 'all' : +e.target.value,
                  )
                }
              >
                <option key="all" value="all">
                  All
                </option>
                {accounts?.map((account) => (
                  <option key={`account_${account.id}`} value={account.id}>
                    {account.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </WrapItem>

          <WrapItem>
            <FormControl>
              <FormLabel>Creator</FormLabel>
              <Select
                placeholder="Select Creator"
                value={selectedUserId}
                onChange={(e) => setSelectedUserId(parseInt(e.target.value))}
              >
                {users?.map((user) => (
                  <option key={`user_${user.id}`} value={user.id}>
                    {user.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </WrapItem>

          <WrapItem>
            <FormControl>
              <FormLabel>Creation Date</FormLabel>
              <Input
                type="date"
                defaultValue={date}
                onChange={(e) => setDate(e.target.value)}
              />
            </FormControl>
          </WrapItem>
        </Wrap>

        <Wrap align="center">
          <WrapItem mr={4}>
            <Checkbox
              isChecked={onlyDynascoresWithNotes}
              onChange={(e) => setOnlyDynascoresWithNotes(e.target.checked)}
            >
              Only With Notes
            </Checkbox>
          </WrapItem>

          <WrapItem mr={4}>
            <Checkbox
              isChecked={includeQAGenerated}
              onChange={(e) => setIncludeQAGenerated(e.target.checked)}
            >
              Include QA Generated
            </Checkbox>
          </WrapItem>

          <WrapItem mr={4}>
            <Checkbox
              isChecked={includeArchived}
              onChange={(e) => setIncludeArchived(e.target.checked)}
            >
              Include Archived
            </Checkbox>
          </WrapItem>

          <WrapItem>
            <Button onClick={onFilter}>Filter</Button>
          </WrapItem>
        </Wrap>
      </VStack>
    </Box>
  );
};
