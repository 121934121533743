import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Composition } from '../../entities/Composition';
import { CompositionEditFacetsForm } from './edit_facets';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  StackDivider,
  VStack,
} from '@chakra-ui/react';
import { Permission } from '../../../../common/entities/Permission';
import { Status, StatusOrder } from '../../../../common/entities/Composition';
import { IUser } from '../../entities/User';
import { useUserContext } from '../../contexts/user';

interface FilterOptions {
  users: Array<IUser>;
}

type Props = {
  composition: Composition;
  onChange: (composition: Composition) => void;
  onSubmit: () => void;
  isUpdating: boolean;
};

export const CompositionEditForm = ({
  composition,
  onChange,
  onSubmit,
  isUpdating,
}: Props) => {
  const [statuses, setStatuses] = useState<
    { id: number; name: string; isDisabled: boolean }[] | undefined
  >(undefined);
  const [canAdjustStatus, setCanAdjustStatus] = useState<boolean>(false);
  const [canSetComposer, setCanSetComposer] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const { user } = useUserContext();

  const getStatuses = () => {
    let map: { id: number; name: string; isDisabled: boolean }[] = [];

    for (var n in Status) {
      if ((+n).toString() === n) {
        if (
          ![+Status.DynasampleReady, +Status.QAFailed, +Status.Live].includes(
            parseInt(composition.status),
          ) &&
          (+n === +Status.QAFailed || +n === +Status.Live)
        ) {
          map.push({ id: +n, name: Status[n], isDisabled: true });
        } else {
          if (
            +n === +Status.DynasampleReady ||
            +n === +Status.DynasampleFailed
          ) {
            map.push({ id: +n, name: Status[n], isDisabled: true });
          } else {
            map.push({ id: +n, name: Status[n], isDisabled: false });
          }
        }
      }
    }
    setStatuses(map);

    setCanAdjustStatus(user.permissions.includes(Permission.PromoteDemote));
  };

  const fetchUsers = () => {
    const request = axios.get<{ options: FilterOptions }>(
      `/compositions/composer_options`,
    );
    request.then(({ data }) => {
      setUsers(data.options.users.sort((a, b) => a.name.localeCompare(b.name)));
    });

    setCanSetComposer(user.permissions.includes(Permission.PromoteDemote));
  };

  useEffect(() => {
    getStatuses();
    fetchUsers();
  }, []);

  return (
    <Box borderWidth="1px" borderRadius="lg" w="100%">
      <form
        method="PATCH"
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit();
        }}
      >
        <VStack align="flex-start" divider={<StackDivider />} py={2}>
          <VStack align="flex-start" alignSelf="stretch" mx={4} my={2}>
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                name="name"
                placeholder="Name"
                isRequired
                value={composition.name || ''}
                onChange={(e) =>
                  onChange({ ...composition, name: e.target.value })
                }
              />
            </FormControl>

            <FormControl id="artist">
              <FormLabel>Artist</FormLabel>
              <Input
                name="artist"
                placeholder="Artist"
                value={composition.artist || ''}
                onChange={(e) =>
                  onChange({ ...composition, artist: e.target.value })
                }
              />
            </FormControl>

            <FormControl id="creatorId">
              <FormLabel>Composer</FormLabel>
              {users && (
                <Select
                  name="creatorId"
                  placeholder="Select Composer"
                  isDisabled={!canSetComposer}
                  value={composition.creatorId || ''}
                  onChange={(e) =>
                    onChange({
                      ...composition,
                      creatorId: parseInt(e.target.value, 10),
                    })
                  }
                >
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              )}
            </FormControl>
          </VStack>

          <CompositionEditFacetsForm
            bpm={composition.bpm}
            facetOptionIds={composition.facetOptionIds || []}
            onChange={(facetOptionIds) =>
              onChange({ ...composition, facetOptionIds })
            }
          />

          <VStack align="flex-start" alignSelf="stretch" mx={4} my={2}>
            <FormControl id="status">
              <FormLabel>Status</FormLabel>
              {statuses && (
                <Select
                  name="status"
                  isRequired
                  isDisabled={!canAdjustStatus}
                  value={composition.status || ''}
                  onChange={(e) => {
                    if (
                      parseInt(e.target.value) === +Status.VSTrexApproved &&
                      [
                        +Status.DynasampleReady,
                        +Status.DynasampleFailed,
                        +Status.QAFailed,
                        +Status.Live,
                      ].includes(parseInt(composition.status))
                    ) {
                      if (
                        window.confirm(
                          `Are you sure you want to delete all Velocirender samples and regenerate?\n\nSave changes afterwards to delete dynasamples.`,
                        )
                      ) {
                        onChange({ ...composition, status: e.target.value });
                      }
                    } else {
                      onChange({ ...composition, status: e.target.value });
                    }
                  }}
                >
                  {statuses
                    ?.sort((a, b) => StatusOrder[a.name] - StatusOrder[b.name])
                    .map((status) => (
                      <option
                        key={status.id}
                        value={status.id}
                        disabled={status.isDisabled}
                      >
                        {status.name}
                      </option>
                    ))}
                </Select>
              )}
            </FormControl>
          </VStack>

          <VStack align="flex-start" alignSelf="stretch" mx={4} my={2}>
            <Button
              type="submit"
              colorScheme="blue"
              size="sm"
              isDisabled={isUpdating}
              isLoading={isUpdating}
            >
              Save
            </Button>
          </VStack>
        </VStack>
      </form>
    </Box>
  );
};
