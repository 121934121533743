import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  Icon,
  Tab,
  TabList,
  Tabs,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { BsPersonCircle, BsArrowDownUp } from 'react-icons/bs';
import { useUserContext } from '../../contexts/user';
import { Permission } from '../../../../common/entities/Permission';
import { Permissions } from '../../components/Permissions';
import { AccountPriorities } from './account_priorities/account_priorities';
import { MessagePriorityAdjuster } from './message_priority_adjuster';

export const Settings: React.FC<{}> = () => {
  const match = useRouteMatch();
  const { user } = useUserContext();

  return (
    <Permissions user={user} show={[Permission.ManageRenderQueues]}>
      <Switch>
        <Route path={match.path}>
          <Tabs w="100%">
            <TabList>
              <Tab>
                <Icon as={BsPersonCircle} mr={2} /> Account Priorities
              </Tab>
              <Tab>
                <Icon as={BsArrowDownUp} mr={2} /> Priority Adjuster
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                {/* Account Priorities */}
                <AccountPriorities user={user} />
              </TabPanel>
              <TabPanel>
                {/* Priority Adjuster */}
                <MessagePriorityAdjuster />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Route>
      </Switch>
    </Permissions>
  );
};
