import React from 'react';
import { Button, HStack, Input, Text, VStack } from '@chakra-ui/react';

export const SplitTracks: React.FC<{
  values: string[];
  onChange: (values: string[]) => void;
}> = ({ values, onChange }) => (
  <VStack align="flex-start" spacing={3}>
    {values.map((value, index) => (
      <HStack key={index} align="center" w="100%">
        <Text flexShrink={0}>Track #{index + 1}</Text>
        <Input
          bg="white"
          isRequired
          value={value}
          onChange={(e) =>
            onChange([
              ...values.slice(0, index),
              e.target.value,
              ...values.slice(index + 1),
            ])
          }
        />
        <Button
          size="sm"
          colorScheme="red"
          tabIndex={-1}
          onClick={() =>
            onChange([...values.slice(0, index), ...values.slice(index + 1)])
          }
        >
          Delete Track
        </Button>
      </HStack>
    ))}

    <Button size="sm" onClick={() => onChange([...values, ''])}>
      Add Track
    </Button>
  </VStack>
);
