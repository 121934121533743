import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useUserContext } from '../../contexts/user';
import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { AccountsReport } from './accounts';
import { DaysReport } from './days';

export const Reports = () => {
  const match = useRouteMatch();
  const { user } = useUserContext();

  return (
    <Switch>
      <Route path={match.path}>
        <Flex align="center" justify="space-between" w="100%" mb={4}>
          <Heading fontSize="xl">Reports</Heading>
        </Flex>

        <Tabs size="sm" w="100%">
          <TabList flexWrap="wrap">
            <Tab>Accounts</Tab>
            <Tab>Accounts (Non-Stripe Customers)</Tab>
            <Tab>Days</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <AccountsReport nonStripeCustomers={false} />
            </TabPanel>
            <TabPanel>
              <AccountsReport nonStripeCustomers={true} />
            </TabPanel>
            <TabPanel>
              <DaysReport />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Route>
    </Switch>
  );
};
