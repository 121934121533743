// NOTE this order seems strange as the db uses int statuses and we 
// want to remain consistent with db
export enum Status {
  New = 1,
  MP3DemoReady = 2,
  NeedsConfig = 3,
  NeedsApproval = 4,
  VSTrexApproved = 6,
  DynasampleReady = 7,
  DynasampleFailed = 8,
  QAFailed = 9,
  Live = 5,
}

// this controls the display order
export enum StatusOrder {
  New = 1,
  MP3DemoReady = 2,
  NeedsConfig = 3,
  NeedsApproval = 4,
  VSTrexApproved = 5,
  DynasampleReady = 6,
  DynasampleFailed = 7,
  QAFailed = 8,
  Live = 9,
}

export type SortOption =
  | 'name'
  | 'artist'
  | 'status'
  | 'createdDate'
  | 'searchPriority';
export type SortDirection = 'asc' | 'desc';
