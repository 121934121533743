import axios from 'axios';
import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { logger } from '../../../../../common/infra/logger';

export const NewPlaylist: React.FC<{ onSave(): void }> = ({ onSave }) => {
  const [newPlaylistName, setNewPlaylistName] = useState<string>('');
  const toast = useToast();

  const createPlaylist = (event) => {
    event.preventDefault();
    const data = { playlist: { name: newPlaylistName } };
    axios
      .post(`/playlists/new`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        onSave();
      })
      .catch((error) => {
        toast({
          position: 'top',
          title: 'Error Creating Playlist',
          description: (
            <VStack align="flex-start">
              <Text>{error.response.data.error || 'Unkown Error'}</Text>
            </VStack>
          ),
          status: 'error',
          duration: null,
          isClosable: true,
        });
      })
      .finally(() => {
        setNewPlaylistName('');
      });
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme="blue" size="sm">
          New
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>New Playlist</PopoverHeader>
        <PopoverBody>
          <form onSubmit={createPlaylist}>
            <VStack align="flex-start">
              <FormControl id="newPlaylist">
                <Input
                  name="newPlaylist"
                  placeholder="Playlist Name"
                  value={newPlaylistName}
                  onChange={(e) => setNewPlaylistName(e.target.value)}
                  isRequired
                />
              </FormControl>

              <Button colorScheme="blue" type="submit">
                Create
              </Button>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
