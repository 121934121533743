import React, { useEffect, ChangeEvent } from 'react';
import { Button, Flex, Heading, HStack, Select } from '@chakra-ui/react';
import {
  Paginator,
  Container,
  Previous,
  Next,
  PageGroup,
  usePaginator,
} from 'chakra-paginator';

export const DEFAULT_INITIAL_PAGE_SIZE = 100;

export const Pagination: React.FC<{
  initialPage: number;
  initialPageSize: number;
  totalCount: number;
  onChange(page: number, pageSize: number | undefined): void;
}> = ({ initialPage, initialPageSize, totalCount, onChange, children }) => {
  const {
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
    isDisabled,
    setIsDisabled,
  } = usePaginator({
    initialState: {
      currentPage: initialPage,
      pageSize: initialPageSize,
      isDisabled: false,
    },
  });
  const baseStyles = {
    w: 7,
    fontSize: 'sm',
    _hover: {
      bg: 'gray',
    },
  };

  const handlePageSizeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const pageSize = parseInt(event.target.value);
    setPageSize(pageSize);
    setCurrentPage(1);
  };

  const handleDisableClick = () => {
    onChange(currentPage, isDisabled ? pageSize : undefined);
    return setIsDisabled(!isDisabled);
  };

  useEffect(() => {
    onChange(currentPage, pageSize);
  }, [pageSize, currentPage]);

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        w="100%"
        mb={1}
      >
        <Heading fontSize="sm">Count: {totalCount}</Heading>
        <HStack>
          <Button onClick={handleDisableClick}>
            {isDisabled ? 'View Per Page' : 'View All'}
          </Button>
          <span>Per Page:</span>
          <Select
            w={24}
            ml={3}
            value={pageSize}
            isDisabled={isDisabled}
            onChange={handlePageSizeChange}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
          </Select>
        </HStack>
      </Flex>

      <>{children}</>

      <Paginator
        activeStyles={{
          ...baseStyles,
          bg: 'blue.600',
        }}
        normalStyles={baseStyles}
        innerLimit={2}
        outerLimit={2}
        pagesQuantity={Math.ceil(totalCount / pageSize)}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        isDisabled={isDisabled}
      >
        <Container align="center" justify="space-between" w="full" p={4}>
          <Previous>
            Previous
            {/* TODO maybe use an icon */}
          </Previous>
          <PageGroup isInline align="center" />
          <Next>
            Next
            {/* TODO maybe use an icon */}
          </Next>
        </Container>
      </Paginator>
    </>
  );
};
