import {
  IMarker,
  IPointMarker,
  IRangeMarker,
  isRangeMarkerType,
  MarkerType,
  PointMarkerType,
  RangeMarkerType,
} from './markerType';
import { minRangeMarkerDuration } from './markerRules';

export const makePointMarker = (
  markerType: PointMarkerType,
  timeCode: number,
): IPointMarker => {
  return { markerType, timeCode };
};

export const makeRangeMarker = (
  markerType: RangeMarkerType,
  timeCode: number,
  duration?: number,
): IRangeMarker => {
  // Ensure that the range meets the minimum duration requirements.
  // Consider that if the duration is negative, the user created the range by
  // dragging to the left direction, and we should accept that and adjust the
  // timeCode position to accommodate it.
  const minDuration = minRangeMarkerDuration(markerType);

  // May be negative
  const _duration =
    duration === undefined
      ? minDuration
      : Math.max(Math.abs(duration), minDuration) * (duration < 0 ? -1 : 1);

  // Adjust timeCode backwards if duration is negative
  const _timeCode = _duration < 0 ? timeCode + _duration : timeCode;

  return {
    markerType,
    timeCode: _timeCode,
    duration: Math.abs(_duration),
  };
};

interface ModifierKeys {
  altKey: boolean;
  ctrlKey: boolean;
  metaKey: boolean;
  shiftKey: boolean;
}

export const markerTypeFromModifierKeys = (
  modifierKeys: ModifierKeys,
): MarkerType => {
  if (modifierKeys.altKey) {
    return 'pause';
  } else {
    return 'transition';
  }
};

export const makeMarker = (
  markerType: MarkerType,
  currentPosition: number,
  mouseDownPosition: number | undefined,
): IMarker => {
  const timeCode = Math.round(currentPosition);

  if (isRangeMarkerType(markerType)) {
    if (mouseDownPosition !== undefined) {
      const start = Math.round(mouseDownPosition);
      const duration = timeCode - start;
      return makeRangeMarker(markerType, start, duration);
    } else {
      return makeRangeMarker(markerType, timeCode);
    }
  } else {
    return makePointMarker(markerType, timeCode);
  }
};

export type RangeMarkerAdjustmentEdge = 'all' | 'left' | 'right';

export const adjustRangeMarker = (
  marker: IRangeMarker,
  adjustmentEdge: RangeMarkerAdjustmentEdge,
  adjustmentOffset: number,
) => {
  let { timeCode, duration } = marker; // duration may become negative after adjustments
  switch (adjustmentEdge) {
    case 'all':
      timeCode += adjustmentOffset;
      break;
    case 'left':
      timeCode += adjustmentOffset;
      duration -= adjustmentOffset;
      break;
    case 'right':
      duration += adjustmentOffset;
      break;
    case undefined:
      break;
    default:
      const _exhaustiveCheck: never = adjustmentEdge;
      throw new Error(`Unexpected adjustmentEdge: ${_exhaustiveCheck}`);
  }

  if (duration < 0) {
    timeCode += duration;
    duration = Math.abs(duration);
  }

  // Don't use makeRangeMaker, which has minimum duration validation, which we
  // don't want here (if the user is actively dragging, allow the duration to
  // drop below the minimum while dragging, and enforce it again when complete)
  return {
    markerType: marker.markerType,
    timeCode,
    duration,
  };
};
