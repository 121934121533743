import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  HStack,
  Stack,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { IUser } from '../../../entities/User';
import { Permission } from '../../../../../common/entities/Permission';
import { Permissions } from '../../../components/Permissions';
import { PriorityRow } from './priority_row';
import {
  AccountPriority,
  QueuePriority,
} from '../../../../../common/entities/AccountQueuePriority';
import { logger } from '../../../../../common/infra/logger';

export const AccountPriorities: React.FC<{ user: IUser }> = ({ user }) => {
  const [priorities, setPriorities] = useState<AccountPriority[]>([]);

  const fetchPriorities = () => {
    const request = axios.get<{ priorities: AccountPriority[] }>(
      '/settings/accounts_queue_priorities/all',
    );
    request.then(({ data }) => {
      setPriorities(data.priorities);
    });
  };

  useEffect(() => {
    fetchPriorities();
  }, []);

  const onSave = (priority: AccountPriority) => {
    axios
      .patch(`/settings/accounts_queue_priorities/upsert`, priority, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        fetchPriorities();
      })
      .catch((error) => {
        logger.warn(error);
      });
  };

  const onDelete = (accountId: number) => {
    if (window.confirm(`Delete priority for account id ${accountId}?`)) {
      axios
        .delete(`/settings/accounts_queue_priorities/${accountId}/delete`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          fetchPriorities();
        })
        .catch((error) => {
          logger.warn(error);
        });
    }
  };

  return (
    <Stack direction={['column', 'row']} align="flex-start" spacing={4}>
      <Box borderWidth="1px" borderRadius="lg" w="100%" p={4}>
        <VStack align="flex-start" spacing={4}>
          <Permissions user={user} show={[Permission.ManageRenderQueues]}>
            <HStack justify="flex-end" w="100%">
              <Button
                size="sm"
                colorScheme="blue"
                onClick={() => {
                  const n = priorities.concat([
                    {
                      accountId: 0,
                      priority: QueuePriority.Medium,
                    },
                  ]);
                  setPriorities(n);
                }}
              >
                New
              </Button>
            </HStack>
            <Table size="md">
              <Thead>
                <Tr>
                  <Th>Account Id</Th>
                  <Th>Account Name</Th>
                  <Th>Priority</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {priorities &&
                  priorities.map((priority, index) => (
                    <PriorityRow
                      key={index}
                      priority={priority}
                      onSave={onSave}
                      onDelete={onDelete}
                    />
                  ))}
              </Tbody>
            </Table>
          </Permissions>
        </VStack>
      </Box>
    </Stack>
  );
};
