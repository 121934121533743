import React from 'react';
import { RuleSet } from './rule_set';
import NestingCard from './nesting_card';
import { Button, Flex, Heading, VStack } from '@chakra-ui/react';

interface Props<T> {
  options: T[];
  values: T[][];
  onChange: (values: T[][]) => void;
}

export const RuleSets = <T extends string>({
  options,
  values,
  onChange,
}: Props<T>) => {
  return (
    <VStack align="flex-start" spacing={3}>
      {values.map((ruleSet, index) => {
        return (
          <NestingCard key={index}>
            <Flex align="center" justify="space-between" w="100%" mb={2}>
              <Heading as="h3" fontSize="lg">
                Rule Set #{index + 1}
              </Heading>

              <Button
                size="sm"
                colorScheme="red"
                tabIndex={-1}
                onClick={() =>
                  onChange([
                    ...values.slice(0, index),
                    ...values.slice(index + 1),
                  ])
                }
              >
                Delete Rule Set
              </Button>
            </Flex>

            <RuleSet
              options={options}
              values={ruleSet}
              onChange={(ruleSet) =>
                onChange([
                  ...values.slice(0, index),
                  ruleSet,
                  ...values.slice(index + 1),
                ])
              }
            />
          </NestingCard>
        );
      })}

      <Button size="sm" onClick={() => onChange([...values, []])}>
        Add Rule Set
      </Button>
    </VStack>
  );
};
