export type PointMarkerType = 'transition';
export type RangeMarkerType = 'pause' | 'end';
export type MarkerType = PointMarkerType | RangeMarkerType;

// Create a dictionary of all marker types for the purposes of generating
// allMarkerTypes. The boolean is irrelevant is and is just there to work
// well with TypeScript to ensure that we the list is exhaustive.
const _markerTypes: { [type in MarkerType]: boolean } = {
  transition: true,
  pause: true,
  end: true,
};

export const allMarkerTypes = Object.keys(_markerTypes) as MarkerType[];

export const isRangeMarkerType = (
  markerType: MarkerType,
): markerType is RangeMarkerType => {
  switch (markerType) {
    case 'transition':
      return false;
    case 'pause':
    case 'end':
      return true;
  }
};

export interface IPointMarker {
  markerType: PointMarkerType;
  timeCode: number;
}

export interface IRangeMarker {
  markerType: RangeMarkerType;
  timeCode: number;
  duration: number;
}

export type IMarker = IPointMarker | IRangeMarker;

export const isRangeMarker = (marker: IMarker): marker is IRangeMarker => {
  return isRangeMarkerType(marker.markerType);
};
