import '../../../common/api/loadWebEnvironment'; // Load first to set environment.
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  ChakraProvider,
  Container,
  Flex,
} from '@chakra-ui/react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { IUser } from './entities/User';
import { Compositions } from './pages/compositions/index';
import { ComposeIndex } from './pages/compose/index';
import { Facets } from './pages/facets/index';
import { Reports } from './pages/reports/index';
import { Settings } from './pages/settings/index';
import NavBar from './components/NavBar';
import { UserContextProvider } from './contexts/user';
import { datadogLogs } from '@datadog/browser-logs';
import { logger } from '../../common/infra/logger';
import { baseAccountsURL } from '../../../common/api/http';

let user: IUser;

datadogLogs.init({
  clientToken: 'pub9370d39cb70ba04f392739cbaf630051',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  env: window.location.host.includes('dynascore.com') ? 'prod' : 'dev',
  service: 'dynascoreadmin',
  sampleRate: 100,
});

fetch('/diag/me')
  .then((res) => {
    if (res.status === 401) {
      logger.debug('Not logged in');
      return null;
    }
    return res.json();
  })
  .then((result) => {
    if (result) {
      user = result as IUser;
    }
  })
  .then(() => {
    if (!user) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const jwt = urlSearchParams.get('jwt');
      if (jwt) {
        window.location.href = baseAccountsURL;
      } else {
        window.location.href = '/login';
      }
    } else if (!user.aspects?.employee) {
      ReactDOM.render(
        <ChakraProvider>
          <Container centerContent mt={12} maxW="lg">
            <Alert
              status="error"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
            >
              <AlertIcon boxSize="3rem" mr={0} />
              <AlertTitle mt={4} mb={2} fontSize="xl">
                Employee Access Only
              </AlertTitle>
              <Button as="a" colorScheme="red" href="/logout">
                Logout
              </Button>
              {window.location.href} from {document.referrer}
            </Alert>
          </Container>
        </ChakraProvider>,
        document.getElementById('root'),
      );
    } else {
      datadogLogs.setLoggerGlobalContext({ userId: user.id });
      datadogLogs.logger.info(`Session starting for user ${user.id}`);

      ReactDOM.render(
        <ChakraProvider>
          <BrowserRouter>
            <UserContextProvider user={user}>
              <NavBar />

              <Flex
                direction="column"
                align="center"
                maxW={{ xl: '1200px' }}
                m="0 auto"
                p={[2, 4]}
              >
                <Switch>
                  <Route path="/dynascore">
                    <ComposeIndex />
                  </Route>
                  <Route
                    path={[
                      '/compositions',
                      '/masteringPresets',
                      '/playlists',
                      '/searchPriorities',
                    ]}
                  >
                    <Compositions />
                  </Route>
                  <Route path="/facets">
                    <Facets />
                  </Route>
                  <Route path="/reports">
                    <Reports />
                  </Route>
                  <Route path="/settings">
                    <Settings />
                  </Route>

                  <Redirect to="/dynascore" />
                </Switch>
              </Flex>
            </UserContextProvider>
          </BrowserRouter>
        </ChakraProvider>,
        document.getElementById('root'),
      );
    }
  });
