export enum QueuePriority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface AccountPriority {
  accountId: number;
  priority: QueuePriority;
  accountName?: string;
}
