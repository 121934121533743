import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ReportAccount } from '../../../../common/entities/Report';
import { dateReviver } from '../../../../../common/api/http';
import { formatDate } from '../../../../../common/client/formatters/formatDate';
import {
  Box,
  Icon,
  Link,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { BsTriangleFill } from 'react-icons/bs';

type SortColumn =
  | 'name'
  | 'createdDate'
  | 'numUsers'
  | 'numApiUsers'
  | 'numHatches';

type CompareFunction = (a: ReportAccount, b: ReportAccount) => number;

type Props = {
  nonStripeCustomers: boolean | undefined;
};

export const AccountsReport: React.FunctionComponent<Props> = ({
  nonStripeCustomers,
}) => {
  const [accounts, setAccounts] = useState<ReportAccount[]>([]);

  const sortComparators: {
    [column in SortColumn]: CompareFunction;
  } = {
    name: (a, b) => a.name.localeCompare(b.name),
    createdDate: (a, b) => a.createdDate.getTime() - b.createdDate.getTime(),
    numUsers: (a, b) => a.numUsers - b.numUsers,
    numApiUsers: (a, b) => a.numApiUsers - b.numApiUsers,
    numHatches: (a, b) => a.numHatches - b.numHatches,
  };

  const columnDefaultSortAscending: { [column in SortColumn]: boolean } = {
    name: true,
    createdDate: false,
    numUsers: false,
    numApiUsers: false,
    numHatches: false,
  };

  const [sortColumn, setSortColumn] = useState<SortColumn>('createdDate');
  const [sortAscending, setSortAscending] = useState<boolean>(false);

  const fetchAccounts = () => {
    return axios
      .get<{ accounts: ReportAccount[] }>(
        `/reports/accounts?nonStripeCustomers=${!!nonStripeCustomers}`,
        {
          transformResponse: [(data) => JSON.parse(data, dateReviver)],
        },
      )
      .then(({ data }) => setAccounts(data.accounts));
  };

  useEffect(() => {
    fetchAccounts();
  }, []);

  return (
    <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
      <Table size="sm">
        <Thead>
          <Tr>
            {(
              [
                { name: 'Name', column: 'name' },
                { name: 'Created', column: 'createdDate' },
                { name: '# Users', column: 'numUsers' },
                { name: '# API Users', column: 'numApiUsers' },
                { name: '# Hatches', column: 'numHatches' },
              ] as {
                name: string;
                column: SortColumn;
              }[]
            ).map(({ name, column }) => (
              <Th
                key={column}
                onClick={() => {
                  if (column == sortColumn) {
                    setSortAscending(!sortAscending);
                  } else {
                    setSortColumn(column);
                    setSortAscending(columnDefaultSortAscending[column]);
                  }
                }}
              >
                {name}
                {column === sortColumn && (
                  <Icon
                    as={BsTriangleFill}
                    mx={2}
                    boxSize={2}
                    transform={`rotate(${sortAscending ? 0 : 180}deg)`}
                  />
                )}
              </Th>
            ))}
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {accounts
            ?.sort(
              (a, b) =>
                sortComparators[sortColumn](a, b) * (sortAscending ? 1 : -1),
            )
            .map((account) => (
              <Tr key={account.id}>
                <Td>
                  <Link href={'/dynascore?selectedAccount=' + account.id}>
                    {account.name}
                  </Link>
                </Td>
                <Td>
                  {account.createdDate ? formatDate(account.createdDate) : '—'}
                </Td>
                <Td>{account.numUsers}</Td>
                <Td>{account.numApiUsers}</Td>
                <Td>{account.numHatches}</Td>
              </Tr>
            ))}
        </Tbody>
        {accounts?.length > 0 && (
          <Tfoot>
            <Tr>
              <Th>Total {accounts.length}</Th>
              <Th></Th>
              <Th>{accounts.reduce((sum, a) => sum + a.numUsers, 0)}</Th>
              <Th>{accounts.reduce((sum, a) => sum + a.numApiUsers, 0)}</Th>
              <Th>{accounts.reduce((sum, a) => sum + a.numHatches, 0)}</Th>
            </Tr>
          </Tfoot>
        )}
      </Table>
    </Box>
  );
};
