export interface DynascoreInput {
  name: string;
  compositionId: number;
  duration: number;
  markers?: string;
  createdDate?: Date;
  lossClass?: string;
  composerType?: string;
  soloInstrument?: string;
  falseEndingMarker?: number;
  endingMarker?: number;
  pauses?: string;
  notes?: string;
  onlyGuide?: boolean;
  skipMarkerValidation?: boolean;
  useVelociRender?: boolean;
  frameRate?: number;
  loopMode?: boolean;
}

export function timingsInFractionalSeconds<T extends DynascoreInput>(
  input: T,
): T {
  var ret = { ...input };
  let frameRate = input.frameRate || 1;
  if (frameRate == 1) {
    return ret;
  }
  ret.duration = input.duration / frameRate;
  if (input.markers) {
    ret.markers = input.markers
      .split(',')
      .map((i) => +i.trim() / frameRate)
      .join(',');
  }
  if (input.falseEndingMarker) {
    ret.falseEndingMarker = input.falseEndingMarker / frameRate;
  }
  if (input.endingMarker) {
    ret.endingMarker = input.endingMarker / frameRate;
  }
  if (input.pauses) {
    ret.pauses = input.pauses
      .split(',')
      .map((i) =>
        i
          .trim()
          .split(':')
          .map((j) => +j.trim() / frameRate)
          .join(':'),
      )
      .join(',');
  }
  return ret;
}

export function timingsInMilliSeconds<T extends DynascoreInput>(input: T): T {
  var ret = { ...input };
  let frameRate = input.frameRate || 1;
  if (frameRate == 1000) {
    return ret;
  }
  ret.frameRate = 1000;
  ret.duration = Math.floor((1000 * input.duration) / frameRate);
  if (input.markers) {
    ret.markers = input.markers
      .split(',')
      .map((i) => Math.floor((1000 * +i.trim()) / frameRate))
      .join(',');
  }
  if (input.falseEndingMarker) {
    ret.falseEndingMarker = Math.floor(
      (1000 * input.falseEndingMarker) / frameRate,
    );
  }
  if (input.endingMarker) {
    ret.endingMarker = Math.floor((1000 * input.endingMarker) / frameRate);
  }
  if (input.pauses) {
    ret.pauses = input.pauses
      .split(',')
      .map((i) =>
        i
          .trim()
          .split(':')
          .map((j) => Math.floor((1000 * +j.trim()) / frameRate))
          .join(':'),
      )
      .join(',');
  }
  return ret;
}
