import React from 'react';
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { ThemeTypings } from '@chakra-ui/styled-system';
import { HiOutlineChevronDown } from 'react-icons/hi';
import {
  Environment,
  allEnvironments,
  environmentNames,
} from '../../../../common/api/environment';
import { dynascoreServiceLocations } from '../../../../common/api/serviceLocations';

export const environmentColors: {
  [env in Environment]: ThemeTypings['colorSchemes'];
} = {
  local: 'cyan',
  exp: 'orange',
  dev: 'green',
  stage: 'yellow',
  prod: 'red',
};

type Props = {
  currentEnvironment: Environment;
};

const EnvironmentMenu = ({ currentEnvironment }: Props) => {
  return (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        size="sm"
        colorScheme={environmentColors[currentEnvironment]}
        rightIcon={<Icon as={HiOutlineChevronDown} />}
      >
        {environmentNames[currentEnvironment] || 'Environment'}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          title="Environment"
          type="radio"
          value={currentEnvironment}
        >
          {allEnvironments.map((env) => (
            <MenuItemOption
              key={env}
              value={env}
              as="a"
              href={dynascoreServiceLocations[env]}
              target="_blank"
              _focus={{ bgColor: `${environmentColors[env]}.100` }}
            >
              {environmentNames[env]}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
        <MenuDivider />
        <MenuItem as="a" href="/logout" color="red">
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default EnvironmentMenu;
