import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Facet, FacetOption } from '../../../../common/entities/Facet';
import Autosuggest from 'react-autosuggest';
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';

import autosuggestTheme from '../../Autosuggest.module.css';

type Props = {
  // BPM isn't technically a facet, but should show up in the facets section
  bpm?: number;

  facetOptionIds: string[];
  onChange: (facetOptionIds: string[]) => void;
};

export const CompositionEditFacetsForm = ({
  bpm,
  facetOptionIds,
  onChange,
}: Props) => {
  const [allFacets, setAllFacets] = useState<Facet[] | undefined>(undefined);
  const [allFacetOptions, setAllFacetOptions] = useState<{
    [facetOptionId: string]: FacetOption;
  }>({});

  useEffect(() => {
    axios.get<{ facets: Facet[] }>(`/facets/all`).then(({ data }) => {
      const { facets } = data;
      setAllFacets(facets);
      setAllFacetOptions(
        facets.reduce((map, facet) => {
          for (const option of facet.options) {
            map[option.id] = option;
          }
          return map;
        }, {} as { [facetOptionId: string]: FacetOption }),
      );
    });
  }, []);

  const [inputValue, setInputValue] = useState('');
  const [facetSuggestions, setFacetSuggestions] = useState<Facet[]>([]);

  const selectedOptionsByFacet: {
    [facetId: string]: FacetOption[];
  } = facetOptionIds.reduce((map, id) => {
    const facetOption = allFacetOptions[id];
    if (facetOption) {
      let arr = map[facetOption.facetTypeId];
      if (!arr) {
        arr = [];
        map[facetOption.facetTypeId] = arr;
      }
      arr.push(facetOption);
    }
    return map;
  }, {} as { [facetId: number]: FacetOption[] });

  const filterFacetSuggestions = (value: string) => {
    const normalize = (s: string) => s.normalize().toLocaleLowerCase();

    const normalizedValue = normalize(value);

    // Check if value matches start of the facet name, or the start of any word
    // within the name (not a generic `includes` search)
    const matchingFacetNames = new Set(
      (allFacets || [])
        .filter((facet) =>
          [facet.name, ...facet.name.split(' ')].some((s) =>
            normalize(s).startsWith(normalizedValue),
          ),
        )
        .map((facet) => facet.name),
    );

    const facetOptionIdsSet = new Set(facetOptionIds);

    setFacetSuggestions(
      (allFacets || [])
        // Only show suggestions for manually populated facets
        .filter((facet) => facet.manuallyPopulated)
        .map(({ options, ...facet }) => ({
          ...facet,
          options: options.filter((option) => {
            // Exclude if option is already selected
            if (facetOptionIdsSet.has(option.id)) {
              return false;
            }

            // Exclude if facet has reached maximum number of options
            const numSelected = (selectedOptionsByFacet[facet.id] || []).length;
            if (
              facet.maxOptionsAllowed != null &&
              numSelected >= facet.maxOptionsAllowed
            ) {
              return false;
            }

            // Include all remaining facet options with an empty input value
            if (!normalizedValue) {
              return true;
            }

            // Include if facet name matches
            if (matchingFacetNames.has(facet.name)) {
              return true;
            }

            // Check if value is contained anywhere within the option name or aliases
            const strings = [option.name, ...option.aliases];
            if (strings.some((s) => normalize(s).includes(normalizedValue))) {
              return true;
            }

            return false;
          }),
        }))
        .filter((facet) => facet.options.length > 0),
    );
  };

  return (
    <VStack align="flex-start" alignSelf="stretch" my={2}>
      <FormControl>
        <FormLabel mx={4}>Facets</FormLabel>

        {!allFacets ? (
          <CircularProgress mx={4} isIndeterminate />
        ) : (
          <>
            <Table size="sm" mb={4}>
              <Tbody>
                {allFacets.map((facet) => {
                  const options = selectedOptionsByFacet[facet.id] || [];
                  const numOptions = options.length;

                  return (
                    <Tr key={facet.name}>
                      <Th w="1%" whiteSpace="nowrap">
                        {facet.name}
                      </Th>
                      <Td>
                        <Wrap spacing={2}>
                          {options.map((option) => (
                            <WrapItem key={option.name}>
                              <Tag borderRadius="full">
                                <TagLabel>{option.name}</TagLabel>
                                {facet.manuallyPopulated && (
                                  <TagCloseButton
                                    onClick={() =>
                                      onChange(
                                        facetOptionIds.filter(
                                          (id) => id !== option.id,
                                        ),
                                      )
                                    }
                                  />
                                )}
                              </Tag>
                            </WrapItem>
                          ))}

                          {numOptions < facet.minOptionsRequired &&
                          facet.manuallyPopulated ? (
                            <WrapItem>
                              <Tag borderRadius="full" colorScheme="red">
                                <TagLabel>
                                  {facet.minOptionsRequired === 1
                                    ? 'Required'
                                    : `Must Select ${facet.minOptionsRequired} Options`}
                                </TagLabel>
                              </Tag>
                            </WrapItem>
                          ) : facet.maxOptionsAllowed != null &&
                            numOptions > facet.maxOptionsAllowed &&
                            facet.manuallyPopulated ? (
                            <WrapItem>
                              <Tag borderRadius="full" colorScheme="red">
                                <TagLabel>
                                  {`Maximum of ${facet.maxOptionsAllowed} Options`}
                                </TagLabel>
                              </Tag>
                            </WrapItem>
                          ) : numOptions === 0 && facet.manuallyPopulated ? (
                            <WrapItem>
                              <Tag borderRadius="full">
                                <TagLabel>None (Optional)</TagLabel>
                              </Tag>
                            </WrapItem>
                          ) : numOptions === 0 && !facet.manuallyPopulated ? (
                            <WrapItem>
                              <Tag borderRadius="full">
                                <TagLabel>Populated from Master MIDI</TagLabel>
                              </Tag>
                            </WrapItem>
                          ) : null}
                        </Wrap>
                      </Td>
                    </Tr>
                  );
                })}
                <Tr>
                  <Th w="1%" whiteSpace="nowrap">
                    BPM
                  </Th>
                  <Td>
                    <Tag borderRadius="full">
                      <TagLabel>{bpm || 'Populated from Master MIDI'}</TagLabel>
                    </Tag>
                  </Td>
                </Tr>
              </Tbody>
            </Table>

            <Box mx={4}>
              <Autosuggest<FacetOption, Facet>
                multiSection
                highlightFirstSuggestion
                suggestions={facetSuggestions}
                getSectionSuggestions={(section) => section.options}
                getSuggestionValue={(suggestion) => suggestion.name}
                inputProps={{
                  placeholder: 'Add Facet Option',
                  value: inputValue,
                  onChange: (_, { newValue }) => setInputValue(newValue),
                }}
                onSuggestionsFetchRequested={({ value }) =>
                  filterFacetSuggestions(value)
                }
                onSuggestionsClearRequested={() => setFacetSuggestions([])}
                focusInputOnSuggestionClick={
                  // NOTE: There is a shouldKeepSuggestionsOnSelect prop that
                  // we should use, but it hasn't been added to the TypeScript
                  // definitions yet, so for now, have the input lose focus
                  // after choosing a suggestion. So when the user clicks on
                  // the input to focus it, it will show all suggestions
                  // (rather than no suggestion list until typing starts).
                  false
                }
                onSuggestionSelected={(_, { suggestion }) => {
                  onChange([...facetOptionIds, suggestion.id]);
                  setInputValue('');
                }}
                shouldRenderSuggestions={() => true}
                renderInputComponent={({ size, ...inputProps }) => (
                  <Input
                    {...inputProps}
                    {...(facetSuggestions.length > 0
                      ? { borderBottomRadius: 0 }
                      : {})}
                  />
                )}
                renderSuggestionsContainer={({ containerProps, children }) =>
                  children && (
                    <Box
                      {...containerProps}
                      borderWidth="1px"
                      borderBottomRadius="lg"
                      py={2}
                    >
                      {children}
                    </Box>
                  )
                }
                renderSectionTitle={(section: Facet) => (
                  <>
                    {facetSuggestions[0]?.name !== section.name && (
                      <Divider my={2} />
                    )}
                    <Heading as="h6" size="xs" fontWeight="600" px={4} py={2}>
                      {section.name}
                    </Heading>
                  </>
                )}
                renderSuggestion={(suggestion, { isHighlighted }) => (
                  <Text
                    px={4}
                    py={2}
                    cursor="pointer"
                    bgColor={isHighlighted && 'gray.100'}
                  >
                    {suggestion.name}
                  </Text>
                )}
                theme={autosuggestTheme}
              />
            </Box>
          </>
        )}
      </FormControl>
    </VStack>
  );
};
