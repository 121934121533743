import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DynascoreResults } from './results';
import { DynascoreHistory } from './history';
import { Compose } from './compose';

export const ComposeIndex: React.FC = () => {
  return (
    <Switch>
      <Route path="/dynascore/new">
        <Compose />
      </Route>
      <Route path="/dynascore/:datePrefix/:dynascoreId">
        <DynascoreResults />
      </Route>
      <Route path="/dynascore">
        <DynascoreHistory />
      </Route>
    </Switch>
  );
};
