import React from 'react';
import { Box, VStack, Stat, StatHelpText } from '@chakra-ui/react';

export const DynasampleStatus: React.FC<{
  dynasamplesExist: boolean;
  dynasamplesReady: boolean;
  samplerErrorLog: string;
}> = ({ dynasamplesExist, dynasamplesReady, samplerErrorLog }) => {
  if (dynasamplesReady === true) {
    return <h1> Dynasamples are Ready! </h1>;
  } else {
    if (dynasamplesExist === true) {
      if (samplerErrorLog) {
        return (
          <Box borderWidth="1px" borderRadius="lg" mb={4} p={4} w="100%">
            <h1>
              Dynasamples exist but do not seem to be ready, see log below
            </h1>
            <br></br>
            <VStack align="flex-start" overflowX="auto">
              <Stat>
                <StatHelpText as="pre">{samplerErrorLog}</StatHelpText>
              </Stat>
            </VStack>
          </Box>
        );
      } else {
        return (
          <Box borderWidth="1px" borderRadius="lg" mb={4} p={4} w="100%">
            <h1>VSTrex AT WORK: DYNASAMPLING IN PROGRESS...</h1>
          </Box>
        );
      }
    } else {
      return <h1> This dynascore has not been dynasampled </h1>;
    }
  }
};
