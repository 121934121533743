import axios from 'axios';
import React, { useEffect, useState } from 'react';
import QueryString from 'query-string';
import { dateReviver } from '../../../../../common/api/http';
import { Composition } from '../../entities/Composition';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { IUser } from '../../entities/User';
import { Permission } from '../../../../common/entities/Permission';
import { Status, SortOption } from '../../../../common/entities/Composition';
import { BsTriangleFill } from 'react-icons/bs';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { Facet, FacetOption } from '../../../../common/entities/Facet';
import { CompositionSearchFilters, Filters } from './search_filters';
import { useUserContext } from '../../contexts/user';
import { NewComposition } from './new_composition';
import { logger } from '../../../../common/infra/logger';

export const CompositionsTable: React.FC<{}> = () => {
  const { user } = useUserContext();
  const [compositions, setCompositions] = useState<Composition[] | undefined>(
    undefined,
  );
  const [totalCompositionCount, setTotalCompositionCount] =
    useState<number>(undefined);

  const columnDefaultSortAscending: { [column in SortOption]: boolean } = {
    name: true,
    artist: true,
    status: true,
    createdDate: false,
    searchPriority: true,
  };

  const [sortColumn, setSortColumn] = useState<SortOption>('name');
  const [sortAscending, setSortAscending] = useState<boolean>(true);

  const [genreFacetOptions, setGenreFacetOptions] = useState<FacetOption[]>([]);
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);

  const [archiving, setArchiving] = useState<boolean>(false);
  const [generating, setGenerating] = useState<boolean>(false);
  const [canPromote, setCanPromote] = useState<boolean>(false);
  const [canDemote, setCanDemote] = useState<boolean>(false);
  const [promotingDemoting, setPromotingDemoting] = useState<boolean>(false);
  const [selections, setSelections] = useState<Array<number>>([]);
  const queryParams = QueryString.parse(location.search);
  const [filters, setFilters] = useState<Filters>({
    creatorId:
      user.aspects?.employee &&
      !user.permissions.includes(Permission.ViewAllDynascores)
        ? user.id
        : queryParams['creatorId'] &&
          parseInt(queryParams['creatorId'].toString()),
    status: queryParams['status']?.toString(),
    inputValue: queryParams['inputValue']?.toString(),
    pageSize:
      queryParams['pageSize'] && parseInt(queryParams['pageSize'].toString()),
    page: queryParams['page'] && parseInt(queryParams['page'].toString()),
  });
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCompositions = (
    filters: Filters,
    overrides?: { sortColumn: SortOption; sortAscending: boolean },
  ) => {
    setLoading(true);
    const request = axios.get<{ compositions: Composition[] }>(
      '/compositions/all',
      {
        params: {
          ...filters,
          sortColumn: overrides?.sortColumn ?? sortColumn,
          sortDirection:
            overrides?.sortAscending ?? sortAscending ? 'asc' : 'desc',
        },
        transformResponse: [(data) => JSON.parse(data, dateReviver)],
      },
    );
    request
      .then(({ data }) => {
        let compositions = data.compositions;
        setCompositions(compositions);
        setSelections(
          compositions
            .filter((fc) => selections.includes(fc.id))
            .map((fc) => fc.id),
        );
      })
      .finally(() => setLoading(false));
  };

  const fetchCompositionCount = (filters: Filters) => {
    const request = axios.get<{ count: number }>('/compositions/count', {
      params: filters,
    });
    request.then(({ data }) => {
      let count = data.count;
      setTotalCompositionCount(count);
    });
  };

  const fetchFacets = () => {
    axios
      .get<{ facets: Facet[] }>(`/facets/all`)
      .then(({ data }) =>
        setGenreFacetOptions(
          data.facets.find((f) => f.name === 'Genre')?.options || [],
        ),
      );
  };

  const getGenreName = (composition: Composition) => {
    // There should only be 1 genre, but join as a fallback in case that changes
    return genreFacetOptions
      ?.filter((o) => composition.facetOptionIds?.includes(o.id))
      .map((o) => o.name)
      .join(', ');
  };

  const fetchUsers = () => {
    axios
      .get<{ options: { users: IUser[] } }>(`/compositions/composer_options`)
      .then(({ data }) => setUsers(data.options.users));
  };

  const findUser = (userId?: number): IUser | undefined => {
    if (users && userId) {
      return users.find((u) => u.id === userId);
    }
  };

  const fetchCanPromoteDemote = () => {
    const request = axios.get<{ canPromote: boolean; canDemote: boolean }>(
      '/compositions/canPromoteDemote',
    );
    request.then(({ data }) => {
      setCanPromote(data.canPromote);
      setCanDemote(data.canDemote);
    });
  };

  useEffect(() => {
    fetchFacets();
    fetchUsers();
    fetchCanPromoteDemote();
  }, []);

  const archive = async (compositionId, compositionName) => {
    setArchiving(true);
    if (window.confirm(`Delete ${compositionName}?`)) {
      axios
        .delete(`/compositions/${compositionId}/delete`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          fetchCompositions(filters);
          fetchCompositionCount(filters);
          setArchiving(false);
        })
        .catch((error) => {
          logger.warn(`Error deleting composition ${error.message}`);
          setArchiving(false);
        });
    } else {
      setArchiving(false);
    }
  };

  const promoteAll = (generateMaster: boolean) => {
    const confirmMessage = `Are you sure you want to promote all approved Dynascores?`;
    if (window.confirm(confirmMessage)) {
      setPromotingDemoting(true);
      axios
        .patch(
          `/compositions/promoteDemoteAll?action=promote&generateMaster=${generateMaster}`,
          { headers: { 'Content-Type': 'application/json' } },
        )
        .catch((error) => {
          logger.warn(`Error promoting all Dynascores ${error.message}`);
          if (error.response?.data?.error) {
            logger.warn(error.response.data.error);
          }
        })
        .finally(() => setPromotingDemoting(false));
    }
  };

  const demoteAll = (generateMaster: boolean) => {
    if (window.confirm(`Are you sure you want to demote all Dynascores?`)) {
      setPromotingDemoting(true);
      axios
        .patch(
          `/compositions/promoteDemoteAll?action=demote&generateMaster=${generateMaster}`,
          { headers: { 'Content-Type': 'application/json' } },
        )
        .catch((error) => {
          logger.warn(`Error demoting all Dynascores ${error.message}`);
          if (error.response?.data?.error) {
            logger.warn(error.response.data.error);
          }
        })
        .finally(() => setPromotingDemoting(false));
    }
  };

  const generateSuite = (
    option: 'qa' | 'sample',
    render?: boolean,
    useVelociRender?: boolean,
  ) => {
    setGenerating(true);
    let selection: string;
    selection = 'Composition Only';
    if (render) {
      selection = 'VSTrex';
    }
    if (useVelociRender) {
      selection = 'VelociRender';
    }
    if (
      window.confirm(
        `Are you sure you want to generate ${selection}
        ${option === 'qa' ? 'QA tracks' : 'samples'} for ${
          selections.length
        } dynascores?`,
      )
    ) {
      let url: string;
      switch (option) {
        case 'qa':
          url = '/compositions/generateQaForAll';
          break;
        case 'sample':
          url = '/compositions/generateSampleForAll';
          break;
      }
      const data = {
        compositionIds: selections,
        render: !!render,
        useVelociRender: !!useVelociRender,
      };
      axios
        .patch(url, {
          headers: {
            'Content-Type': 'application/json',
          },
          data,
        })
        .then(() => {
          setGenerating(false);
        })
        .catch((error) => {
          logger.warn(
            `Error generating QA for all compositions ${error.message}`,
          );
          if (error.response.data.error) {
            logger.warn(error.response.data.error);
          }
          setGenerating(false);
        });
    } else {
      setGenerating(false);
    }
  };

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        w="100%"
        mb={4}
      >
        <Heading fontSize="xl">Dynascores</Heading>

        <HStack>
          {(canPromote || canDemote) && (
            <Menu autoSelect={false}>
              <MenuButton
                as={Button}
                size="sm"
                isLoading={promotingDemoting}
                isDisabled={promotingDemoting}
                rightIcon={<Icon as={HiOutlineChevronDown} />}
              >
                {canPromote && !canDemote
                  ? 'Promote All'
                  : !canPromote && canDemote
                  ? 'Demote All'
                  : 'Promote/Demote All'}
              </MenuButton>
              <MenuList>
                {canPromote && (
                  <MenuGroup title="Promote All Approved">
                    <MenuItem onClick={() => promoteAll(true)}>
                      Promote All Approved With Master Generation
                    </MenuItem>
                    <MenuItem onClick={() => promoteAll(false)}>
                      Promote All Approved and Skip Master Generation
                    </MenuItem>
                  </MenuGroup>
                )}
                {canDemote && (
                  <MenuGroup title="Demote All">
                    <MenuItem onClick={() => demoteAll(true)}>
                      Demote All With Master Generation
                    </MenuItem>
                    <MenuItem onClick={() => demoteAll(false)}>
                      Demote All and Skip Master Generation
                    </MenuItem>
                  </MenuGroup>
                )}
              </MenuList>
            </Menu>
          )}

          <Menu autoSelect={false}>
            <MenuButton
              as={Button}
              size="sm"
              rightIcon={<Icon as={HiOutlineChevronDown} />}
              isLoading={generating}
              isDisabled={generating || selections.length === 0}
            >
              QA Selected
            </MenuButton>
            {/* Override MenuList fontSize due to Table size="sm" making it smaller by default */}
            <MenuList fontSize="1rem">
              <MenuItem onClick={() => generateSuite('qa', false, false)}>
                Compose Only
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => generateSuite('qa', true, false)}>
                With VSTrex
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => generateSuite('qa', false, true)}>
                With VelociRender
              </MenuItem>
            </MenuList>
          </Menu>

          <Button
            size="sm"
            onClick={() => generateSuite('sample', false, true)}
            isLoading={generating}
            isDisabled={generating || selections.length === 0}
          >
            Sample Selected
          </Button>

          <NewComposition
            onSave={() => {
              fetchCompositions(filters);
              fetchCompositionCount(filters);
            }}
          />
        </HStack>
      </Flex>

      <CompositionSearchFilters
        filter={(filters) => {
          fetchCompositions(filters);
          fetchCompositionCount(filters);
          setFilters(filters);
        }}
        initialFilters={filters}
        totalCompositionCount={totalCompositionCount}
      >
        <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>
                  <Checkbox
                    isChecked={
                      compositions?.length > 0 &&
                      compositions?.every((c) => selections.includes(c.id))
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setSelections(compositions.map((c) => c.id));
                      } else {
                        setSelections([]);
                      }
                    }}
                    isDisabled={!compositions || compositions.length == 0}
                  />
                </Th>
                {(
                  [
                    { name: 'Name', column: 'name' },
                    { name: 'Composer', column: 'composer' },
                    { name: 'Artist', column: 'artist' },
                    { name: 'Genre', column: 'genre' },
                    { name: 'Status', column: 'status' },
                    { name: 'Created', column: 'createdDate' },
                  ] as {
                    name: string;
                    column: string;
                  }[]
                ).map(({ name, column }) => (
                  <Th
                    key={column}
                    onClick={() => {
                      if (column == sortColumn) {
                        const ascending = !sortAscending;
                        setSortAscending(ascending);
                        fetchCompositions(filters, {
                          sortColumn: column,
                          sortAscending: ascending,
                        });
                      } else if (column !== 'composer' && column !== 'genre') {
                        setSortColumn(column as SortOption);
                        setSortAscending(columnDefaultSortAscending[column]);
                        fetchCompositions(filters, {
                          sortColumn: column as SortOption,
                          sortAscending: columnDefaultSortAscending[column],
                        });
                      }
                    }}
                  >
                    {name}
                    {column === sortColumn && (
                      <Icon
                        as={BsTriangleFill}
                        mx={2}
                        boxSize={2}
                        transform={`rotate(${sortAscending ? 0 : 180}deg)`}
                      />
                    )}
                  </Th>
                ))}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading &&
                [
                  ...Array(
                    Math.min(
                      totalCompositionCount || 10,
                      filters.pageSize || 10,
                    ),
                  ),
                ].map((_, index) => (
                  <Tr key={index}>
                    <Td>
                      <Checkbox isDisabled={true} />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
              {!loading &&
                compositions &&
                compositions.map((composition) => (
                  <Tr key={composition.id}>
                    <Td>
                      <Checkbox
                        isChecked={selections.includes(composition.id)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            if (!selections.includes(composition.id)) {
                              setSelections(
                                selections.concat([composition.id]),
                              );
                            }
                          } else {
                            if (selections.includes(composition.id)) {
                              setSelections(
                                selections.filter(
                                  (selection) => selection !== composition.id,
                                ),
                              );
                            }
                          }
                        }}
                      />
                    </Td>
                    <Td>
                      <Link href={'/compositions/' + composition.id}>
                        {composition.name}
                      </Link>
                    </Td>
                    <Td>
                      {composition.creatorId && (
                        <Link
                          href={`/compositions?creatorId=${composition.creatorId}`}
                        >
                          {findUser(composition.creatorId)?.name}
                        </Link>
                      )}
                    </Td>
                    <Td>{composition.artist}</Td>
                    <Td>{getGenreName(composition)}</Td>
                    <Td>{Status[composition.status]}</Td>
                    <Td>{composition.createdDate.toLocaleString()}</Td>
                    <Td>
                      <Flex justify="flex-end">
                        <Button
                          colorScheme="red"
                          size="sm"
                          onClick={() =>
                            archive(composition.id, composition.name)
                          }
                          isDisabled={archiving}
                        >
                          Delete
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </Box>
      </CompositionSearchFilters>
    </>
  );
};
