import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { dateReviver } from '../../../../../../common/api/http';
import {
  Box,
  Button,
  Heading,
  HStack,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { NewMasteringPreset } from './new_preset';
import { MasteringPreset } from '../../../../../common/entities/MasteringPreset';
import { Permission } from '../../../../../common/entities/Permission';
import { Permissions } from '../../../components/Permissions';
import { useUserContext } from '../../../contexts/user';
import { logger } from '../../../../../common/infra/logger';

export const MasteringPresetsTable: React.FC<{}> = () => {
  const [masteringPresets, setMasteringPresets] = useState<
    MasteringPreset[] | undefined
  >(undefined);
  const [archiving, setArchiving] = useState<boolean>(false);
  const { user } = useUserContext();

  const fetchPresets = () => {
    const request = axios.get<{ masteringPresets: MasteringPreset[] }>(
      '/mastering_presets/all',
      {
        transformResponse: [(data) => JSON.parse(data, dateReviver)],
      },
    );
    request.then(({ data }) => {
      setMasteringPresets(data.masteringPresets);
    });
  };

  useEffect(() => {
    fetchPresets();
  }, []);

  const archive = async (id, name) => {
    setArchiving(true);
    if (window.confirm(`Delete ${name}?`)) {
      axios
        .delete(`/mastering_presets/${id}/delete`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          fetchPresets();
          setArchiving(false);
        })
        .catch((error) => {
          logger.warn(`Error deleting mastering preset ${error.message}`);
          setArchiving(false);
        });
    } else {
      setArchiving(false);
    }
  };

  return (
    <>
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        w="100%"
        mb={4}
      >
        <Heading fontSize="xl">RenderRoar Mastering Presets</Heading>

        <Permissions user={user} show={[Permission.ViewAllDynascores]}>
          <HStack>
            <NewMasteringPreset onSave={() => fetchPresets()} />
          </HStack>
        </Permissions>
      </Flex>
      <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
        <Table size="sm">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>File Name</Th>
              <Th>Created</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {masteringPresets &&
              masteringPresets
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((preset) => (
                  <Tr key={preset.id}>
                    <Td>{preset.name}</Td>
                    <Td>{preset.prefixKey}</Td>
                    <Td>{new Date(preset.createdDate).toLocaleString()}</Td>
                    <Td>
                      <Permissions
                        user={user}
                        show={[Permission.ViewAllDynascores]}
                      >
                        <Flex justify="flex-end">
                          <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => archive(preset.id, preset.name)}
                            isDisabled={archiving}
                          >
                            Delete
                          </Button>
                        </Flex>
                      </Permissions>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </Box>
    </>
  );
};
