import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  CompositionConfig,
  SHORTENING_OPTIONS,
  ELONGATION_OPTIONS,
  fixUpCompositionConfig,
} from '../configType';
import { RuleSets } from './rule_sets';
import { SplitTracks } from './split_tracks';
import { BlockRules } from './block_rules';
import NestingCard from './nesting_card';
import {
  Button,
  Heading,
  HStack,
  Input,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useUserContext } from '../../../contexts/user';
import { Permission } from '../../../../../common/entities/Permission';
import { Permissions } from '../../../components/Permissions';
import { logger } from '../../../../../common/infra/logger';

export const ConfigForm: React.FC<{
  initialConfigJson: CompositionConfig;
  setFormEditor(value: boolean): void;
  save(configJSON: string): void;
  saving: boolean;
  compositionId: number;
}> = ({ initialConfigJson, setFormEditor, save, saving, compositionId }) => {
  const [config, setConfig] = useState(
    fixUpCompositionConfig(initialConfigJson),
  );

  const [blocks, setBlocks] = useState<string[]>([]);
  const [loadBlocksError, setLoadBlocksError] = useState<any>();
  const { user } = useUserContext();

  const fetchBlocks = () => {
    axios
      .get<{ blocks: string[] }>(`/compositions/${compositionId}/blocks`)
      .then(({ data }) => setBlocks(data.blocks))
      .catch((error) => {
        logger.warn(`Error fetch blocks: ${error.message}`);
        setBlocks([]);
        setLoadBlocksError(error);
      });
  };

  useEffect(fetchBlocks, []);

  return (
    <form method="PATCH" onSubmit={() => save(JSON.stringify(config))}>
      <VStack align="flex-start" spacing={4}>
        <HStack justify="flex-end" w="100%">
          <Permissions user={user} show={[Permission.ViewAllDynascores]}>
            <Button size="sm" onClick={() => setFormEditor(false)}>
              JSON Editor
            </Button>
          </Permissions>
          <Button
            type="submit"
            size="sm"
            colorScheme="blue"
            isDisabled={saving || !blocks}
          >
            Save
          </Button>
        </HStack>

        <NestingCard>
          <Heading as="h3" fontSize="md" mb={2}>
            Tracks To Split
          </Heading>
          <SplitTracks
            values={config.tracks_to_split || []}
            onChange={(tracks_to_split) =>
              setConfig({
                ...config,
                tracks_to_split,
              })
            }
          />
        </NestingCard>

        <NestingCard>
          <Heading as="h3" fontSize="md" mb={2}>
            Default Rules
          </Heading>

          <NestingCard mb={3}>
            <Heading as="h4" fontSize="md" mb={2}>
              BPM Tolerance % (Global)
            </Heading>
            <Input
              bg="white"
              type="number"
              min={0}
              max={100}
              step="any"
              value={config.bpmTolerance}
              onChange={(e) =>
                setConfig({ ...config, bpmTolerance: e.target.value })
              }
            />
          </NestingCard>

          <Stack direction={['column', 'row']} spacing={4}>
            <NestingCard>
              <Heading as="h4" fontSize="md" mb={2}>
                Shortening Rules
              </Heading>
              <RuleSets
                options={SHORTENING_OPTIONS}
                values={config.default_shortening_rules || []}
                onChange={(default_shortening_rules) =>
                  setConfig({ ...config, default_shortening_rules })
                }
              />
            </NestingCard>

            <NestingCard>
              <Heading as="h4" fontSize="md" mb={2}>
                Elongation Rules
              </Heading>
              <RuleSets
                options={ELONGATION_OPTIONS}
                values={config.default_elongation_rules || []}
                onChange={(default_elongation_rules) =>
                  setConfig({ ...config, default_elongation_rules })
                }
              />
            </NestingCard>
          </Stack>
        </NestingCard>

        {loadBlocksError ? (
          <NestingCard>
            <Heading as="h4" fontSize="md">
              {loadBlocksError.message}
            </Heading>
          </NestingCard>
        ) : blocks.length === 0 ? (
          <NestingCard>
            <Heading as="h4" fontSize="md">
              Loading blocks...
            </Heading>
          </NestingCard>
        ) : (
          <BlockRules
            blocks={blocks}
            values={config.block_rules || {}}
            onChange={(block_rules) => setConfig({ ...config, block_rules })}
            onAdd={(name) => {
              setConfig({
                ...config,
                block_rules: {
                  ...config.block_rules,
                  [name]: {
                    shortening_rules: config.default_shortening_rules,
                    elongation_rules: config.default_elongation_rules,
                  },
                },
              });
            }}
          />
        )}
      </VStack>
    </form>
  );
};
