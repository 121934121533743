import axios from 'axios';
import React, { useState } from 'react';
import { Composition } from '../../entities/Composition';
import {
  Box,
  Button,
  Heading,
  HStack,
  Stat,
  StatHelpText,
  StatLabel,
  VStack,
} from '@chakra-ui/react';
import { timingsInFractionalSeconds } from '../../../../common/models/DynascoreInput';
import { logger } from '../../../../common/infra/logger';

export const CompositionMasterView: React.FC<{
  composition: Composition;
  fetchComposition(): void;
  onUpdate(error: string | undefined): void;
}> = ({ composition, onUpdate, fetchComposition }) => {
  const [isGenerating, setGenerating] = useState(false);

  let sampleInSeconds = composition.sample
    ? timingsInFractionalSeconds(composition.sample)
    : null;
  let masterInSeconds = composition.master
    ? timingsInFractionalSeconds(composition.master)
    : null;

  const generateMaster = () => {
    if (window.confirm(`Generate master for ${composition.name}?`)) {
      setGenerating(true);
      axios
        .patch(`/compositions/${composition.id}/generateMaster`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          fetchComposition();
          onUpdate(undefined);
          setGenerating(false);
        })
        .catch((error) => {
          logger.warn(`Error generating master ${error.message}`);
          if (error.response.data.error) {
            onUpdate(error.response.data.error);
          }
          setGenerating(false);
        });
    }
  };

  const generateSample = () => {
    if (window.confirm(`Generate sample for ${composition.name}?`)) {
      setGenerating(true);
      axios
        .patch(`/compositions/${composition.id}/generateSample`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then(() => {
          fetchComposition();
          onUpdate(undefined);
          setGenerating(false);
        })
        .catch((error) => {
          logger.warn(`Error generating sample ${error.message}`);
          if (error.response.data.error) {
            onUpdate(error.response.data.error);
          }
          setGenerating(false);
        });
    }
  };

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
      <VStack align="flex-start">
        {composition.sample?.status === 'complete' && (
          <Box>
            Sample:{' '}
            <audio
              controls
              src={`/dynascores/${composition.sample.datePrefix}/${composition.sample.uuid}/files/download?ext=wav`}
            >
              Your browser does not support the <code>audio</code> element.
            </audio>
          </Box>
        )}
        {composition.master?.status === 'complete' && (
          <Box>
            Master:{' '}
            <audio
              controls
              src={`/dynascores/${composition.master.datePrefix}/${composition.master.uuid}/files/download?ext=wav`}
            >
              Your browser does not support the <code>audio</code> element.
            </audio>
          </Box>
        )}

        <HStack>
          <Box borderWidth="1px" borderRadius="lg" p={4} w="100%" h="100%">
            <Heading fontSize="m">Sample</Heading>

            <Stat>
              <StatLabel>Duration</StatLabel>
              <StatHelpText>{sampleInSeconds?.duration || '—'}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Markers</StatLabel>
              <StatHelpText wordBreak="break-word">
                {sampleInSeconds?.markers || '—'}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Ending Start</StatLabel>
              <StatHelpText>
                {sampleInSeconds?.falseEndingMarker || '—'}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Bump</StatLabel>
              <StatHelpText>
                {sampleInSeconds?.endingMarker || '—'}
              </StatHelpText>
            </Stat>
          </Box>

          <Box borderWidth="1px" borderRadius="lg" p={4} w="100%" h="100%">
            <Heading fontSize="m">Master</Heading>

            <Stat>
              <StatLabel>Duration</StatLabel>
              <StatHelpText>{masterInSeconds?.duration || '—'}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Markers</StatLabel>
              <StatHelpText wordBreak="break-word">
                {masterInSeconds?.markers || '—'}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Ending Start</StatLabel>
              <StatHelpText>
                {masterInSeconds?.falseEndingMarker || '—'}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Bump</StatLabel>
              <StatHelpText>
                {masterInSeconds?.endingMarker || '—'}
              </StatHelpText>
            </Stat>
          </Box>
        </HStack>

        <HStack>
          <Button
            size="sm"
            isDisabled={isGenerating}
            isLoading={isGenerating}
            onClick={() => generateMaster()}
          >
            Generate Master
          </Button>
          <Button
            size="sm"
            isDisabled={isGenerating}
            isLoading={isGenerating}
            onClick={() => generateSample()}
          >
            Generate Sample
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};
