import axios from 'axios';
import React, { useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { logger } from '../../../../common/infra/logger';

export const NewComposition: React.FC<{ onSave(): void }> = ({ onSave }) => {
  const [newCompositionName, setNewCompositionName] = useState<string>('');
  const toast = useToast();

  const createComposition = (event) => {
    event.preventDefault();
    logger.info('Creating composition called ' + newCompositionName);
    const data = { composition: { name: newCompositionName } };
    axios
      .post(`/compositions/new`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        logger.debug(response);
        onSave();
      })
      .catch((error) => {
        logger.warn(error);
        toast({
          position: 'top',
          title: 'Error Creating Dynascore',
          description: (
            <VStack align="flex-start">
              <Text>{error.response.data.error || 'Unkown Error'}</Text>
            </VStack>
          ),
          status: 'error',
          duration: null,
          isClosable: true,
        });
      })
      .finally(() => {
        setNewCompositionName('');
      });
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button colorScheme="blue" size="sm">
          New
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>New Dynascore</PopoverHeader>
        <PopoverBody>
          <form onSubmit={createComposition}>
            <VStack align="flex-start">
              <FormControl id="newComposition">
                <Input
                  name="newComposition"
                  placeholder="Dynascore Name"
                  value={newCompositionName}
                  onChange={(e) => setNewCompositionName(e.target.value)}
                  isRequired
                />
              </FormControl>

              <Button colorScheme="blue" type="submit">
                Create
              </Button>
            </VStack>
          </form>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
