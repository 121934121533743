import React from 'react';
import { IUser } from '../entities/User';
import { Permission } from '../../../common/entities/Permission';

export const Permissions: React.FC<{ user: IUser; show?: Permission[] }> = ({
  user,
  show,
  children,
}) => {
  if (user.permissions.some((p) => show.includes(p))) {
    return <>{children}</>;
  } else {
    return null;
  }
};
