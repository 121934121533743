import axios from 'axios';
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Playlist } from '../../../entities/Playlist';
import { CompositionSearch } from '../../../components/CompositionSearch';

interface CompositionLight {
  id: number;
  displayOrder: number;
  name: string;
}

const EditPlaylistTable: React.FC<{
  playlistId: number;
  onClose(): void;
  hasPermission: boolean;
}> = ({ playlistId, onClose, hasPermission }) => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [compositions, setCompositions] = useState<CompositionLight[]>([]);
  const [edited, setEdited] = useState<boolean>(false);
  const toast = useToast();

  const fetchPlaylist = () => {
    setLoading(true);
    const request = axios.get<{ playlist: Playlist }>(
      `/playlists/${playlistId}`,
    );
    request
      .then(({ data }) => {
        const playlist = data.playlist;
        setCompositions(
          playlist.compositions.map((c, index) => ({
            ...c,
            displayOrder: index + 1,
          })),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (playlistId) {
      fetchPlaylist();
    }
  }, [playlistId]);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index == result.source.index) {
      return;
    }

    // // Create new copy of compositions with priority set in displayOrder
    const copy = [...compositions];
    const [item] = copy.splice(result.source.index, 1);
    item.displayOrder = result.destination.index + 1;
    copy.splice(result.destination.index, 0, item);

    // store new orders used in params of update
    const newOrder = copy.map((p, index) => {
      const displayOrder = index + 1;
      return {
        id: p.id,
        displayOrder: displayOrder,
      };
    });

    // reset compositions in displayOrder of draft priorities
    setCompositions(
      copy.map((p) => ({
        ...p,
        displayOrder: newOrder.find((s) => s.id === p.id)?.displayOrder,
      })),
    );
    setEdited(true);
  };

  const onDelete = (compositionId: number) => {
    const newCompositions = compositions
      .filter((c) => c.id !== compositionId)
      .map((c, index) => ({ ...c, displayOrder: index + 1 }));
    setCompositions(newCompositions);
    setEdited(true);
  };

  const onSave = () => {
    axios
      .patch(
        `/playlists/${playlistId}/bulkUpdatePlaylistCompositions`,
        {
          playlistCompositions: compositions.map((c) => ({
            compositionId: c.id,
            displayOrder: c.displayOrder,
          })),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        onClose();
      })
      .catch((error) => {
        if (error.response.data.error) {
          toast({
            position: 'top',
            title: 'Error',
            description: (
              <VStack align="flex-start">
                <Text>{error.response.data.error}</Text>
              </VStack>
            ),
            status: 'error',
            duration: null,
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <ModalBody>
        <Box borderWidth="1px" borderRadius="lg" w="100%" overflowX="auto">
          <Table size="sm">
            <Thead>
              <Tr>
                <Th w="1%"></Th>
                <Th w="1%">Order</Th>
                <Th w="80%">Name</Th>
                <Th></Th>
              </Tr>
            </Thead>
            {loading && (
              <Tbody>
                {[...Array(10)].map((_, index) => (
                  <Tr key={index}>
                    <Td>
                      <Icon as={BsThreeDotsVertical} mx={2} boxSize={3} />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td>
                      <Skeleton height="20px" />
                    </Td>
                    <Td></Td>
                  </Tr>
                ))}
              </Tbody>
            )}
            {!loading && (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="droppable"
                  isDropDisabled={!hasPermission}
                >
                  {(provided) => (
                    <Tbody
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{}}
                    >
                      {compositions &&
                        compositions.map((composition, index) => (
                          <Draggable
                            key={`draggable_${composition.id}`}
                            draggableId={composition.id.toString()}
                            index={index}
                            isDragDisabled={!hasPermission}
                          >
                            {(provided, snapshot) => (
                              <Tr
                                key={composition.id}
                                align="center"
                                justify="space-between"
                                mb={2}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: snapshot.isDragging
                                    ? 'rgba(0, 0, 0, 0.1)'
                                    : 'inherit',
                                }}
                              >
                                <Td>
                                  <Icon
                                    as={BsThreeDotsVertical}
                                    mx={2}
                                    boxSize={3}
                                  />
                                </Td>
                                <Td>
                                  {composition.displayOrder || composition.id}
                                </Td>
                                <Td>{composition.name}</Td>
                                <Td>
                                  <Flex justify="flex-end">
                                    <HStack>
                                      <Button
                                        colorScheme="red"
                                        size="sm"
                                        onClick={() => onDelete(composition.id)}
                                        isDisabled={!hasPermission}
                                      >
                                        Delete
                                      </Button>
                                    </HStack>
                                  </Flex>
                                </Td>
                              </Tr>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </Tbody>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Table>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Button
          colorScheme="blue"
          mr={3}
          onClick={onSave}
          isDisabled={!hasPermission || !edited}
        >
          Save
        </Button>
        <Box width="50%">
          <CompositionSearch
            onSelection={(id: number, name: string) => {
              const copy = [...compositions];
              copy.push({ id, name, displayOrder: copy.length + 1 });
              setCompositions(copy);
              setEdited(true);
            }}
            placeHolder="Add Dynascores"
            hasPermission={hasPermission}
          />
        </Box>
      </ModalFooter>
    </>
  );
};

export const EditModal: React.FC<{
  onClose(): void;
  isOpen: boolean;
  playlistId: number;
  hasPermission: boolean;
  playlistName?: string;
}> = ({ onClose, isOpen, playlistId, hasPermission, playlistName }) => {
  return (
    <Modal
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      scrollBehavior="inside"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{playlistName ?? 'Editing Playlist'}</ModalHeader>
        <ModalCloseButton />
        <EditPlaylistTable
          playlistId={playlistId}
          onClose={onClose}
          hasPermission={hasPermission}
        />
      </ModalContent>
    </Modal>
  );
};
