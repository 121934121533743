import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Permission } from '../../../../common/entities/Permission';
import { Permissions } from '../../components/Permissions';
import { useUserContext } from '../../contexts/user';
import { Facet } from '../../../../common/entities/Facet';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagLabel,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  useToast,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { logger } from '../../../../common/infra/logger';

export const Facets = () => {
  const match = useRouteMatch();
  const toast = useToast();

  const [facets, setFacets] = useState<Facet[] | undefined>();
  const [isSyncing, setSyncing] = useState(false);
  const [isYextSyncing, setYextSyncing] = useState(false);
  const { user } = useUserContext();

  const fetchFacets = () => {
    return axios
      .get<{ facets: Facet[] }>('/facets/all')
      .then(({ data }) => setFacets(data.facets));
  };

  useEffect(() => {
    fetchFacets();
  }, []);

  const doSyncFromDisk = () => {
    if (window.confirm(`Sync facets from disk?`)) {
      setSyncing(true);
      axios
        .post(`/facets/syncFromDisk`)
        .then(fetchFacets)
        .then(() =>
          toast({
            position: 'top',
            title: 'Facet Sync Complete',
            status: 'success',
            duration: 3000,
            isClosable: true,
          }),
        )
        .catch((error) => {
          logger.warn(`Error syncing facets from disk ${error.message}`);
          toast({
            position: 'top',
            title: 'An error occurred.',
            description: 'Unable to sync facets from disk.',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => setSyncing(false));
    }
  };

  const doSyncToYext = () => {
    if (
      window.confirm(
        `Sync all dynascore facets to Yext? This will happen in the background and could take several minutes.`,
      )
    ) {
      setYextSyncing(true);
      axios
        .post(`/compositions/resyncYextKnowledgeGraph`)
        .then(() =>
          toast({
            position: 'top',
            title: 'Yext Sync Kicked Off',
            status: 'success',
            duration: 3000,
            isClosable: true,
          }),
        )
        .catch((error) => {
          logger.warn(
            `Error syncing to Yext Knowledge graph: ${
              error.response?.data?.error ?? error.message
            }`,
          );
          toast({
            position: 'top',
            title: 'An error occurred.',
            description: 'Unable to all dynascore facets to yext',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        })
        .finally(() => setYextSyncing(false));
    }
  };

  return (
    <Switch>
      <Route path={match.path}>
        <Flex align="center" justify="space-between" w="100%" mb={4}>
          <Heading fontSize="xl">Facets</Heading>

          <Permissions user={user} show={[Permission.SyncFacetData]}>
            <HStack>
              <Button
                size="sm"
                colorScheme="green"
                isDisabled={isSyncing}
                isLoading={isSyncing}
                onClick={() => doSyncFromDisk()}
              >
                Sync from Disk
              </Button>
              <Button
                size="sm"
                colorScheme="blue"
                isDisabled={isYextSyncing}
                isLoading={isYextSyncing}
                onClick={() => doSyncToYext()}
              >
                Sync to Yext
              </Button>
            </HStack>
          </Permissions>
        </Flex>

        {facets && (
          <Tabs size="sm" w="100%">
            <TabList flexWrap="wrap">
              {facets.map((facet) => (
                <Tab key={facet.id}>{facet.name}</Tab>
              ))}
            </TabList>

            <TabPanels>
              {facets.map((facet) => (
                <TabPanel key={facet.id}>
                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    w="100%"
                    overflowX="auto"
                    mb={4}
                  >
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Aliases</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {facet.options.map((option) => (
                          <Tr key={option.id}>
                            <Td>
                              <Tag borderRadius="full">
                                <TagLabel>{option.name}</TagLabel>
                              </Tag>
                            </Td>
                            <Td>
                              <Wrap spacing={2}>
                                {option.aliases.map((alias) => (
                                  <WrapItem key={alias}>
                                    <Tag borderRadius="full">
                                      <TagLabel>{alias}</TagLabel>
                                    </Tag>
                                  </WrapItem>
                                ))}
                              </Wrap>
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th colSpan={2}>Total {facet.options.length}</Th>
                        </Tr>
                      </Tfoot>
                    </Table>
                  </Box>

                  <Box
                    borderWidth="1px"
                    borderRadius="lg"
                    w="100%"
                    overflowX="auto"
                  >
                    <Table size="sm">
                      <Thead>
                        <Tr>
                          <Th colSpan={2}>Rules</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td>Minimum Options Required per Dynascore</Td>
                          <Td>{facet.minOptionsRequired}</Td>
                        </Tr>
                        <Tr>
                          <Td>Maximum Options Allowed per Dynascore</Td>
                          <Td>{facet.maxOptionsAllowed || '—'}</Td>
                        </Tr>
                        <Tr>
                          <Td>Dynascore {facet.name} is Populated</Td>
                          <Td>
                            {facet.manuallyPopulated
                              ? 'Manually'
                              : 'From Master MIDI'}
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </Box>
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        )}
      </Route>
    </Switch>
  );
};
