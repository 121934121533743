// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/*\n * These class names are defined by the react-autosuggest component\n *\n * See https://github.com/moroshko/react-autosuggest#theme-optional\n */\n\n.Autosuggest-module__container--DAHiI {\n  position: relative;\n}\n\n.Autosuggest-module__suggestionsContainer--2SQ12 {\n  display: none;\n}\n\n.Autosuggest-module__suggestionsContainerOpen--206_H {\n  background-color: white;\n  display: block;\n  left: 0;\n  max-height: 16rem;\n  overflow-y: auto;\n  position: absolute;\n  right: 0;\n  top: 2.5rem;\n  z-index: 10;\n}\n\n.Autosuggest-module__suggestionsList--1Lrhe {\n  display: flex;\n  flex-direction: column;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://dynascore/client/src/Autosuggest.module.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,OAAO;EACP,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,SAAS;EACT,UAAU;AACZ","sourcesContent":["/*\n * These class names are defined by the react-autosuggest component\n *\n * See https://github.com/moroshko/react-autosuggest#theme-optional\n */\n\n.container {\n  position: relative;\n}\n\n.suggestionsContainer {\n  display: none;\n}\n\n.suggestionsContainerOpen {\n  background-color: white;\n  display: block;\n  left: 0;\n  max-height: 16rem;\n  overflow-y: auto;\n  position: absolute;\n  right: 0;\n  top: 2.5rem;\n  z-index: 10;\n}\n\n.suggestionsList {\n  display: flex;\n  flex-direction: column;\n  list-style-type: none;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Autosuggest-module__container--DAHiI",
	"suggestionsContainer": "Autosuggest-module__suggestionsContainer--2SQ12",
	"suggestionsContainerOpen": "Autosuggest-module__suggestionsContainerOpen--206_H",
	"suggestionsList": "Autosuggest-module__suggestionsList--1Lrhe"
};
export default ___CSS_LOADER_EXPORT___;
