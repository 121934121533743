enum ShorteningOption {
  CutMiddle = 'CutMiddle',
  CutFinalBar = 'CutFinalBar',
  CutFromEndOfFinalBar1 = 'CutFromEndOfFinalBar1',
  CutFromEndOfFinalBar2 = 'CutFromEndOfFinalBar2',
  CutFromEndOfFinalBar3 = 'CutFromEndOfFinalBar3',
  CutFromStartOfFinalBar1 = 'CutFromStartOfFinalBar1',
  CutFromStartOfFinalBar2 = 'CutFromStartOfFinalBar2',
  CutFromStartOfFinalBar3 = 'CutFromStartOfFinalBar3',
}

export const SHORTENING_OPTIONS = Object.keys(
  ShorteningOption,
) as ShorteningOption[];

enum ElongationOption {
  RepeatFirstBar1 = 'RepeatFirstBar1',
  RepeatFirstBar2 = 'RepeatFirstBar2',
  RepeatFirstBar3 = 'RepeatFirstBar3',
  RepeatFirstTwoBars1 = 'RepeatFirstTwoBars1',
  RepeatFirstTwoBars2 = 'RepeatFirstTwoBars2',
  RepeatFirstTwoBars3 = 'RepeatFirstTwoBars3',
  RepeatFirstFourBars1 = 'RepeatFirstFourBars1',
  RepeatFirstFourBars2 = 'RepeatFirstFourBars2',
  RepeatFirstFourBars3 = 'RepeatFirstFourBars3',
  Vamp1 = 'Vamp1',
  Vamp2 = 'Vamp2',
  Vamp3 = 'Vamp3',
}

export const ELONGATION_OPTIONS = Object.keys(
  ElongationOption,
) as ElongationOption[];

export interface CompositionBlockRules {
  shortening_rules: ShorteningOption[][];
  elongation_rules: ElongationOption[][];
}

export interface CompositionConfig {
  tracks_to_split?: string[];
  bpmTolerance: string; // number within string
  default_shortening_rules: ShorteningOption[][];
  default_elongation_rules: ElongationOption[][];
  block_rules: { [name in string]: CompositionBlockRules };
}

export const emptyCompositionConfig: CompositionConfig = {
  bpmTolerance: '',
  default_shortening_rules: [],
  default_elongation_rules: [],
  block_rules: {},
};

// Fix up certain types of issues with the composition config, such as
// variables being undefined when they should be an empty array.
export const fixUpCompositionConfig = (
  config: CompositionConfig,
): CompositionConfig => {
  return {
    // Pre-fill in the empty config to ensure all required fields are set
    ...emptyCompositionConfig,

    // Use the provided config to override all provided fields
    ...config,

    // Ensure default rules exist or are empty array (i.e., not undefined)
    default_shortening_rules: config.default_shortening_rules || [],
    default_elongation_rules: config.default_elongation_rules || [],

    // Fix any issues with block_rules
    block_rules: Object.keys(config.block_rules || {}).reduce(
      (block_rules, name) => {
        const block = block_rules[name];
        block_rules[name] = {
          ...block,

          // Ensure block rules exist or are empty array (i.e., not undefined)
          shortening_rules: block.shortening_rules || [],
          elongation_rules: block.elongation_rules || [],
        };
        return block_rules;
      },
      config.block_rules || {},
    ),
  };
};
