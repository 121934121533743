import axios, { CancelTokenSource } from 'axios';
import React, { useEffect, useState } from 'react';
import { CompositionStatus } from '../../entities/Composition';
import { IUser } from '../../entities/User';
import { Permission } from '../../../../common/entities/Permission';
import Autosuggest from 'react-autosuggest';
import QueryString from 'query-string';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Select,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import {
  DEFAULT_INITIAL_PAGE_SIZE,
  Pagination,
} from '../../components/Pagination';
import { useUserContext } from '../../contexts/user';
import { StatusOrder } from '../../../../common/entities/Composition';

import autosuggestTheme from '../../Autosuggest.module.css';
import { logger } from '../../../../common/infra/logger';

interface FilterOptions {
  users: Array<IUser>;
  statuses: Array<CompositionStatus>;
}

export interface Filters {
  creatorId?: number;
  status?: string;
  inputValue?: string;
  compositionIds?: number[];
  pageSize?: number;
  page?: number;
}

export const CompositionSearchFilters: React.FC<{
  filter(filters: Filters): void;
  initialFilters: Filters;
  totalCompositionCount: number;
}> = ({ filter, initialFilters, totalCompositionCount, children }) => {
  const [status, setStatus] = useState<string>(initialFilters.status ?? '');
  const [selectedCreatorId, setSelectedCreatorId] = useState<number>(
    initialFilters.creatorId,
  );
  const [composers, setComposers] = useState<IUser[]>([]);
  const [statuses, setStatuses] = useState<CompositionStatus[]>([]);
  const [searchResultIds, setSearchResultIds] = useState<number[]>([]);

  const [inputValue, setInputValue] = useState<string>(
    initialFilters.inputValue ?? '',
  );
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [cancelTokenSource, setCancelTokenSource] = useState<
    CancelTokenSource | undefined
  >(undefined);
  const [page, setPage] = useState<number>(initialFilters.page ?? 1);
  const [pageSize, setPageSize] = useState<number | undefined>(
    initialFilters.pageSize ?? DEFAULT_INITIAL_PAGE_SIZE,
  );
  const { user } = useUserContext();

  const fetchOptions = () => {
    const request = axios.get<{ options: FilterOptions }>(
      `/compositions/filter_options`,
    );
    request.then(({ data }) => {
      setComposers(
        data.options.users.sort((a, b) => a.name.localeCompare(b.name)),
      );
      setStatuses(data.options.statuses);
    });
  };

  const fetchAutocompleteSuggestions = (value: string) => {
    const request = axios.get<{ stuff: any }>(
      `/search/autocomplete?input=${value}`,
    );
    request.then(({ data }) => {
      const results = data['results']
        ?.map((result) => result['value'])
        .sort((a, b) => a.localeCompare(b));
      setSuggestions(results ?? []);
    });
  };

  const handleSearchChange = (value: string) => {
    setInputValue(value);
    // Cancel prior search requests
    if (!!cancelTokenSource) {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    }

    setCancelTokenSource(axios.CancelToken.source());
    if (!value) {
      setSearchResultIds([]);
      onFilter({ compositionIds: [] });
    }
  };

  const searchQuery = (value: string) => {
    const request = axios.get<{ stuff: any }>(
      `/search/query?input=${value}&limit=200`,
      {
        cancelToken: cancelTokenSource?.token,
      },
    );
    request
      .then(({ data }) => {
        const resultIds = data['results']?.map((result) =>
          parseInt(result['data']['id']),
        );
        setSearchResultIds(resultIds ?? []);
        onFilter({ compositionIds: resultIds });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          logger.warn(error);
        }
      });
  };

  const handlePaginationChange = (
    page: number,
    pageSize: number | undefined,
  ) => {
    setPageSize(pageSize);
    setPage(page);
    onFilter({ pageSize, page });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  useEffect(() => {
    if (inputValue) {
      searchQuery(inputValue);
    }
  }, [cancelTokenSource]);

  useEffect(() => {
    const stringified = QueryString.stringify({
      creatorId: selectedCreatorId,
      status: status,
      inputValue: inputValue,
      pageSize: pageSize,
      page: page,
    });

    history.replaceState(
      undefined,
      'Dynascores',
      `/compositions?${stringified}`,
    );
  }, [page, pageSize, selectedCreatorId, status, inputValue]);

  const onFilter = (overrides?: {
    creatorId?: number;
    status?: string;
    compositionIds?: number[];
    pageSize?: number;
    page?: number;
  }) => {
    filter({
      creatorId: overrides.creatorId ?? selectedCreatorId,
      status: overrides.status ?? status,
      compositionIds: overrides.compositionIds ?? searchResultIds,
      pageSize:
        overrides && 'pageSize' in overrides ? overrides.pageSize : pageSize,
      page: overrides.page ?? page,
    });
  };

  return (
    <>
      <Box borderWidth="1px" borderRadius="lg" w="100%" mb={4} p={3}>
        <VStack align="flex-start">
          <Flex
            direction="row"
            align="center"
            justify="space-between"
            w="100%"
            mb={4}
          >
            <HStack width="80%">
              <Wrap width="100%">
                <WrapItem width="40%">
                  <FormControl>
                    <FormLabel>Magic Search</FormLabel>
                    <Autosuggest
                      suggestions={suggestions}
                      getSuggestionValue={(suggestion) => suggestion}
                      inputProps={{
                        placeholder: 'Search',
                        value: inputValue,
                        onChange: (_, { newValue }) =>
                          handleSearchChange(newValue),
                      }}
                      onSuggestionsFetchRequested={({ value }) => {
                        fetchAutocompleteSuggestions(value);
                      }}
                      onSuggestionsClearRequested={() => setSuggestions([])}
                      focusInputOnSuggestionClick={false}
                      onSuggestionSelected={(_, { suggestion }) => {
                        handleSearchChange(suggestion);
                      }}
                      shouldRenderSuggestions={() => true}
                      renderInputComponent={({ size, ...inputProps }) => (
                        <Input
                          {...inputProps}
                          {...(suggestions.length > 0
                            ? { borderBottomRadius: 0 }
                            : {})}
                        />
                      )}
                      renderSuggestionsContainer={({
                        containerProps,
                        children,
                      }) =>
                        children && (
                          <Box
                            {...containerProps}
                            borderWidth="1px"
                            borderBottomRadius="lg"
                            py={2}
                          >
                            {children}
                          </Box>
                        )
                      }
                      renderSuggestion={(suggestion, { isHighlighted }) => (
                        <Text
                          px={4}
                          py={2}
                          cursor="pointer"
                          bgColor={isHighlighted && 'gray.100'}
                        >
                          {suggestion}
                        </Text>
                      )}
                      theme={autosuggestTheme}
                    />
                  </FormControl>
                </WrapItem>
                <WrapItem>
                  <FormControl>
                    <FormLabel>Status</FormLabel>
                    <Select
                      placeholder="Select Status"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                        onFilter({ status: e.target.value });
                      }}
                    >
                      {statuses
                        ?.sort(
                          (a, b) => StatusOrder[a.name] - StatusOrder[b.name],
                        )
                        .map((status) => (
                          <option key={status.name} value={status.id}>
                            {status.name}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </WrapItem>

                <WrapItem>
                  <FormControl>
                    <FormLabel>Composer</FormLabel>
                    <Select
                      placeholder="Select Composer"
                      value={selectedCreatorId}
                      disabled={
                        user.aspects?.employee &&
                        !user.permissions.includes(Permission.ViewAllDynascores)
                      }
                      onChange={(e) => {
                        const newCreatorId = e.target.value
                          ? parseInt(e.target.value)
                          : undefined;
                        setSelectedCreatorId(newCreatorId);
                        onFilter({ creatorId: newCreatorId });
                      }}
                    >
                      {composers?.map((composer) => (
                        <option
                          key={`composer_${composer.id}`}
                          value={composer.id}
                        >
                          {composer.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </WrapItem>
              </Wrap>
            </HStack>
          </Flex>
        </VStack>
      </Box>
      <Pagination
        initialPage={initialFilters.page ?? 1}
        initialPageSize={initialFilters.pageSize ?? DEFAULT_INITIAL_PAGE_SIZE}
        totalCount={totalCompositionCount}
        onChange={(page, pageSize) => handlePaginationChange(page, pageSize)}
      >
        <>{children}</>
      </Pagination>
    </>
  );
};
