import React from 'react';
import {
  Center,
  Spinner,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  VStack,
} from '@chakra-ui/react';

export const LoadingModal: React.FC<{
  onClose(): void;
  isOpen: boolean;
}> = ({ onClose, isOpen }) => {
  return (
    <Modal
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      isOpen={isOpen}
      size="lg"
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Processing...</ModalHeader>
        <ModalBody>
          <VStack align="flex-start" justify="center">
            <Center width="100%">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Center>
            <Center width="100%">
              <Text textAlign="center">
                This could take a minute or so, please don't close the window.
              </Text>
            </Center>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
