import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Dynascore } from '../../entities/Dynascore';
import {
  Alert,
  Badge,
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  SkeletonText,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { BsCloudDownload } from 'react-icons/bs';
import { HiOutlineChevronDown, HiOutlineChevronRight } from 'react-icons/hi';
import {
  DynascoreInput,
  timingsInFractionalSeconds,
} from '../../../../common/models/DynascoreInput';
import { ManageProgressMenu } from './manage_progress_menu';
import QueryString from 'query-string';
import { logger } from '../../../../common/infra/logger';

export const DynascoreResults: React.FC = () => {
  const { datePrefix, dynascoreId } = useParams<{
    datePrefix: string;
    dynascoreId: string;
  }>();
  const [dynascore, setDynascore] = useState<Dynascore | undefined>(undefined);
  const [dynascoreInFractions, setDynascoreInFractions] = useState<
    DynascoreInput | undefined
  >(undefined);
  const [editing, setEditing] = useState<boolean>(false);
  const [dynascoreName, setDynascoreName] = useState<string | undefined>(
    undefined,
  );
  const [dynascoreNotes, setDynascoreNotes] = useState<string | undefined>(
    undefined,
  );
  const [updating, setUpdating] = useState<boolean>(false);
  const [viewRenderLog, setViewRenderLog] = useState<boolean>(false);
  const [viewComposerLog, setViewComposerLog] = useState<boolean>(false);
  const [renderLog, setRenderLog] = useState<string | undefined>(undefined);
  const [composerLog, setComposerLog] = useState<string | undefined>(undefined);
  const dynascoreRef = useRef(dynascore);

  useEffect(() => {
    fetchDynascore();
    const interval = setInterval(() => {
      const createdAtLeastSixMinutesAgo =
        !dynascoreRef.current &&
        dynascoreRef.current?.createdDate.valueOf() >
          new Date().valueOf() - 360000;
      if (
        !dynascoreRef.current ||
        ((dynascoreRef.current?.status === 'new' ||
          dynascoreRef.current?.status === 'composing' ||
          dynascoreRef.current?.status === 'rendering') &&
          !createdAtLeastSixMinutesAgo)
      ) {
        fetchDynascore();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const fetchDynascore = () => {
    const request = axios.get<{ dynascore: Dynascore }>(
      `/dynascores/${datePrefix}/${dynascoreId}`,
    );
    request.then(({ data }) => {
      setDynascore(data.dynascore);
      if (data.dynascore.frameRate != 1) {
        setDynascoreInFractions(timingsInFractionalSeconds(data.dynascore));
      }
      setDynascoreName(data.dynascore.name);
      setDynascoreNotes(data.dynascore.notes);
      dynascoreRef.current = data.dynascore;
    });
  };

  const clickEditCancel = () => {
    if (editing) {
      setDynascoreName(dynascore.name);
      setDynascoreNotes(dynascore.notes);
    }
    setEditing(!editing);
  };

  const renderAgain = () => {
    setUpdating(true);
    axios
      .patch(`/dynascores/${dynascore.uuid}/renderAgain`)
      .then(() => {
        setUpdating(false);
        fetchDynascore();
      })
      .catch((error) => {
        logger.warn(`Error updating composition ${error.message}`);
        setUpdating(false);
      });
  };

  const updateDynascore = () => {
    setUpdating(true);
    const data = {
      dynascore: {
        name: dynascoreName,
        notes: dynascoreNotes,
      },
    };
    axios
      .patch(`/dynascores/${dynascore.uuid}/update`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => {
        setUpdating(false);
        setEditing(false);
        fetchDynascore();
      })
      .catch((error) => {
        logger.warn(`Error updating composition ${error.message}`);
        setUpdating(false);
        setEditing(false);
      });
  };

  const fetchLog = () => {
    const request = axios.get<{ renderLog: string }>(
      `/dynascores/${datePrefix}/${dynascoreId}/render_log`,
    );
    request
      .then(({ data }) => {
        setRenderLog(data.renderLog);
      })
      .catch((error) => {
        setRenderLog(
          `Error: Could not fetch log. ${JSON.stringify(error.response.data)}`,
        );
      });
  };

  const fetchComposerLog = () => {
    const request = axios.get<{ composerLog: string }>(
      `/dynascores/${datePrefix}/${dynascoreId}/composer_log`,
    );
    request
      .then(({ data }) => {
        setComposerLog(data.composerLog);
      })
      .catch((error) => {
        setComposerLog(
          `Error: Could not fetch log. ${JSON.stringify(error.response.data)}`,
        );
      });
  };

  const clone = () => {
    const stringified = QueryString.stringify({
      name: `${dynascore.name?.includes('Cloned: ') ? '' : 'Cloned: '}${
        dynascore.name || dynascore.uuid
      }`,
      compositionId: dynascore.compositionId,
      lossClass: dynascore.lossClass,
      soloInstrument: dynascore.soloInstrument,
      duration: dynascore.duration,
      markers: dynascore.markers,
      endingMarker: dynascore.endingMarker,
      falseEndingMarker: dynascore.falseEndingMarker,
      frameRate: dynascore.frameRate,
      pauses: dynascore.pauses,
    });

    window.location.href = `/dynascore/new?${stringified}`;
  };

  return (
    <>
      <Container maxW="2xl">
        <Heading fontSize="xl" w="100%" mb={4}>
          <Breadcrumb
            spacing={2}
            separator={<Icon as={HiOutlineChevronRight} />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="/dynascore">Tracks</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Text>{dynascore?.name || 'Track'}</Text>
            </BreadcrumbItem>
          </Breadcrumb>
        </Heading>

        {/* Loading state */}
        {!dynascore && (
          <Box borderWidth="1px" borderRadius="lg" p={4} w="100%">
            <SkeletonText noOfLines={6} spacing="4" />
          </Box>
        )}

        {dynascore && (
          <Box
            borderWidth="1px"
            borderRadius="lg"
            mb={4}
            p={4}
            w="100%"
            pos="relative"
          >
            <>
              <Stat>
                <StatLabel>Name</StatLabel>
                {!editing ? (
                  <StatHelpText>
                    {dynascore.name || `<<${dynascore.id}>>`}
                  </StatHelpText>
                ) : (
                  <Input
                    placeholder="Name"
                    value={dynascoreName}
                    onChange={(e) => setDynascoreName(e.target.value)}
                  />
                )}
              </Stat>

              <Stat>
                <StatLabel>Notes</StatLabel>
                {!editing ? (
                  <StatHelpText as="pre">{dynascore.notes || '—'}</StatHelpText>
                ) : (
                  <Textarea
                    value={dynascoreNotes}
                    onChange={(e) => setDynascoreNotes(e.target.value)}
                  />
                )}
              </Stat>

              {!editing && (
                <HStack pos="absolute" right={2} top={2}>
                  {['composing'].includes(dynascore.status) && (
                    <ManageProgressMenu dynascore={dynascore} />
                  )}
                  <Menu autoSelect={false}>
                    <MenuButton
                      as={Button}
                      size="sm"
                      rightIcon={<Icon as={HiOutlineChevronDown} />}
                    >
                      Actions
                    </MenuButton>
                    <MenuList fontSize="1rem">
                      <MenuItem
                        onClick={clickEditCancel}
                        isDisabled={['new', 'composing'].includes(
                          dynascore.status,
                        )}
                      >
                        Edit
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem onClick={clone}>Clone</MenuItem>
                      <MenuDivider />
                      <MenuItem
                        onClick={renderAgain}
                        color="orange"
                        isDisabled={!['error'].includes(dynascore.status)}
                      >
                        Render Again
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </HStack>
              )}
              {editing && (
                <HStack>
                  <Button onClick={clickEditCancel}>Cancel</Button>
                  <Button
                    colorScheme="blue"
                    onClick={updateDynascore}
                    isDisabled={updating}
                  >
                    Save
                  </Button>
                </HStack>
              )}
            </>
          </Box>
        )}

        {dynascore?.status === 'complete' && (
          <Box borderWidth="1px" borderRadius="lg" mb={4} p={4} w="100%">
            <Stack direction={['column', 'row']} align="center">
              <audio controls>
                <source
                  type={dynascore.mp3 ? 'audio/mp3' : 'audio/wav'}
                  src={
                    dynascore.mp3 ||
                    `/dynascores/${datePrefix}/${dynascoreId}/files/download?ext=wav`
                  }
                />
                Your browser does not support the audio element.
              </audio>

              <HStack align="center">
                <Button
                  as="a"
                  size="sm"
                  leftIcon={<Icon as={BsCloudDownload} />}
                  href={`/dynascores/${datePrefix}/${dynascoreId}/files/download?ext=wav`}
                  download
                >
                  WAV
                </Button>

                <Button
                  as="a"
                  size="sm"
                  leftIcon={<Icon as={BsCloudDownload} />}
                  href={`/dynascores/${datePrefix}/${dynascoreId}/files/download?ext=mid`}
                  download
                >
                  MIDI
                </Button>
              </HStack>
            </Stack>
          </Box>
        )}

        {dynascore && (
          <Box borderWidth="1px" borderRadius="lg" mb={4} p={4} w="100%">
            <Stat>
              <StatLabel>UUID</StatLabel>
              <StatHelpText>
                {dynascore.datePrefix}/{dynascore.uuid}
              </StatHelpText>
            </Stat>

            {dynascore.errors && (
              <Stat>
                <StatLabel>Errors</StatLabel>
                <Alert
                  status="error"
                  as="pre"
                  whiteSpace="pre-wrap"
                  overflowX="auto"
                  fontSize="sm"
                >
                  {dynascore.errors}
                </Alert>
              </Stat>
            )}

            <Stat>
              <StatLabel>Status</StatLabel>
              <HStack align="center" mb={2}>
                <Badge
                  colorScheme={
                    dynascore.status === 'error'
                      ? 'red'
                      : dynascore.status === 'complete'
                      ? 'green'
                      : 'gray'
                  }
                >
                  {dynascore.status}
                </Badge>
                {'composing' === dynascore.status && (
                  <Badge>{dynascore.queueMessage}</Badge>
                )}
                {!['composing', 'error', 'complete'].includes(
                  dynascore.status,
                ) && <Badge>{dynascore.percentComplete.toFixed(2)}%</Badge>}
              </HStack>
            </Stat>

            <Stat>
              <StatLabel>Dynascore</StatLabel>
              <StatHelpText>{dynascore.compositionName}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Frame Rate</StatLabel>
              <StatHelpText>{dynascore.frameRate}</StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Duration</StatLabel>
              <StatHelpText>
                {dynascore.duration}
                <br />
                {dynascoreInFractions ? dynascoreInFractions.duration : ''}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Markers</StatLabel>
              <StatHelpText>
                {dynascore.markers}
                <br />
                {dynascoreInFractions ? dynascoreInFractions.markers : ''}
              </StatHelpText>
            </Stat>

            {dynascore.pauses && (
              <Stat>
                <StatLabel>Pauses</StatLabel>
                <StatHelpText>
                  {dynascore.pauses}
                  <br />
                  {dynascoreInFractions ? dynascoreInFractions.pauses : ''}
                </StatHelpText>
              </Stat>
            )}

            {dynascore.falseEndingMarker && (
              <Stat>
                <StatLabel>Ending Start</StatLabel>
                <StatHelpText>
                  {dynascore.falseEndingMarker}
                  <br />
                  {dynascoreInFractions
                    ? dynascoreInFractions.falseEndingMarker
                    : ''}
                </StatHelpText>
              </Stat>
            )}

            {dynascore.endingMarker && (
              <Stat>
                <StatLabel>Bump</StatLabel>
                <StatHelpText>
                  {dynascore.endingMarker}
                  <br />
                  {dynascoreInFractions
                    ? dynascoreInFractions.endingMarker
                    : ''}
                </StatHelpText>
              </Stat>
            )}

            {dynascore.composerType && (
              <Stat>
                <StatLabel>Composer Type</StatLabel>
                <StatHelpText>{dynascore.composerType}</StatHelpText>
              </Stat>
            )}

            {dynascore.soloInstrument && (
              <Stat>
                <StatLabel>Solo Instrument</StatLabel>
                <StatHelpText>{dynascore.soloInstrument}</StatHelpText>
              </Stat>
            )}

            {dynascore.compositionGuide && (
              <Stat>
                <StatLabel>Composer Guide</StatLabel>
                <Alert status="info" as="pre" overflowX="auto" fontSize="sm">
                  {dynascore.compositionGuide}
                </Alert>
              </Stat>
            )}
          </Box>
        )}

        <HStack>
          {['complete', 'error'].includes(dynascore?.status) &&
            !viewRenderLog &&
            !viewComposerLog && (
              <Button
                size="sm"
                onClick={() => {
                  if (!renderLog) {
                    fetchLog();
                  }
                  setViewRenderLog(true);
                }}
              >
                View Render Log
              </Button>
            )}
          {['rendering', 'complete', 'error'].includes(dynascore?.status) &&
            !viewRenderLog &&
            !viewComposerLog && (
              <Button
                size="sm"
                onClick={() => {
                  if (!composerLog) {
                    fetchComposerLog();
                  }
                  setViewComposerLog(true);
                }}
              >
                View Composer Log
              </Button>
            )}
        </HStack>
      </Container>

      {(viewRenderLog || viewComposerLog) && (
        <Box borderWidth="1px" borderRadius="lg" mb={4} p={4} w="100%">
          {((!renderLog && viewRenderLog) ||
            (composerLog === undefined && viewComposerLog)) && (
            <SkeletonText noOfLines={3} spacing="4" mb={4} />
          )}

          <VStack align="flex-start" overflowX="auto">
            {renderLog && viewRenderLog && (
              <Stat>
                <StatHelpText as="pre">{renderLog}</StatHelpText>
              </Stat>
            )}

            {composerLog !== undefined && viewComposerLog && (
              <Stat>
                <StatHelpText as="pre">{composerLog || 'No Logs'}</StatHelpText>
              </Stat>
            )}

            <Button
              size="sm"
              onClick={() => {
                setViewRenderLog(false);
                setViewComposerLog(false);
              }}
            >
              Close Log
            </Button>
          </VStack>
        </Box>
      )}
    </>
  );
};
